import _ from "lodash";
import { Grid } from "@mui/material";
import { RHFTextField } from "src_common/components/hook-form";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import {
  YAOFieldAutocomplete,
  YAOFieldAutocompleteOption,
} from "src_common/components/yao-form-fields";

type Props = {
  accounts: any[];
  type?: string;
  isTo: boolean;
  handleAcct: Function;
  setValue?: UseFormSetValue<any>;
  hideLabelTo?: boolean;
  isM2M?: boolean;
};

export default function InternalAccount({
  accounts,
  type,
  isTo,
  handleAcct,
  setValue,
  hideLabelTo = false,
  isM2M = false,
}: Props) {
  const [options, setOptions] = useState<YAOFieldAutocompleteOption[]>([]);

  useEffect(() => {
    const nextOptions = Object.values(accounts || [])
      .filter((e: any) => e.type === type)
      .map((account: any) => {
        const value = JSON.stringify({
          account_id: account._id,
          account_number: account.account_number,
          account_name: account.name
        });
        return {
          label: _.capitalize(
            `${account.account_number} - ${account.name} (${account.type})`
          ),
          value,
          changeVal: value,
        };
      });
    setOptions(nextOptions);
    if (Array.isArray(nextOptions) && nextOptions.length > 0) {
      setValue &&
        setValue(isTo ? "to.account" : "from.account", nextOptions[0]);
      handleAcct && handleAcct(nextOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const forceLabbelToHide = ((isTo && hideLabelTo) || (isM2M && hideLabelTo));

  return (
    <Grid container spacing={3}>
      <Grid item xs={forceLabbelToHide ? 12 : 6}>
        <YaoFormFieldLabel
          name={isTo ? "to.payment_label" : "from.payment_label"}
          label={"Make a payment " + (isTo ? "to" : "from")}
          required
        />
        <YAOFieldAutocomplete
          name={isTo ? "to.account" : "from.account"}
          placeholder="Select an option"
          options={options}
          customOnChange={(v: string) => {
            handleAcct(v || "");
          }}
        />
      </Grid>
      {forceLabbelToHide ? null : (
        <Grid item xs={6}>
          <YaoFormFieldLabel
            name={isTo ? "to.reference_label" : "from.reference_label"}
            label="Display on Ledger as:"
            required={true}
          />
          <RHFTextField
            id={isTo ? "to.reference" : "from.reference"}
            name={isTo ? "to.reference" : "from.reference"}
            variant="outlined"
            placeholder="enter text to be displayed on ledger"
            required={true}
          />
        </Grid>
      )}
    </Grid>
  );
}
