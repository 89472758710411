import CustomIcon from "src_common/components/custom-icon";
import { Button } from "@mui/material";
import { Status } from 'src_lawfirm/api/matters';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useMemo} from "react";

type Props = {
  matterStatus: string;
  sx?: {};
};

type StatusFieldProps = {
  icon: string;
  borderColor: string;
}

const statusFieldProps = (matterStatus: string): StatusFieldProps => ({
  [Status.IN_PROGRESS.value]: { icon: 'circle_in_progress', borderColor: "#09B5B5" },
  [Status.ON_HOLD.value]: { icon: 'circle_on_hold', borderColor: "#E4CB72" },
  [Status.COMPLETED.value]: { icon: 'circle_completed', borderColor: "#A4AFB9" },
  [Status.QUOTE.value]: { icon: 'circle_quote', borderColor: "#E49060" },
  [Status.NOT_PROCEEDING.value]: { icon: 'circle_not_proceeding', borderColor: "#A4AFB9" },
  [Status.EXCHANGED.value]: { icon: 'circle_exchanged', borderColor: "#A363D5" },
})[matterStatus] || { icon: "", borderColor: "#ddd" };

export const MatterStatusField = ({ matterStatus, sx }: Props) => {
  const matches = useMediaQuery('(max-width:1450px)')

  const complement = useMemo(() => {
    if(matches){
      return {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '6px',
      }
    }
    return {}
  }, [matches])

  return (
    <Button
      variant="outlined"
      className="matter-status"
      startIcon={<CustomIcon sx={{ height: 16, marginRight: '3px' }} name={statusFieldProps(matterStatus).icon} />}
      sx={{
        borderColor: statusFieldProps(matterStatus).borderColor,
        height: "24px",
        borderRadius: "3px",
        ...complement,
        ...sx,
    }}
    >
      {Status[matterStatus as keyof typeof Status].name}
    </Button>
  );
};
