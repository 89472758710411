import {createContext, FC} from "react";
import {useQuery} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";
import {WOPI_API} from "../config";

type RouteTypeObj = {
    [key: string]: { [key: string]: string }
}

export type WopiContextProps = {
    routes: RouteTypeObj | undefined;
    generateWopiUrl: (extension: string, wopi_key: string, wopi_token: string, edit: boolean, viewOnly: boolean) => string | undefined;
}

const DEFAULT_VALUES = {
    routes: undefined,
    generateWopiUrl: (extension: string, wopi_key: string, wopi_token: string, edit: boolean) => undefined
}

const WopiContext = createContext<WopiContextProps>(DEFAULT_VALUES);

const fetchDiscovery = async (): Promise<RouteTypeObj | undefined> => {
    try {
        const response: AxiosResponse<RouteTypeObj> = await axios.get(`${WOPI_API}/app/discovery`)
        return response?.data || undefined;
    } catch (e) {
        return undefined
    }
}

const WopiContextProvider: FC = ({ children }) => {

    const {data} = useQuery(
        ['wopi-routes'],
        () => fetchDiscovery()
    )

    const generateWopiUrl = (
        extension: string,
        wopi_key: string,
        wopi_token: string,
        edit = false,
        viewOnly = false
    ) => {
        try {
            if (!data) {
                return undefined
            }
            const complement = viewOnly ? "view" : edit ? extension.endsWith('x') ? 'edit' : 'open' : 'embedview';
            const actionUrl = data[extension.toLowerCase()][complement];
            const divider = actionUrl.includes('?') ? '&' : '?';
            const origin = encodeURIComponent(`${WOPI_API}/wopi/files/${wopi_key}`)
            return `${actionUrl}${divider}WOPISrc=${origin}&access_token=${wopi_token}&access_token_ttl=0`;
        } catch (e){
            return undefined
        }
    }

    return <WopiContext.Provider value={{
        routes: data,
        generateWopiUrl,
    }}>
        {children}
    </WopiContext.Provider>
}

export { WopiContext, WopiContextProvider }