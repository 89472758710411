import {Controller, RegisterOptions, useFormContext,} from "react-hook-form";
import {TextField, TextFieldProps} from "@mui/material";
import "./YAOFieldInputOverride.css";
import React, {forwardRef} from "react";
import NumberFormat, {InputAttributes} from "react-number-format";

type YAOFieldPhoneProps =
    Omit<TextFieldProps, "name" | "fullWidth" | "className" | "error" | "helperText" | "defaultValue">
    & {
    name: string;
    defaultValue?: string;
    label?: string;
    rules?: Omit<RegisterOptions<Record<string, any>, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
};

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = forwardRef<NumberFormat<InputAttributes>, CustomProps>(function NumberFormatCustom(props, ref) {
    const {onChange, ...other} = props;

    return (<NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name, value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            decimalScale={2}
        />);
});


export function YAOFieldMaskedNumber({
                                         name,
                                         defaultValue,
                                         label,
                                         placeholder = "",
                                         rules,
                                         margin = "none",
                                         variant = "outlined",
                                         ...fieldProps
                                     }: YAOFieldPhoneProps) {
    const {control} = useFormContext();

    return (<Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={rules}
            render={({
                         field: {value = "", onChange, ...props}, fieldState: {error},
                     }) => (<TextField
                    fullWidth
                    {...fieldProps}
                    {...props}
                    value={fieldProps.value || value}
                    onChange={(e) => onChange(e.target.value)}
                    variant={variant}
                    margin={margin}
                    id={name}
                    error={!!error}
                    helperText={error?.message}
                    className="yao-input-override"
                    InputProps={{
                        ...(fieldProps.InputProps || {}), inputComponent: NumberFormatCustom as any,
                    }}
                />)}
        />);
}
