import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import {
    Dispatch,
    FC,
    MouseEvent,
    SetStateAction,
    useEffect,
    useState,
    ChangeEvent, useContext, useMemo,
} from "react";
import { UseFormReturn } from "react-hook-form";
import Decimal from "decimal.js";
import CustomIcon from "src_common/components/custom-icon";
import Iconify from "src_common/components/Iconify";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { useAPI } from "src_common/hooks/useAPI";
import palette from "src_common/theme/palette";
import { fCurrencyWithFormat } from "src_common/utils/formatNumber";
import {
  Invoice,
  invoiceSearch,
  InvoiceStatus,
} from "src_lawfirm/api/invoices";
import { Ledger, searchLedgers } from "src_lawfirm/api/ledgers";
import { PaymentNotificationFormData } from "src_lawfirm/api/payment-notifications";
import { CurrencyMask } from "src_common/components/yao-form";
import {LawFirmContext} from "../../law-firm/LawFirmContext";

Decimal.set({ precision: 10, rounding: 2 });

export type PaymentNotificationC2ODisbursement = {
  id: string;
  narrative: string;
  date: string;
  outstanding: number;
  value: number;
  selected: boolean;
};

interface PaymentNotificationC2OProps {
  matter: string;
  methods: UseFormReturn<PaymentNotificationFormData, any>;
  invoice: Invoice | null;
  setInvoice: Dispatch<SetStateAction<Invoice | null>>;
  disbursements: PaymentNotificationC2ODisbursement[];
  setDisbursements: Dispatch<
    SetStateAction<PaymentNotificationC2ODisbursement[]>
  >;
}

interface BreakdownItemProps
  extends Pick<PaymentNotificationC2OProps, "setDisbursements"> {
  index: number;
  item: PaymentNotificationC2ODisbursement;
}

const BreakdownItem: FC<BreakdownItemProps> = ({
  index,
  item,
  setDisbursements,
}) => {
    const lawFirmContext = useContext(LawFirmContext);
  const labelId = `checkbox-list-label-${item.id}`;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [temp, setTemp] = useState<string>("");

    const currInfo = useMemo(() => lawFirmContext.getCurrency(), [lawFirmContext]);

  const startEdit = (event: MouseEvent) => {
    event.stopPropagation();
    setTemp(item.value ? item.value.toString() : "");
    setIsEditing((o) => !o);
  };

  const cancelEdit = (event: MouseEvent) => {
    event.stopPropagation();
    setIsEditing((o) => !o);
    setTemp("");
  };

  const performEdit = (event: MouseEvent) => {
    event.stopPropagation();
    setDisbursements((list) => {
      const next = [...list];
      let value = Number(temp);
      if (value < 0) {
        value = 0;
      } else if (value > item.outstanding) {
        value = item.outstanding;
      }
      next[index].value = value;
      next[index].selected = value > 0;
      return next;
    });
    cancelEdit(event);
  };

  const performSelect = (event: MouseEvent) => {
    event.stopPropagation();
    setDisbursements((list) => {
      const next = [...list];
      next[index].selected = !next[index].selected;
      return next;
    });
  };

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        if (isEditing) {
          cancelEdit(event as any);
        }
      }}
    >
      <ListItem
        key={item.id}
        disablePadding
        secondaryAction={
          isEditing ? (
            <Stack direction="row" spacing={0}>
              <Button
                disableRipple
                onClick={cancelEdit}
                sx={{
                  color: palette.yao.secondary[2],
                  borderRadius: 0,
                  "&:hover": { backgroundColor: "transparent" },
                }}
                size="small"
                title="cancel"
              >
                <Iconify icon="material-symbols:close" fontSize={14} />
              </Button>
              <Button
                disableRipple
                onClick={performEdit}
                sx={{
                  color: palette.yao.secondary[1],
                  borderRadius: 0,
                  "&:hover": { backgroundColor: "transparent" },
                }}
                size="small"
                title="save"
              >
                <Iconify icon="material-symbols:check" fontSize={14} />
              </Button>
            </Stack>
          ) : (
            <Tooltip title="change outstanding value">
              <IconButton onClick={startEdit} disableRipple>
                <CustomIcon name="pencil" />
              </IconButton>
            </Tooltip>
          )
        }
      >
        {isEditing ? (
          <Box sx={{ width: "calc(100% - 150px)" }}>
            <TextField
              name="temp-value"
              value={temp}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setTemp(event.target.value)
              }
              variant="standard"
              size="small"
              focused
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">{lawFirmContext.getCurrencySymbol()}</InputAdornment>,
                inputComponent: CurrencyMask,
                inputProps: { min: 0, max: item.outstanding, step: 1 },
              }}
              onKeyUp={(e) => {
                if (e.key === "Escape") {
                  cancelEdit(e as any);
                } else if (e.key === "Enter") {
                  performEdit(e as any);
                }
              }}
            />
          </Box>
        ) : (
          <ListItemButton role={undefined} onClick={performSelect} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={item.selected}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={`${fCurrencyWithFormat(item.value, currInfo.locale, currInfo.currency, currInfo.symbol)} - Narrative: ${
                item.narrative
              }`}
              secondary={`Date. ${format(new Date(item.date), "dd.MM.yyyy")}`}
            />
          </ListItemButton>
        )}
      </ListItem>
    </ClickAwayListener>
  );
};

export const PaymentNotificationC2O: FC<PaymentNotificationC2OProps> = ({
  matter,
  methods,
  invoice,
  setInvoice,
  disbursements,
  setDisbursements,
}) => {
    const lawFirmContext = useContext(LawFirmContext);
  const getInvoicesAPI = useAPI(invoiceSearch);
  const ledgersAPI = useAPI(searchLedgers);

    const currInfo = useMemo(() => lawFirmContext.getCurrency(), [lawFirmContext]);

  useEffect(() => {
    methods.setValue("value", 0);
    getInvoicesAPI.invoke(
      {
        page: 0,
        size: 1000,
        bank_account: null,
        end: null,
        matter: matter,
        start: null,
        status: InvoiceStatus.ISSUED,
      },
      (data) => {
        if (Array.isArray(data) && data.length > 0) {
          setInvoice(data[0]);
        }
      }
    );
  }, []);

  useEffect(() => {
    setDisbursements([]);
    if (!invoice || !invoice._id.length) {
      return;
    }
    ledgersAPI.invoke(
      {
        page: 0,
        size: 10000,
        matter: matter,
        invoice: invoice._id,
      },
      (data) => {
        if (Array.isArray(data) && data.length > 0) {
          setDisbursements(
            (data as Ledger[]).map((l) => {
              let outstanding = Number(l.outstanding);
              if (isNaN(outstanding)) {
                outstanding = 0;
              } else if (outstanding < 0) {
                outstanding *= -1;
              }

              return {
                id: l._id,
                narrative: l.reference,
                date: l.created_at,
                outstanding,
                value: outstanding,
                selected: false,
              } as PaymentNotificationC2ODisbursement;
            })
          );
        }
      }
    );
  }, [invoice]);

  useEffect(() => {
    const value = disbursements
      .filter((d) => d.selected)
      .map((d) => new Decimal(Number(d.value) || 0))
      .reduce((p, c) => p.plus(c), new Decimal(0))
      .toNumber();
    methods.setValue("value", value);
  }, [disbursements]);

  return (
    <Grid container spacing={3} sx={{ pt: 2 }}>
      <Grid item xs={12} sm={6} sx={{ height: "235px", overflow: "hidden" }}>
        <YaoFormFieldLabel name="inv" label="Invoice" required />
        {getInvoicesAPI.loading ? (
          <Box
            display="flex"
            width="100%"
            height="200px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : null}
        {!Array.isArray(getInvoicesAPI.data) || !getInvoicesAPI.data.length ? (
          <Typography color="error">No invoice found.</Typography>
        ) : null}
        {Array.isArray(getInvoicesAPI.data) &&
        getInvoicesAPI.data.length > 0 ? (
          <List
            dense
            sx={{
              height: "200px",
              overflowY: "auto",
              overflowX: "hidden",
              p: 0,
            }}
          >
            {getInvoicesAPI.data.map((i) => (
              <ListItem key={i._id} disablePadding>
                <ListItemButton
                  selected={invoice?._id === i._id}
                  onClick={() => setInvoice(i)}
                >
                  <ListItemText
                    primary={`No. ${
                      i.invoice_number
                    } - Total: ${fCurrencyWithFormat(i.total, currInfo.locale, currInfo.currency, currInfo.symbol)}`}
                    secondary={`Date. ${format(
                      new Date(i.created_at),
                      "dd.MM.yyyy"
                    )}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : null}
      </Grid>

      {!getInvoicesAPI.loading && !invoice ? (
        <Grid item xs={12} sm={6} sx={{ height: "235px", overflow: "hidden" }}>
          <YaoFormFieldLabel name="disb" label="Breakdown" required />
          <Typography color="error">Need an invoice.</Typography>
        </Grid>
      ) : (
        <Grid item xs={12} sm={6} sx={{ height: "235px", overflow: "hidden" }}>
          <YaoFormFieldLabel name="disb" label="Breakdown" required />
          {ledgersAPI.loading ? (
            <Box
              display="flex"
              width="100%"
              height="200px"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : null}
          {!ledgersAPI.loading && !disbursements.length ? (
            <Typography color="error">No breakdowns found.</Typography>
          ) : null}
          {!ledgersAPI.loading && disbursements.length > 0 ? (
            <List
              dense
              sx={{
                height: "200px",
                overflowY: "auto",
                overflowX: "hidden",
                p: 0,
              }}
            >
              {disbursements.map((item, index) => (
                <BreakdownItem
                  key={item.id}
                  index={index}
                  item={item}
                  setDisbursements={setDisbursements}
                />
              ))}
            </List>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};
