import { Ledger } from "../../api/ledgers";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {useCallback, useContext, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import palette from "src_common/theme/palette";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { NumberFormatCustom } from "src_common/components/yao-form-fields/YAOFieldCurrency";
import { DatePicker } from "@mui/lab";
import {LawFirmContext} from "../law-firm/LawFirmContext";

export type ReverseConfirmModalProps = {
  row: Ledger;
};

const ReverseConfirmModal = NiceModal.create<ReverseConfirmModalProps>(
  ({ row }) => {
    const lawFirmContext = useContext(LawFirmContext);
    const modal = useModal();
    const [dtUsed, setDate] = useState<Date | null>(new Date(row.date));

    const handleClose = useCallback(() => {
      modal.hide();
    }, [modal]);

    const handleConfirm = () => {
      if (!!dtUsed) {
        modal.resolve(dtUsed);
        modal.hide();
      }
    };

    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={modal.visible}
        onClose={handleClose}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>REVERSAL</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <YaoFormFieldLabel name="date" label="Date" />
              <DatePicker
                openTo={"day"}
                views={["day"]}
                value={dtUsed}
                onChange={(e) => setDate(e)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    className="yao-input-override"
                    placeholder="dd/MM/yyyy"
                    error={!!dtUsed}
                    {...params}
                    id="date"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YaoFormFieldLabel
                name="value"
                label={Number(row.value) < 0 ? "withdrawal" : "deposit"}
              />
              <TextField
                type="text"
                name="value"
                value={row.value}
                variant="outlined"
                margin="none"
                className="yao-input-override"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  endAdornment: (
                    <InputAdornment position="end">{lawFirmContext.getCurrencySymbol()}</InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={6}>
              <YaoFormFieldLabel name="client" label="Solicitor" />
              <TextField
                type="text"
                name="client"
                value={row.author?.name}
                variant="outlined"
                margin="none"
                className="yao-input-override"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <YaoFormFieldLabel name="matter" label="Matter" />
              <TextField
                type="text"
                name="matter"
                value={row.matter?.number}
                variant="outlined"
                margin="none"
                className="yao-input-override"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <YaoFormFieldLabel name="description" label="Description" />
              <TextField
                type="text"
                name="description"
                value={row.reference}
                variant="outlined"
                margin="none"
                className="yao-input-override"
                fullWidth
                disabled
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={handleClose}
            sx={{ color: palette.yao.grey[7] }}
          >
            Cancel
          </Button>
          <Button variant="contained" sx={{ ml: 1 }} onClick={handleConfirm}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ReverseConfirmModal;
