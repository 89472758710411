import {FC, Fragment, useContext, useEffect} from "react";
import {MenuContext} from "src_common/components/menu/menu-context";
import {useLocation} from "react-router-dom";
import useDebounce from "src_common/hooks/useDebounce";
import {getPostItemPendingCount} from "../../api/post-office";
import { on, off } from "src_common/utils/events"

export const POST_OFFICE_UPDATE = 'POST_OFFICE_UPDATE'

export const PostOfficeWatcher: FC = ({ children }) => {

    const contextMenu = useContext(MenuContext)
    const { pathname } = useLocation()
    const {debounce, reset: clearDebounce} = useDebounce();

    useEffect(() => {
        on(POST_OFFICE_UPDATE, updateCounter)
        return () => {
            off(POST_OFFICE_UPDATE, updateCounter)
        }
    }, [])

    useEffect(() => {
        updateCounter()
    }, [pathname])

    const updateCounter = () => {
        clearDebounce()
        debounce(async () => {
            try {
                const total = await getPostItemPendingCount()
                contextMenu.setPostOffice(total);
            } catch (e) {
                console.error(e)
            } finally {
                clearDebounce()
            }
        }, 1000)
    }

    return <Fragment>{children}</Fragment>

}