import NiceModal from "@ebay/nice-modal-react";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
  Drawer,
} from "@mui/material";
import {useInfiniteQuery, useQuery, useQueryClient,} from "@tanstack/react-query";
import {useMemo, useState, useEffect, useContext} from "react";
import {useParams} from "react-router";
import CustomIcon from "src_common/components/custom-icon";
import ConfirmDialog from "src_common/components/dialog/ConfirmDialog";
import Dialog from "src_common/components/dialog/Dialog";
import Iconify from "src_common/components/Iconify";
import Page from "src_common/components/Page";
import YaoTable from "src_common/components/yao-table";
import {Row} from "src_common/components/yao-table/types";
import {useAPI} from "src_common/hooks/useAPI";
import {useInfiniteScrollQuery} from "src_common/hooks/useInfiniteScrollQuery";
import palette from "src_common/theme/palette";
import {fCurrencyWithFormat} from "src_common/utils/formatNumber";
import {fDate, fMinutes} from "src_common/utils/formatTime";
import {
  deleteTimeEntry, downloadTimeEntries,
  getTotalTimeEntries,
  InvoicedFilter,
  searchTimeEntries,
  TimeEntry,
} from "src_lawfirm/api/time-entries";
import {useInvoices} from "../invoices/useInvoices";
import {MatterFinancialLimitBanner} from "./MatterFinancialLimitBanner";
import FilterTimeEntry, {TimeEntryFiltersFormModel,} from "./TimeEntry/FilterTimeEntryForm";
import TimeEntryDialog from "./TimeEntry/TimeEntryDialog";
import Decimal from "decimal.js";
import {formatError} from "src_common/utils/misc";
import {useSnackbar} from "notistack";
import { Correspondence, CorrespondenceCategory, getCorrespondenceDetails } from 'src_lawfirm/api/correspondence';
import { CorrespondenceDetail, CorrespondenceEmailDetail } from './CorrespondenceForms';
import { CorrespondenceFileViewer } from './CorrespondenceForms/CorrespondenceFileViewer.component';
import { CurrencyInfo, LawFirmContext} from "../law-firm/LawFirmContext";

Decimal.set({precision: 10, rounding: 2});

const headLabels = (currInfo: CurrencyInfo) => [
  {
    id: "duration_minutes",
    label: "Time",
    formatter: (v: number) => fMinutes(v * 60),
  },
  {
    id: "date",
    label: "Date Posted",
    formatter: fDate,
  },
  {
    id: "work_type",
    label: "Type",
  },
  {
    id: "description",
    label: "Description",
    formatter: (_: any, row?: Row) => (
      <Typography width="22rem" title={row?.description} noWrap>
        {row?.description}
      </Typography>
    )
  },
  {
    id: "assignee",
    label: "User",
    formatter: (_: any, row?: Row) => {
      return `${row?.assignee?.name} ${row?.assignee?.surname}`;
    },
  },
  {
    id: "cat",
    label: "CAT",
    formatter: (_: any, row?: Row) => (!!row?.invoice ? "Invoiced" : "WIP"),
  },
  {
    id: "units",
    label: "Units",
    sx: {
      width: "5rem",
    },
  },
  {
    id: "rate",
    label: "Unit Value",
    formatter: (rate: number) => fCurrencyWithFormat(new Decimal(rate || 0).dividedBy(10).toNumber(), currInfo.locale, currInfo.currency, currInfo.symbol),
    sx: {
      width: "10rem",
    },
  },
  {
    id: "billable",
    label: "Work Value",
    formatter: (v: number) => fCurrencyWithFormat(v, currInfo.locale, currInfo.currency, currInfo.symbol),
    sx: {
      width: "10rem",
    },
  },
  {
    id: "write_off",
    label: "write off",
    formatter: (v: number) => fCurrencyWithFormat(v, currInfo.locale, currInfo.currency, currInfo.symbol),
  },
  {
    id: "",
    label: "",
    formatter: () => <div style={{ width: "0.8rem" }}></div>
  },
];

export default function TimeTab() {
  const lawFirmContext = useContext(LawFirmContext);
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState<TimeEntryFiltersFormModel>({
    assignee: null,
    end: null,
    start: null,
    work_type: [],
  });
  const { matterId } = useParams();
  const invoicesApi = useInvoices();
  const { enqueueSnackbar } = useSnackbar();
  const deleteTimeEntryAPI = useAPI(deleteTimeEntry);
  const getCorrespondenceDetailsAPI = useAPI(getCorrespondenceDetails);

  const [wip, setWip] = useState(true);
  const [invoiced, setInvoiced] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

  const [correspondenceId, setCorrespondenceId] = useState();
  const [openDrawer, setOpenDrawer] = useState<Correspondence | null>();
  const [openEmailDrawer, setOpenEmailDrawer] = useState<Correspondence | null>();
  const [openModel, setOpenModel] = useState<Correspondence | null>();

  const searchTimeEntriesFilters = useMemo(() => {
    const _filters: any = {
      matter: matterId,
      assignee: filters?.assignee?.value,
      end: filters?.end?.toISOString(),
      start: filters?.start?.toISOString(),
      work_type:
        filters?.work_type.length > 0
          ? filters?.work_type.map((wt) => wt.value)
          : undefined,
      invoiced: InvoicedFilter.All,
    };

    if (wip && !invoiced) _filters.invoiced = InvoicedFilter.Wip;
    else if (!wip && invoiced) _filters.invoiced = InvoicedFilter.Invoiced;

    return _filters;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, wip, invoiced]);

  const currInfo = useMemo(() => lawFirmContext.getCurrency(), [lawFirmContext]);

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    [matterId, "time-entries", searchTimeEntriesFilters],
    ({ pageParam }) =>
      searchTimeEntries({ ...searchTimeEntriesFilters, page: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.next,
    }
  );

  const { data: total } = useQuery([matterId, "time-entries"], () =>
    getTotalTimeEntries(matterId || "")
  );

  const { ref } = useInfiniteScrollQuery({
    fetch: !isFetching && hasNextPage ? fetchNextPage : undefined,
  });

  const list = useMemo(
    () => data?.pages.flatMap((page) => page.result) || [],
    [data]
  );

  const handleInvoiceAllWip = () => {
    (async () => {
      try {
        invoicesApi
            .createDialog(matterId as string, [], true)
            .then((response) => {
              if (response) {
                queryClient.invalidateQueries([
                  matterId,
                  "time-entries",
                  searchTimeEntriesFilters,
                ]);
              }
            });
      } catch (error) {
        enqueueSnackbar(formatError(error), { variant: "error" });
      }
    })();
  }

  const Footer = ({ selected }: { selected: string[] }) => {
    if (selected.length > 0) {
      return (
        <TableCell colSpan={11}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Button
                startIcon={<Iconify icon="ri:money-pound-box-line" />}
                variant="contained"
                sx={{
                  marginLeft: 2,
                }}
                onClick={() => {
                  const selectedTimeEntries = list.filter((t) =>
                    selected.includes(t._id)
                  );
                  invoicesApi
                    .createDialog(matterId as string, selectedTimeEntries)
                    .then((response) => {
                      if (response) {
                        queryClient.invalidateQueries([
                          matterId,
                          "time-entries",
                          searchTimeEntriesFilters,
                        ]);
                      }
                    });
                }}
              >
                Invoice selected entries
              </Button>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: "flex", gap: 4, justifyContent: "space-between" }}
            >
              <Typography
                variant="inherit"
                color={palette.yao.grey[7]}
                sx={{ fontWeight: 700 }}
              >
                Total
              </Typography>
              <Typography variant="inherit" sx={{ fontWeight: 700 }}>
                {fCurrencyWithFormat(
                  list
                    .filter((t) => selected.includes(t._id))
                    .reduce((prev, cur) => cur.billable + prev, 0) || 0, currInfo.locale, currInfo.currency, currInfo.symbol
                )}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      );
    }

    return (
      <TableCell colSpan={11}>
        <Grid container>
          <Grid xs={6} item>
            <Button startIcon={<Iconify icon="ri:money-pound-box-line" />}
                    variant="contained"
                    sx={{
                      marginLeft: 2,
                    }}
            onClick={handleInvoiceAllWip}>
              Invoice all WIP
            </Button>

          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="inherit"
              color={palette.yao.grey[7]}
              sx={{ fontWeight: 700 }}
            >
              Invoiced
            </Typography>
            <Typography variant="inherit" sx={{ fontWeight: 700 }}>
              {fCurrencyWithFormat(total?.invoiced || 0, currInfo.locale, currInfo.currency, currInfo.symbol)}
            </Typography>
          </Grid>

          <Grid item xs={1} />

          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="inherit"
              color={palette.yao.grey[7]}
              sx={{ fontWeight: 700 }}
            >
              WIP
            </Typography>
            <Typography variant="inherit" sx={{ fontWeight: 700 }}>
              {fCurrencyWithFormat(total?.uninvoiced || 0, currInfo.locale, currInfo.currency, currInfo.symbol)}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const handleDownload = async () => {
    if(downloadInProgress){
      return
    }
    try {
      enqueueSnackbar(`Please wait, we are preparing the file for download`, { variant: 'info' })
      setDownloadInProgress(true)
      await downloadTimeEntries(
          matterId as string,
          searchTimeEntriesFilters.invoiced
      )
      setDownloadInProgress(false)
    }  catch (error) {
      setDownloadInProgress(false)
      enqueueSnackbar(formatError(error), { variant: "error" });
    }
  }

  useEffect(() => {
    if (!correspondenceId) return;
    getCorrespondenceDetailsAPI.invoke(correspondenceId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correspondenceId])

  useEffect(() => {
    if (getCorrespondenceDetailsAPI.data) {
      const correspondence = getCorrespondenceDetailsAPI.data;
      if (!!openDrawer) {
        setOpenDrawer(null);
      }
      if (!!openEmailDrawer) {
        setOpenEmailDrawer(null);
      }
      if (!!openModel) {
        setOpenModel(null);
      }
      if (
        matterId &&
        (correspondence.category === CorrespondenceCategory.CALL ||
          correspondence.category === CorrespondenceCategory.NOTE)
      ) {
        setOpenDrawer(correspondence as Correspondence);
      }
      if (
        matterId &&
        correspondence.category === CorrespondenceCategory.EMAIL
      ) {
        setOpenEmailDrawer(correspondence as Correspondence);
      }
      if (
        matterId &&
        correspondence.category === CorrespondenceCategory.DOCUMENT
      ) {
        setOpenModel(correspondence as Correspondence);
      }
      setCorrespondenceId(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCorrespondenceDetailsAPI.data])

  return (
    <Page title="Matters">
      <Container maxWidth={false}>
        <YaoTable
          label="Time"
          rows={list}
          columns={headLabels(currInfo)}
          sx={{ mt: 2.5 }}
          selectable
          rowProps={(row) => ({
            disabled: row.invoice,
          })}
          labelNoData={"No time entry at this point"}
          imgNoData={"/assets/illustrations/illustration_empty_content.svg"}
          toolbar={(selected) => (
            <>
              <MatterFinancialLimitBanner
                matterId={matterId}
                label="Budget"
                type="inline"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={invoiced}
                    onChange={(e) => setInvoiced(e.target.checked)}
                  />
                }
                sx={{ marginRight: 0 }}
                label={<Typography variant="caption">Invoiced</Typography>}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={wip}
                    onChange={(e) => setWip(e.target.checked)}
                  />
                }
                sx={{ marginRight: 0 }}
                label={<Typography variant="caption">WIP</Typography>}
              />

              <Button
                startIcon={
                  <CustomIcon
                    name="filter"
                    sx={{ padding: 0.2 }}
                    color={palette.yao.grey[6]}
                  />
                }
                onClick={() =>
                  NiceModal.show<TimeEntryFiltersFormModel>(Dialog, {
                    Content: FilterTimeEntry,
                    params: { filters },
                  }).then((newFilters) => {
                    setFilters(newFilters);
                  })
                }
              >
                Filter Time
              </Button>

              <Tooltip title={
                wip && invoiced ? "Download all time entries" :
                    wip ? "Download wipped time entries"
                        : "Download invoiced time entries"
              }>
              <Button startIcon={ downloadInProgress ? <CircularProgress size={10} /> : <Iconify icon="material-symbols:download" fontSize={10} />}
                      disabled={downloadInProgress}
                      onClick={handleDownload}
              >
                Download
              </Button>
              </Tooltip>

              <Button
                startIcon={
                  <CustomIcon
                    name="add"
                    sx={{ padding: 0.2 }}
                    color={palette.yao.grey[6]}
                  />
                }
                variant="outlined"
                onClick={() =>
                  NiceModal.show<TimeEntry>(TimeEntryDialog, { matterId }).then(
                    () => {
                      queryClient.invalidateQueries([matterId, "time-entries"]);
                    }
                  )
                }
              >
                New Time Entry
              </Button>

              <Button
                startIcon={
                  <CustomIcon
                    name="add"
                    sx={{ padding: 0.2 }}
                    color={palette.yao.grey[6]}
                  />
                }
                variant="outlined"
                onClick={() => {
                  const selectedTimeEntries = list.filter((t) =>
                    selected.includes(t._id)
                  );
                  invoicesApi.createDialog(
                    matterId as string,
                    selectedTimeEntries
                  );
                }}
              >
                New Invoice
              </Button>
            </>
          )}
          rowOptions={(row: TimeEntry) => {return[
            {
              action: (row) => {
                NiceModal.show<TimeEntry>(TimeEntryDialog, {
                  matterId,
                  timeEntry: row,
                }).then(() => {
                  queryClient.invalidateQueries([matterId, "time-entries"]);
                });
              },
              label: "Edit",
              icon: "eva:edit-fill",
            },
            {
              action: async (row) => {
                NiceModal.show(ConfirmDialog, {
                  title: "Are you sure you want to delete this time entry?",
                  description: "This item will be deleted, you can't undo it!",
                }).then(async () => {
                  await deleteTimeEntryAPI.invoke(row._id);
                  queryClient.invalidateQueries([matterId, "time-entries"]);
                });
              },
              label: "Delete",
              icon: "eva:trash-2-fill",
            },
            {
              action: (row) => {
                row.correspondence
                  ? setCorrespondenceId(row.correspondence)
                  : setCorrespondenceId(undefined);
              },
              label: "Document",
              icon: "material-symbols:preview-outline",
              color: row.correspondence ? "" : "#a5b4c3",
            },
          ]}}
          afterRows={(selected) => (
            <>
              <TableRow ref={ref} style={{ height: 0 }} />

              <TableRow
                style={{
                  position: "sticky",
                  bottom: 0,
                  height: 60,
                  backgroundColor: "white",
                }}
              >
                <Footer selected={selected} />
              </TableRow>
            </>
          )}
        />

        {openModel && (
          <Drawer
            anchor="right"
            open={!!openModel}
            onBackdropClick={() => setOpenModel(null)}
            hideBackdrop={true}
            variant="persistent"
          >
            <CorrespondenceFileViewer
              correspondence={openModel}
              handleClose={() => setOpenModel(null)}
              noBorder
            />
          </Drawer>
        )}

        {openDrawer && (
          <Drawer
            anchor="right"
            open={!!openDrawer}
            onBackdropClick={() => setOpenDrawer(null)}
            hideBackdrop={true}
            variant="persistent"
          >
            <CorrespondenceDetail
              correspondence={openDrawer}
              setOpenDrawer={setOpenDrawer}
            />
          </Drawer>
        )}

        {openEmailDrawer && (
          <Drawer
            sx={{ width: "30%" }}
            anchor="right"
            open={!!openEmailDrawer}
            onBackdropClick={() => setOpenEmailDrawer(null)}
            hideBackdrop={true}
            variant="persistent"
          >
            <CorrespondenceEmailDetail
              correspondence={openEmailDrawer}
              setOpenDrawer={setOpenEmailDrawer}
            />
          </Drawer>
        )}
      </Container>
    </Page>
  );
}
