import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Page from "src_common/components/Page";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import {
  correspondenceSignConfirmCode,
  correspondenceSignDetails,
  CorrespondenceSignDetailsDto,
  correspondenceSignRequestCode,
} from "../../api/correspondence-sign";
import ErrorIcon from "@mui/icons-material/Error";
import { WopiContext } from "../../../src_common/contexts/WopiContext";
import getBrowserFingerprint from "../../../src_common/utils/getBrowserFingerprint";
import { useSnackbar } from "notistack";
import ReactCodeInput from "react-code-input";
import { formatError } from "../../../src_common/utils/misc";
import { SupportedFileTypes } from "../../../src_common/components/yao-form-fields";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { CorrespondenceSignStatus } from "src_lawfirm/api/correspondence";

const SignDocumentsPage: FC = () => {
  const pageTitle = "Sign > Document";
  const location = useLocation();
  const wopiContext = useContext(WopiContext);
  const { enqueueSnackbar } = useSnackbar();

  const [d, setD] = useState<string>("");
  const [t, setT] = useState<string>("");
  const [fingerPrint, setFingerprint] = useState<string | undefined>(undefined);
  const formRef = useRef<HTMLFormElement>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [updateCode, setUpdateCode] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isOfficeDocument, setIsOfficeDocument] = useState<boolean>(false);
  const [s3Url, setS3Url] = useState<string>("");

  const queryDetails = useQuery<
    CorrespondenceSignDetailsDto,
    string,
    CorrespondenceSignDetailsDto
  >(
    ["sign-details", t],
    () => correspondenceSignDetails(t, { contactId: d.split(".")[1] }),
    {
      enabled: (t || "").length > 0,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    (async () => {
      const fp = await getBrowserFingerprint();
      setFingerprint(fp);
    })();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const pd = params.get("d");
    if (typeof pd === "string" && pd !== d) {
      setD(pd);
    }

    const pt = params.get("t");
    if (typeof pt === "string" && pt !== t) {
      setT(pt);
    }
  }, [location.search]);

  useEffect(() => {
    if (!queryDetails.data || !formRef) {
      return;
    }
    setS3Url(queryDetails.data.pre_signed);
    const ext = (queryDetails.data.key || "").trim().split(".").pop() || "";
    const useWopi =
      [
        SupportedFileTypes.DOC,
        SupportedFileTypes.DOCX,
        SupportedFileTypes.XLS,
        SupportedFileTypes.XLXS,
      ].indexOf(ext as unknown as SupportedFileTypes) !== -1;
    setIsOfficeDocument(useWopi);
    if (!useWopi) {
      return;
    }
    const url = wopiContext.generateWopiUrl(
      ext,
      queryDetails.data.key,
      queryDetails.data.wopi_token,
      false,
      true
    );
    setTimeout(() => {
      formRef.current?.setAttribute("action", url || "");
      formRef.current?.submit();
    }, 1000);
  }, [queryDetails.data, formRef]);

  const handleSignConfirm = async () => {
    try {
      setLoader(true);
      const sent = await correspondenceSignRequestCode(t, {
        token: d,
        code: "",
        fingerprint: "",
      });
      setLoader(false);
      if (sent) {
        setModalVisible(true);
      } else {
        setError("Error sending SMS code");
      }
    } catch (error) {
      setLoader(false);
      setError(formatError(error));
    }
  };

  const handleSave = async () => {
    try {
      setLoader(true);
      const sent = await correspondenceSignConfirmCode(t, {
        token: d,
        code: updateCode,
        fingerprint: fingerPrint,
      });
      setLoader(false);
      if (sent) {
        setModalVisible(false);
        setUpdated(true);
      } else {
        setError("Error sending your response");
      }
    } catch (error) {
      setLoader(false);
      setError(formatError(error));
    }
  };

  if (
    !wopiContext.routes ||
    queryDetails.isLoading ||
    queryDetails.isFetching ||
    queryDetails.isRefetching
  ) {
    return (
      <Page title={pageTitle}>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <CircularProgress size={20} sx={{ color: "#B3B3B3" }} />
          <Typography variant="caption">please wait...</Typography>
        </Stack>
      </Page>
    );
  }

  if (queryDetails.isError || error?.length > 0) {
    return (
      <Page title={pageTitle}>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <ErrorIcon color="error" fontSize="large" />
          <Typography variant="subtitle1" color="error.main">
            {queryDetails.error?.toString() ||
              error ||
              "Please try again in a few minutes"}
          </Typography>
        </Stack>
      </Page>
    );
  }

  if (updated) {
    return (
      <Page title="Forms > Contact information">
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Document signed successfully
            </Typography>
            <Typography sx={{ color: "#66bb6a" }}>Thank you</Typography>
          </Box>
        </Stack>
      </Page>
    );
  }

  return (
    <Page title={pageTitle}>
      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Request sign
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Check the document below and proceed with signing
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        {isOfficeDocument ? (
          <>
            <form
              ref={formRef}
              id="office_form"
              name="office_form"
              target="office_frame"
              action=""
              method="post"
            ></form>
            <Box style={{ width: "100%", height: "300px" }}>
              <iframe
                sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
                id="office_frame"
                name="office_frame"
                title="Office Frame"
                allowFullScreen={true}
                allowTransparency={true}
                style={{ width: "100%", height: "300px", border: 0 }}
              />
            </Box>
          </>
        ) : (
          <Box style={{ width: "100%", height: "800px" }}>
            <DocViewer
              prefetchMethod="GET"
              documents={[{ uri: s3Url }]}
              initialActiveDocument={{ uri: s3Url }}
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  disableHeader: false,
                  disableFileName: true,
                  retainURLParams: false,
                },
                pdfVerticalScrollByDefault: true,
              }}
            />
          </Box>
        )}

        <Stack spacing={1} alignItems="flex-end">
          <Typography variant="caption">
            {queryDetails.data.title} by {queryDetails.data.author}
          </Typography>
          <Stack alignItems="center">
            {d.includes("undefined") ? (
              <Typography
                fontWeight="bold"
                fontSize="16px"
                display="inline"
                color="primary"
                textAlign="center"
              >
                You cannot sign this document!
              </Typography>
            ) : queryDetails.data.sign_status ===
              CorrespondenceSignStatus.SIGNED ? (
              <>
                {" "}
                <Typography
                  fontWeight="bold"
                  fontSize="16px"
                  display="inline"
                  color="primary"
                  textAlign="center"
                >
                  Document already signed!
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  fontSize="16px"
                  display="inline"
                  marginY="15px"
                  textAlign="center"
                >
                  <Typography
                    fontWeight="bold"
                    fontSize="16px"
                    display="inline"
                    color="red"
                  >
                    Please note:
                  </Typography>{" "}
                  Clicking 'Confirm & Sign' indicates{" "}
                  <Typography
                    fontSize="16px"
                    display="inline"
                    style={{ textDecoration: "underline" }}
                    color="primary"
                    fontWeight="bold"
                  >
                    {" "}
                    your legal agreement and acceptance{" "}
                  </Typography>{" "}
                  of the terms outlined in this document in the same way a
                  handwritten signature does.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ minWidth: 100, fontSize: "14px", maxWidth: 500 }}
                  type="submit"
                  onClick={handleSignConfirm}
                  disabled={!fingerPrint}
                >
                  Confirm & Sign
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
      <Dialog
        maxWidth="xs"
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <DialogContent className="contact-dialog-content">
          <Typography variant="subtitle2">
            please enter the code received by sms below to confirm
          </Typography>
          <Stack justifyContent="center" alignItems="center" mt={1}>
            <ReactCodeInput
              name="update_code"
              value={updateCode}
              onChange={(value: string) => setUpdateCode(value)}
              type="text"
              fields={4}
              inputMode={"latin"}
            />
          </Stack>
        </DialogContent>
        <DialogActions className="contact-dialog-action">
          <Button color="secondary" onClick={() => setModalVisible(false)}>
            cancel
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            type="submit"
            onClick={handleSave}
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};

export default SignDocumentsPage;
