import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { ReactNode, SyntheticEvent, useState, useEffect } from 'react';
import { UseFormSetValue } from "react-hook-form";
import { RHFTextField } from "src_common/components/hook-form";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { orderBy as _orderBy, get as _get, uniqBy as _uniqBy } from "lodash";

type Props = {
  isTo: boolean;
  setValue?: UseFormSetValue<any>;
  contacts?: string[]
  observableValue?: string | undefined;
};

type FilterType = {
  inputValue?: string;
  title: string;
};

const filter = createFilterOptions<FilterType>();

export default function ExternalAccount({
  isTo,
  setValue,
  contacts = [],
  observableValue = undefined
}: Props) {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    setOptions(_orderBy(contacts.map(c => ({ title: c })), ['title'], ['asc']));
  }, [contacts])

  useEffect(() => {

    if(observableValue !== undefined && observableValue.length > 0 && inputValue !== observableValue){
      setInputValue(observableValue);
    }
    
  }, [observableValue])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mt={1}>
        <Autocomplete
          id="contact-selector"
          options={options}
          getOptionLabel={(option: any) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          isOptionEqualToValue={(o, v) => o.title === v?.title}
          disableClearable
          freeSolo
          handleHomeEndKeys
          selectOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder="Enter or select full name"
              label="Full name"
            />
          )}
          inputValue={inputValue}
          onInputChange={(e: SyntheticEvent, value: string) => {
            e.preventDefault();
            e.stopPropagation();
            setInputValue(value);
            setValue && setValue(isTo ? "to.name" : "from.name", value);
          }}
          onChange={(e: SyntheticEvent, newValue: any) => {
            e.preventDefault();
            e.stopPropagation();
            let name = "";
            if (typeof newValue === "string") {
              name = newValue;
            } else if (newValue && newValue.inputValue) {
              name = newValue.inputValue;
            } else {
              name = newValue.title;
            }
            setValue && setValue(isTo ? "to.name" : "from.name", name);
          }}
        />
      </Grid>

      {isTo && (
        <>
          <Grid item xs={4}>
            <YaoFormFieldLabel
              name={
                isTo ? "to_account_number_label" : "from_account_number_label"
              }
              label="Account number"
              required
            />
            <RHFTextField
              id={isTo ? "to.account_number" : "from.account_number"}
              name={isTo ? "to.account_number" : "from.account_number"}
              variant="outlined"
              placeholder="Enter account number"
            />
          </Grid>
          <Grid item xs={2}>
            <YaoFormFieldLabel
              name={
                isTo
                  ? "to_sorting_code_number_label"
                  : "from_sorting_code_number_label"
              }
              label="Sort code"
              required
            />
            <RHFTextField
              id={isTo ? "to.sorting_code" : "from.sorting_code"}
              name={isTo ? "to.sorting_code" : "from.sorting_code"}
              variant="outlined"
              placeholder="Enter code"
            />
          </Grid>

          <Grid item xs={6}>
            <YaoFormFieldLabel
              name={isTo ? "to_reference_label" : "from_reference_label"}
              label={isTo ? "Payee Reference" : "Display on Ledger as:"}
              required={!isTo}
            />
            <RHFTextField
              id={isTo ? "to.reference" : "from.reference"}
              name={isTo ? "to.reference" : "from.reference"}
              variant="outlined"
              placeholder={isTo ? "enter Payee reference" : "enter text to be displayed on ledger"}
              required={!isTo}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
