import {FC, useEffect, useState} from "react";
import Page from "src_common/components/Page";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import {formatError} from "src_common/utils/misc";
import {
    FormPublicResponse,
    formsPublicDetails,
    formsPublicGenerateCode,
    formsPublicReply,
} from "../../api/forms";
import { encodeLabel, decodeLabel } from "src_common/utils/forms"
import {FormField, FormFieldType, FormAnswer} from "src_common/@interfaces/forms";
import {SubmitHandler, useForm} from "react-hook-form";
import {FormProvider} from "src_common/components/hook-form";
import {
    YAOFieldAutocomplete,
    YAOFieldDatePicker,
    YAOFieldMaskedNumber,
    YAOFieldText
} from "src_common/components/yao-form-fields";
import ReactCodeInput from "react-code-input";
import {useContacts} from "../contacts/useContacts";

const FormRenderer: FC<{
    fields: FormField[],
    onSave: (answers: FormAnswer[]) => void
}> = ({fields, onSave}) => {

    const methods = useForm();

    useEffect(() => {
        methods.clearErrors();
        methods.reset();
    }, [fields])

    const onSubmit: SubmitHandler<{ [key: string]: any }> = (data) => {
        if (!data) {
            return;
        }
        onSave && onSave(Object.keys(data).map((key) => ({
            label: decodeLabel(key),
            answer: data[key] || "",
        })));
    }

    const renderField = (field: FormField) => {
        if (!field) {
            return null;
        }
        const label = encodeLabel(field.label);
        switch (field.type) {
            case FormFieldType.TEXT:
                return (
                    <YAOFieldText
                        name={label}
                        label=""
                        placeholder="Enter answer"
                        type="text"
                        rules={{
                            required: true
                        }}
                    />
                );
            case FormFieldType.PARAGRAPH:
                return (
                    <YAOFieldText
                        name={label}
                        label=""
                        placeholder="Enter answer"
                        multiline
                        rows={3}
                        rules={{
                            required: true
                        }}
                    />
                );
            case FormFieldType.NUMBER:
                return (
                    <YAOFieldMaskedNumber
                        name={label}
                        label=""
                        placeholder="Enter answer"
                        rules={{
                            required: true
                        }}
                    />
                );
            case FormFieldType.YES_NO:
                return (
                    <YAOFieldAutocomplete
                        name={label}
                        label=""
                        placeholder="Choose answer"
                        options={["YES", "NO"]}
                        rules={{
                            required: true
                        }}
                    />
                );
            case FormFieldType.DATE:
                return (
                    <YAOFieldDatePicker
                        name={label}
                        datePickerProps={{
                            views: ["year", "month", "day"],
                        }}
                        rules={{
                            required: true
                        }}
                        defaultValue={null}
                    />
                );
            default:
                return <Typography>invalid type</Typography>;
        }
    };

    return <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>

        <Grid container rowSpacing={1} columnSpacing={1}>
            {fields.map((f, idx) => (
                <Grid item xs={12} sx={{p: 0}} key={idx}>
                    <Grid container sx={{p: 0}}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                fontWeight={600}
                                fontSize={16}
                                lineHeight="24px"
                                sx={{color: "#919EAB"}}
                            >
                                {f.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {renderField(f)}
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
        <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{marginTop: 2}}
        >
            <Button
                variant="contained"
                onClick={() => methods.handleSubmit(onSubmit)()}
            >
                Save
            </Button>
        </Stack>

    </FormProvider>
}

const FormReplyEmbedded: FC = () => {

    let {token} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const contactsApi = useContacts();
    const [error, setError] = useState<string>("");
    const [loader, setLoader] = useState<string>("Await...");
    const [form, setForm] = useState<FormPublicResponse>();
    const [answers, setAnswers] = useState<FormAnswer[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [update_code, setUpdateCode] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (typeof token !== "string" || !token.length) {
            setError("Invalid token");
            return;
        }
        setLoader("Loading document data...");
        (async () => {
            try {
                const response = await formsPublicDetails(decodeURIComponent(token));
                if (!response) {
                    throw new Error("Document not found");
                }
                setForm(response);
                if (response.isFilled) {
                    throw new Error("This form is already filled");
                }
                setLoader("");
            } catch (error) {
                setError(formatError(error));
                setLoader("");
            }
        })();
    }, [token]);

    const handleFormSave = (answers: FormAnswer[]) => {
        setAnswers(answers);
        if (!(form?.contact || '').length) {
            handleSave(answers);
            return;
        }
        (async () => {
            try {
                const sent = await formsPublicGenerateCode(form?._id || '');
                if (sent) {
                    setModalVisible(true)
                } else {
                    enqueueSnackbar("Error sending SMS code", {variant: "error"});
                }
            } catch (error) {
                enqueueSnackbar(error, {variant: "error"});
            }
        })();
    }

    const handleSave = (answers: FormAnswer[] = []) => {
        if(!answers.length){
            return;
        }
        (async () => {
            try {
                const response = await formsPublicReply(
                    form?._id || '',
                    {
                        answers,
                        update_code
                    }
                );
                if(response?._id === form?._id){
                    setSuccess(true);
                }
            } catch (error) {
                enqueueSnackbar(error, {variant: "error"});
            }
        })();
    }

    if (loader.length > 0) {
        return (
            <Page title="Forms > Document to fill">
                <Stack direction="row" alignItems="center" sx={{mb: 5}}>
                    <Box sx={{flexGrow: 1}}>
                        <CircularProgress/>
                        <Typography variant="h4" gutterBottom>
                            {loader}
                        </Typography>
                    </Box>
                </Stack>
            </Page>
        );
    }

    if (error.length > 0) {
        return (
            <Page title="Forms > Document to fill">
                <Stack direction="row" alignItems="center" sx={{mb: 5}}>
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant="h4" gutterBottom>
                            {form?.title || "something went wrong"}
                        </Typography>
                        <Typography sx={{color: "#f44336"}}>{error}</Typography>
                    </Box>
                </Stack>
            </Page>
        );
    }

    if (form?.isFilled) {
        return (
            <Page title="Forms > Document to fill">
                <Stack direction="row" alignItems="center" sx={{mb: 5}}>
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant="h4" gutterBottom>
                            Document to fill
                        </Typography>
                        <Typography sx={{color: "#f44336"}}>{error}</Typography>
                    </Box>
                </Stack>
            </Page>
        );
    }

    if(success){
       return (<Page title="Forms > Document to fill">
            <Stack direction="row" alignItems="center" sx={{mb: 5}}>
                <Box sx={{flexGrow: 1}}>
                    <Typography variant="h4" gutterBottom>
                        Document to fill
                    </Typography>
                    <Typography color="success.main">thank you for your answers</Typography>
                </Box>
            </Stack>
        </Page>)
    }

    return (
        <Page title="Forms > Document to fill">
            <Stack alignItems="center" sx={{mb: 5}}>
                <Box sx={{flexGrow: 1}}>
                    <Typography variant="h4" gutterBottom>
                        {form?.title}
                    </Typography>
                    <Typography sx={{color: "text.secondary"}}>
                        {form?.description}
                    </Typography>
                </Box>
            </Stack>
            <FormRenderer fields={form?.fields || []} onSave={handleFormSave}/>
            <Dialog
                maxWidth="xs"
                open={modalVisible}
                onClose={() => setModalVisible(false)}
            >
                <DialogContent className="contact-dialog-content">
                    <Typography variant="subtitle2">
                        please enter the code received by sms below to confirm
                    </Typography>
                    <Stack justifyContent="center" alignItems="center" mt={1}>
                        <ReactCodeInput
                            name="update_code"
                            value={update_code}
                            onChange={(value: string) => setUpdateCode(value)}
                            type="text"
                            fields={4}
                            inputMode={"latin"}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions className="contact-dialog-action">
                    <Button color="secondary" onClick={() => setModalVisible(false)}>
                        cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{minWidth: 100}}
                        type="submit"
                        onClick={() => handleSave(answers)}
                        disabled={!update_code?.length}
                    >
                        save
                    </Button>
                </DialogActions>
            </Dialog>
        </Page>
    )

}

export default FormReplyEmbedded
