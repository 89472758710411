import { FC, SyntheticEvent, useEffect, useState, HTMLAttributes, Dispatch } from "react";
import { UseFormReturn } from "react-hook-form";
import { PaymentNotificationFormData } from "src_lawfirm/api/payment-notifications";
import { useAPI } from "src_common/hooks/useAPI";
import { getMatters, Matter } from "src_lawfirm/api/matters";
import useDebounce from "src_common/hooks/useDebounce";
import { useSnackbar } from "notistack";
import { formatError } from "src_common/utils/misc";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import palette from "src_common/theme/palette";
import Iconify from "src_common/components/Iconify";

interface PaymentNotificationM2MProps {
  matter: string;
  methods: UseFormReturn<PaymentNotificationFormData, any>;
  transferMatter: Matter | undefined;
  setTransferMatter: Dispatch<Matter | undefined>;
}

export const PaymentNotificationM2M: FC<PaymentNotificationM2MProps> = ({
  matter,
  methods,
  transferMatter, 
  setTransferMatter
}) => {
  const { debounce, reset: clearDebounce } = useDebounce();
  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    clearDebounce();
    if (!inputValue.length) {
      setOpen(false);
      setOptions([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    debounce(async () => {
      try {
        const response = await getMatters({
          search: inputValue,
        });
        setOptions(response.filter((o) => o._id !== matter));
      } catch (error) {
        setOptions([]);
        enqueueSnackbar(formatError(error), { variant: "error" });
      } finally {
        clearDebounce();
        setLoading(false);
      }
    }, 250);
  }, [inputValue]);

  return (
    <Autocomplete
      id="m2mfield1589"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(o: Matter, v: Matter) => o._id === v._id}
      filterOptions={(o) => o}
      options={options}
      loading={loading}
      value={transferMatter}
      blurOnSelect={true}
      onChange={(_: SyntheticEvent, value: Matter | null) => {
        setTransferMatter(value || undefined);
      }}
      inputValue={inputValue}
      onInputChange={(_: SyntheticEvent, v: string) => setInputValue(v)}
      noOptionsText={
        !inputValue.length
          ? "Type anything to search matter"
          : "No matter found"
      }
      disableClearable
      forcePopupIcon={false}
      clearOnEscape={false}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search by matter name"
          fullWidth
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-m2mfield1589",
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
          variant="outlined"
        />
      )}
      getOptionLabel={(o: Matter) => o?.case_name || ""}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: Matter) => (
        <Stack
          {...props}
          direction="column"
          component="li"
          key={option._id}
          sx={{
            "&&": {
              borderRadius: 0,
              borderBottomWidth: "1px",
              borderBottomColor: palette.yao.primary[4],
              borderBottomStyle: "solid",
            },
          }}
        >
          <Stack direction="row" spacing={1} width="100%">
            <Typography
              textAlign="left"
              color={palette.yao.primary[2]}
              fontSize="12px"
              lineHeight="24px"
              fontWeight={600}
            >
              #{option.number} - {option.case_name}
            </Typography>
            <Box
              sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: palette.yao.grey[5],
                borderRadius: "3px",
                height: "20px",
                padding: "2px",
                marginTop: "-4px!important",
                marginLeft: "4px!important",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="#be6cda" fontSize={10}>
                {option.status}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={1} width="100%" alignItems="center">
            <Iconify icon="clarity:user-line" color={palette.yao.grey[6]} />
            <Typography
              variant="caption"
              color={palette.yao.primary[2]}
              fontSize="12px"
              lineHeight="32px"
              fontWeight={400}
            >
              {option.responsible_lawyer?.name}
            </Typography>
          </Stack>
        </Stack>
      )}
    />
  );
};
