import React, {MutableRefObject, useContext} from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CustomIcon from "src_common/components/custom-icon";
import { Box, Link } from "@mui/material";
import { getAttachmentIconName } from 'src_common/utils/fileHelpers';
import {WopiContext} from "src_common/contexts/WopiContext";
import {WOPI_API} from "../../config";

type Props = {
  file: string;
  ext: string;
  wopi_key?: string;
  wopi_token?: string;
    wopi_form?: MutableRefObject<HTMLFormElement>
};

export const FileViewer: React.FC<Props> = ({
                                                file,
                                                ext,
                                                wopi_token = '',
                                                wopi_key = '',
                                                wopi_form = undefined}) => {

    const wopiContext = useContext(WopiContext);

  switch (ext.toLowerCase()) {
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
          if(!wopi_form || !wopi_token?.length || !wopi_key?.length || !wopiContext.routes) {
              return (
                  <Box
                      sx={{
                          display: "flex",
                          p: 2,
                          justifyContent: "center",
                          height: "100%",
                          alignItems: "center",
                      }}
                  >
                      <Link href={file} target="_blank" rel="noopener">
                          {
                              <CustomIcon
                                  key={"icons_" + file}
                                  name={getAttachmentIconName(ext) || ''}
                                  size={200}
                              />
                          }
                          <Box sx={{ fontWeight: "bold" }}>Click to download the file</Box>
                      </Link>
                  </Box>
              );
          }
          const url = wopiContext.generateWopiUrl(
              ext,
              wopi_key,
              wopi_token,
              false,
              false,
          )
          setTimeout(() => {
              wopi_form.current.setAttribute('action', url || '')
              wopi_form.current.submit();
          }, 1000)
          return <Box style={{ width: '100%', height: '100%' }}>
              <iframe sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
                      id="office_frame"
                      name="office_frame"
                      title="Office Frame"
                      allowFullScreen={true}
                      allowTransparency={true}
                      style={{ width: '100%', height: '100%', border: 0 }}

              />
          </Box>
          break;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "pdf":
      const docs = [{ uri: file }];
      return (
        <DocViewer
          prefetchMethod="GET"
          documents={docs}
          initialActiveDocument={docs[0]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: false,
              disableFileName: true,
              retainURLParams: false,
            },
              pdfVerticalScrollByDefault: true,
          }}
        />
      );
    default:
      return <div>File type not supported</div>;
  }
};
