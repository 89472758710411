import NiceModal from "@ebay/nice-modal-react";
import {useMsal} from "@azure/msal-react";
import {useEffect} from "react";
import Router from "./routes";
import ThemeProvider from "src_common/theme";
import ThemeSettings from "src_common/components/settings";
import {ChartStyle} from "src_common/components/chart";
import ScrollToTop from "src_common/components/ScrollToTop";
import {ProgressBarStyle} from "src_common/components/ProgressBar";
import NotistackProvider from "src_common/components/NotistackProvider";
import MotionLazyContainer from "src_common/components/animate/MotionLazyContainer";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {CorrespondenceProvider} from "src_common/contexts/CorrespondenceContext";
import {NotificationContextProvider} from "src_common/components/notifications/NotificationContext";
import {LawFirmContextProvider} from "./pages/law-firm/LawFirmContext";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

export default function App() {
    const {instance, accounts} = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && !instance.getActiveAccount()) {
            instance.setActiveAccount(accounts[0]);
        }
    }, []);

    return (<QueryClientProvider client={queryClient}>
            <MotionLazyContainer>
                <ThemeProvider>
                    <ThemeSettings>
                        <NotistackProvider>
                            <LawFirmContextProvider>
                            <NiceModal.Provider>
                                <ProgressBarStyle/>
                                <ChartStyle/>
                                <ScrollToTop/>
                                <NotificationContextProvider>
                                    <CorrespondenceProvider>
                                        <Router/>
                                    </CorrespondenceProvider>
                                </NotificationContextProvider>
                            </NiceModal.Provider>
                            </LawFirmContextProvider>
                        </NotistackProvider>
                    </ThemeSettings>
                </ThemeProvider>
            </MotionLazyContainer>
        </QueryClientProvider>);
}
