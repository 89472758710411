import { formatError } from "src_common/utils/misc";
import axios from "src_common/utils/axios";
import { BankAccountTypes } from "./bank-account";

export enum PaymentMethodTypes {
  CHEQUE = "CHEQUE",
  CREDIT_CARD = "CREDIT_CARD",
  DIRECT_DEBIT = "DIRECT_DEBIT",
  FASTER_PAYMENT = "FASTER_PAYMENT",
  STANDING_OFFER = "STANDING_OFFER",
  CHAPS = "CHAPS",
  BACS = "BACS",
}

export const PaymentMethod = {
  CHEQUE: { name: "CHEQUE", description: "Cheque" },
  CREDIT_CARD: { name: "CREDIT_CARD", description: "Credit Card" },
  DIRECT_DEBIT: { name: "DIRECT_DEBIT", description: "Direct Debit" },
  FASTER_PAYMENT: { name: "FASTER_PAYMENT", description: "Faster Payment" },
  STANDING_OFFER: { name: "STANDING_OFFER", description: "Standing Offer" },
  CHAPS: { name: "CHAPS", description: "Chaps" },
  BACS: { name: "BACS", description: "Bacs" },
};

export type NotificationAccount = {
  type: BankAccountTypes;
  account?: string;
  account_id?: string;
  account_number?: string;
  sorting_code?: string;
  account_name?: string;
  name?: string;
  reference?: string;
};

export type PaymentNotificationFormData = {
  draft: boolean;
  notes: string;
  value: number;
  vat_percentage: number;
  date: string;
  type: string;
  law_firm: string;
  status: PaymentNotificationStatus;
  matter: string;
  matter_title: string;
  author: string;
  author_name: string;
  approved_by: string;
  approved_by_name: string;
  method?: PaymentMethodTypes;
  from: NotificationAccount;
  to: NotificationAccount;
  createdAt: string;
  anticipated?: boolean;
  invoice?: string;
  disbursements?: { _id: string; value: number; }[];
  transfer_matter?: string;
};

export enum PaymentNotificationStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REVERSED = "REVERSED",
  REJECTED = "REJECTED",
}

interface IPaymentNotificationStatus {
  name: string;
  color: string;
  backgroundColor: string;
}

export const getPaymentNotificationStatus = (status: PaymentNotificationStatus): IPaymentNotificationStatus => ({
  [PaymentNotificationStatus.DRAFT]: {name: "Draft", color: "#0D394D", backgroundColor: "#D8E4E4"},
  [PaymentNotificationStatus.PENDING]: {name: "Pending", color: "#E49060", backgroundColor: "#FFF5ED"},
  [PaymentNotificationStatus.APPROVED]: {name: "Approved", color: "#09B5B5", backgroundColor: "#ECFBFC"},
  [PaymentNotificationStatus.REVERSED]: {name: "Reversed", color: "#0D394D", backgroundColor: "#fee0a0"},
  [PaymentNotificationStatus.REJECTED]: {name: "Rejected", color: "#E4607B", backgroundColor: "#FFF0F3"},
}[status])

export interface SuccessResponse {
  success: boolean;
}

export interface SearchPaymentNotification {
  page: number;
  size: number;
  status?: PaymentNotificationStatus;
  start?: Date;
  end?: Date;
  matter?: string;
  from_type?: BankAccountTypes;
  to_type?: BankAccountTypes;
  value?: number
}

export interface PaymentNotification {
  _id: string;
  notes: string;
  value: number;
  vat_percentage: number;
  approved_by_name: string;
  author_name: string;
  matter_title: string;
  status: PaymentNotificationStatus;
  method: PaymentMethodTypes;
  from: NotificationAccount;
  to: NotificationAccount;
  created_at: string;
  date: string;
  matter: {
    _id: string;
    number: number;
  }
}

export async function createPaymentNotification(
  form: PaymentNotificationFormData
): Promise<PaymentNotificationFormData> {
  try {
    const res = await axios.post("payment-notifications/", form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updatePaymentNotification(
  id: string,
  form: PaymentNotificationFormData
): Promise<PaymentNotificationFormData> {
  try {
    const res = await axios.put(`payment-notifications/${id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function searchPaymentNotifications(
  search: SearchPaymentNotification
): Promise<PaymentNotification[]> {
  try {
    const res = await axios.post("payment-notifications/search", search);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function approvePaymentNotification(
  id: string
): Promise<SuccessResponse> {
  try {
    return await axios.post(`payment-notifications/${id}/approve`);
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export interface rejectBody {
  id: string;
  reject_reason: string;
}

export async function rejectPaymentNotification(
  body: rejectBody
): Promise<SuccessResponse> {
  try {
    return await axios.patch(
      `payment-notifications/${body.id}/reject`,
      body.reject_reason
    );
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function deletePaymentNotification(id: string) {
  try {
    return axios.delete(`payment-notifications/${id}`);
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export type SearchAccountType = 'CP' | 'CR' | 'OP' | 'OR' | 'O2C' | 'C2O' | 'C2C' | 'any'
 
export function paymentnotificationType(data: PaymentNotification): string {
  if(!data || !data.to || !data.from){
    return ''
  }

  const { type: toType } = data.to;
  const { type: fromType } = data.from;

  if(fromType === BankAccountTypes.CLIENT && toType === BankAccountTypes.EXTERNAL){
    return 'Client Payment (CP)'
  }

  if(fromType === BankAccountTypes.EXTERNAL && toType === BankAccountTypes.CLIENT){
    return 'Client Receipt (CR)'
  }

  if(fromType === BankAccountTypes.OFFICE && toType === BankAccountTypes.EXTERNAL){
    return 'Office Payment (OP)'
  }

  if(fromType === BankAccountTypes.EXTERNAL && toType === BankAccountTypes.OFFICE){
    return 'Office Receipt (OR)'
  }

  if(fromType === BankAccountTypes.OFFICE && toType === BankAccountTypes.CLIENT){
    return 'Office to Client (O2C)'
  }

  if(fromType === BankAccountTypes.CLIENT && toType === BankAccountTypes.OFFICE){
    return 'Client to Office (C2O)'
  }
  
  return 'Client to Client (C2C)';
}

export function paymentNotificationGroup(data: PaymentNotification): string {
  if(!data || !data.to || !data.from){
    return ''
  }

  const { type: toType } = data.to;
  const { type: fromType } = data.from;

  if(
    (fromType === BankAccountTypes.CLIENT && toType === BankAccountTypes.EXTERNAL) ||
    (fromType === BankAccountTypes.EXTERNAL && toType === BankAccountTypes.CLIENT) ||
    (fromType === BankAccountTypes.CLIENT && toType === BankAccountTypes.OFFICE)
  ){
    return 'Client'
  }

  return 'Office';
}

export function paymentNotificationClientName(data: PaymentNotification): string {
  if(!data || !data.to){
    return ''
  }
  return data.to.name || '';
}