import { MouseEvent, useContext, useEffect, useMemo, useState } from "react";
import {
  Backdrop,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Page from "src_common/components/Page";
import { useAPI } from "src_common/hooks/useAPI";
import { fCurrencyWithFormat } from "src_common/utils/formatNumber";
import {
  getDisbursementsTotal,
  LedgerEntryStatus,
  LedgerEntryTypeLabel,
  reverseLedger,
  SearchLedger,
  searchLedgers,
} from "../../api/ledgers";
import palette from "src_common/theme/palette";
import { BankAccountTypes } from "../../api/bank-account";
import CustomIcon from "src_common/components/custom-icon";
import PaymentNotificationMenu from "./PaymentNotificationMenu";
import { TableNoData, TableSkeleton } from "src_common/components/table";
import { format } from "date-fns";
import Iconify from "src_common/components/Iconify";
import Decimal from "decimal.js";
import { MatterFinancialLimitBanner } from "./MatterFinancialLimitBanner";
import { useInView } from "react-intersection-observer";
import "./footer.css";
import NiceModal from "@ebay/nice-modal-react";
import ReverseConfirmModal from "../reconciliation/ReverseConfirm";
import { formatError } from "src_common/utils/misc";
import { useSnackbar } from "notistack";
import { MatterDetailsContext } from "./context/matter-details-context";
import useAuth from "../../../src_common/hooks/useAuth";
import { AttorneyPermission } from "../../api/attorneys";
import { LawFirmContext } from "../law-firm/LawFirmContext";

Decimal.set({ precision: 10, rounding: 2 });

const DATE_PATTERN = "dd.MM.yyyy";

interface typeCreating {
  creating: boolean;
  title?: string;
  fromType?: BankAccountTypes;
  toType?: BankAccountTypes;
}

export default function LedgersTab() {
  const lawFirmContext = useContext(LawFirmContext);
  const matterCtx = useContext(MatterDetailsContext);
  const { enqueueSnackbar } = useSnackbar();
  const getLedgersAPI = useAPI(searchLedgers);
  const getOfficeDisbursement = useAPI(getDisbursementsTotal);
  const [accountTypesFilter, setAccountTypesFilter] = useState({
    CLIENT: true,
    OFFICE: true,
  });
  const [typeToSearch, setTypeToSearch] = useState<BankAccountTypes | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { ref: footerRef, inView } = useInView({
    threshold: 0,
    initialInView: false,
  });
  const [postingEnabled, setPostingEnabled] = useState<boolean>(false);

  const [creatingPaymentNotification, setCreatingPaymentNotification] =
    useState<typeCreating>({
      creating: false,
      title: undefined,
      fromType: undefined,
      toType: undefined,
    });
  const [selectedMatterId, setSelectedMatterId] = useState("");

  const searchLedgersPayload: SearchLedger = {
    page: 0,
    size: 10000,
    status: undefined,
    start: null,
    end: null,
    matter: matterCtx.matterId,
    type: typeToSearch,
  };
  const { user } = useAuth();

  const currInfo = useMemo(
    () => lawFirmContext.getCurrency(),
    [lawFirmContext]
  );

  useEffect(() => {
    setPostingEnabled(
      !!user &&
        Array.isArray(user.permissions) &&
        (user.permissions.includes(AttorneyPermission.ADMIN) ||
          user.permissions.includes(AttorneyPermission.ACCOUNTANT))
    );
  }, [user]);

  useEffect(() => {
    getLedgersAPI.invoke(searchLedgersPayload);
    getOfficeDisbursement.invoke({ matter: matterCtx.matterId });
  }, [typeToSearch]);

  useEffect(() => {
    getOfficeDisbursement.invoke({ matter: matterCtx.matterId });
  }, [matterCtx.matterId]);

  useEffect(() => {
    if (accountTypesFilter.CLIENT && accountTypesFilter.OFFICE) {
      setTypeToSearch(null);
    }
    if (accountTypesFilter.CLIENT && !accountTypesFilter.OFFICE) {
      setTypeToSearch(BankAccountTypes.CLIENT);
    }
    if (accountTypesFilter.OFFICE && !accountTypesFilter.CLIENT) {
      setTypeToSearch(BankAccountTypes.OFFICE);
    }
    if (!accountTypesFilter.OFFICE && !accountTypesFilter.CLIENT) {
      setAccountTypesFilter({ CLIENT: true, OFFICE: true });
    }
  }, [accountTypesFilter]);

  const handleAccountTypesFilter = (
    type: BankAccountTypes,
    checked: boolean
  ) => {
    setAccountTypesFilter({ ...accountTypesFilter, [type]: checked });
  };

  const handleCloseForm = () => {
    setCreatingPaymentNotification({
      ...creatingPaymentNotification,
      creating: false,
    });
  };

  const renderLoading = () => {
    if (!getLedgersAPI.loading) {
      return null;
    }
    return [...Array(20)].map((_, i) => <TableSkeleton key={i} />);
  };

  const renderEmpty = () => {
    if (
      getLedgersAPI.loading ||
      (Array.isArray(getLedgersAPI.data) && getLedgersAPI.data?.length > 0)
    ) {
      return null;
    }
    return (
      <TableNoData isNotFound={true} label="No data found for this selection" />
    );
  };

  const getFormattedDate = (dtValue: any) => {
    try {
      return format(new Date(dtValue), DATE_PATTERN);
    } catch {
      return "";
    }
  };

  const renderRows = () => {
    if (getLedgersAPI.loading || !Array.isArray(getLedgersAPI.data)) {
      return null;
    }

    let client_total = new Decimal(0);
    let office_total = new Decimal(0);

    return getLedgersAPI.data
      .filter(
        (r) =>
          [
            LedgerEntryStatus.UNRECONCILED,
            LedgerEntryStatus.RECONCILED,
          ].indexOf(r.status) !== -1
      )
      .reverse()
      .map((r, i) => {
        const isClient = BankAccountTypes.CLIENT === r.account_type;
        const isUnreconciled = LedgerEntryStatus.UNRECONCILED === r.status;
        const statusColor = isUnreconciled
          ? palette.yao.secondary[2]
          : palette.yao.secondary[1];
        const showReverse = isUnreconciled && !(r.reversed_id || "").length;

        if (isClient) {
          client_total = client_total.plus(new Decimal(r.value || 0));
        } else {
          office_total = office_total.plus(new Decimal(r.value || 0));
        }

        const isVatInItalic =
          !new Decimal(r.vat || 0).equals(0) &&
          new Decimal(r.subtotal || 0).equals(new Decimal(r.value || 0));
        return (
          <TableRow key={r._id} sx={{ position: "relative" }}>
            <TableCell align="left">{getFormattedDate(r.date)}</TableCell>
            <TableCell align="left">
              {!r.type ? (
                ""
              ) : (
                <Tooltip
                  title={LedgerEntryTypeLabel[r.type]?.text || ""}
                  placement="top"
                >
                  <Typography component="span">
                    {LedgerEntryTypeLabel[r.type]?.abbr || ""}
                  </Typography>
                </Tooltip>
              )}
            </TableCell>
            <TableCell align="left">{r.payee || ""}</TableCell>
            <TableCell align="left">{r.reference}</TableCell>
            <TableCell
              align="left"
              sx={{ fontStyle: isVatInItalic ? "italic" : "normal" }}
            >
              {fCurrencyWithFormat(
                r.vat,
                currInfo.locale,
                currInfo.currency,
                currInfo.symbol
              )}
            </TableCell>
            <TableCell align="left">
              {isClient
                ? ""
                : fCurrencyWithFormat(
                    r.subtotal || r.value,
                    currInfo.locale,
                    currInfo.currency,
                    currInfo.symbol
                  )}
            </TableCell>
            <TableCell align="left">
              {isClient
                ? ""
                : fCurrencyWithFormat(
                    office_total.toNumber(),
                    currInfo.locale,
                    currInfo.currency,
                    currInfo.symbol
                  )}
            </TableCell>
            <TableCell align="left">
              {isClient
                ? fCurrencyWithFormat(
                    r.subtotal || r.value,
                    currInfo.locale,
                    currInfo.currency,
                    currInfo.symbol
                  )
                : ""}
            </TableCell>
            <TableCell align="left">
              {isClient
                ? fCurrencyWithFormat(
                    client_total.toNumber(),
                    currInfo.locale,
                    currInfo.currency,
                    currInfo.symbol
                  )
                : ""}
            </TableCell>
            <TableCell align="center">
              <Tooltip
                title={
                  <Grid
                    style={{ width: "286px", height: "100%", padding: "20px" }}
                  >
                    {r.notes?.length > 0 ? (
                      <Typography>{r.notes}</Typography>
                    ) : (
                      <Typography>No note</Typography>
                    )}
                  </Grid>
                }
              >
                <Button
                  sx={{ padding: 0 }}
                  className="todo-button"
                  startIcon={<CustomIcon name="chat" />}
                >
                  <Badge
                    badgeContent={r.notes?.length > 0 ? 1 : 0}
                    className="select-list"
                    componentsProps={{
                      root: {
                        style: {
                          color: palette.yao.secondary[2],
                          fontSize: "10px",
                          position: "relative",
                          bottom: "8px",
                          right: "10px",
                        },
                      },
                    }}
                  />
                </Button>
              </Tooltip>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                color: isUnreconciled
                  ? palette.yao.grey[8]
                  : palette.yao.grey[7],
                fontWeight: isUnreconciled ? "600" : "400",
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                height: "100%",
                minHeight: "52px",
              }}
            >
              <Iconify
                icon={
                  isUnreconciled
                    ? "akar-icons:circle-minus"
                    : "akar-icons:check"
                }
                color={statusColor}
              />
              {isUnreconciled ? "U" : "R"}
              {showReverse ? (
                <IconButton
                  aria-describedby={`popover-${r._id}`}
                  data-id={r._id}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <Iconify
                    icon={"eva:more-vertical-fill"}
                    width={20}
                    height={20}
                  />
                </IconButton>
              ) : null}
            </TableCell>
          </TableRow>
        );
      })
      .reverse();
  };

  const renderTotals = () => {
    if (getLedgersAPI.loading || !Array.isArray(getLedgersAPI.data)) {
      return null;
    }

    const client_balance = matterCtx.matter.data?.client_account_balance || 0;
    const office_balance = matterCtx.matter.data?.office_account_balance || 0;
    const disbursements = getOfficeDisbursement.data || 0;

    return (
      <TableRow ref={footerRef}>
        <TableCell
          colSpan={9}
          sx={{
            padding: "16px",
          }}
        >
          <Grid container sx={{ margin: 0, padding: 0 }} spacing={1}>
            <Grid item xs={12}>
              <Typography
                fontSize={11}
                lineHeight="24px"
                color={palette.yao.grey[7]}
              >
                OFFICE
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                fontSize={13}
                lineHeight="24px"
                color={palette.yao.grey[7]}
                fontWeight={600}
              >
                Balance
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                fontSize={14}
                lineHeight="24px"
                color={
                  office_balance >= 0
                    ? palette.yao.primary[2]
                    : palette.yao.secondary[2]
                }
                fontWeight={600}
                textAlign="right"
              >
                {fCurrencyWithFormat(
                  office_balance,
                  currInfo.locale,
                  currInfo.currency,
                  currInfo.symbol
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                fontSize={13}
                lineHeight="24px"
                color={palette.yao.grey[7]}
                fontWeight={600}
              >
                Disbursements
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                fontSize={14}
                lineHeight="24px"
                color={
                  disbursements >= 0
                    ? palette.yao.primary[2]
                    : palette.yao.secondary[2]
                }
                fontWeight={600}
                textAlign="right"
              >
                {fCurrencyWithFormat(
                  disbursements,
                  currInfo.locale,
                  currInfo.currency,
                  currInfo.symbol
                )}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell
          colSpan={3}
          sx={{
            padding: "16px",
            borderLeftWidth: 1,
            borderLeftStyle: "solid",
            borderLeftColor: palette.yao.primary[4],
          }}
        >
          <Grid container sx={{ margin: 0, padding: 0 }} spacing={1}>
            <Grid item xs={12}>
              <Typography
                fontSize={11}
                lineHeight="24px"
                color={palette.yao.grey[7]}
              >
                CLIENT
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontSize={13}
                lineHeight="24px"
                color={palette.yao.grey[7]}
                fontWeight={600}
              >
                Balance
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontSize={14}
                lineHeight="24px"
                color={
                  client_balance >= 0
                    ? palette.yao.primary[2]
                    : palette.yao.secondary[2]
                }
                fontWeight={600}
                textAlign="right"
              >
                {fCurrencyWithFormat(
                  client_balance,
                  currInfo.locale,
                  currInfo.currency,
                  currInfo.symbol
                )}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const renderTotalsOffView = () => {
    if (getLedgersAPI.loading || !Array.isArray(getLedgersAPI.data)) {
      return null;
    }
    const client_balance = matterCtx.matter.data?.client_account_balance || 0;
    const office_balance = matterCtx.matter.data?.office_account_balance || 0;
    const disbursements = getOfficeDisbursement.data || 0;

    return (
      <Stack direction="row" spacing={1}>
        <Table>
          <TableRow>
            <TableCell
              colSpan={9}
              sx={{
                padding: "16px",
              }}
            >
              <Grid container sx={{ margin: 0, padding: 0 }} spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    fontSize={11}
                    lineHeight="24px"
                    color={palette.yao.grey[7]}
                  >
                    OFFICE
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography
                    fontSize={13}
                    lineHeight="24px"
                    color={palette.yao.grey[7]}
                    fontWeight={600}
                  >
                    Balance
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography
                    fontSize={14}
                    lineHeight="24px"
                    color={
                      office_balance >= 0
                        ? palette.yao.primary[2]
                        : palette.yao.secondary[2]
                    }
                    fontWeight={600}
                    textAlign="right"
                  >
                    {fCurrencyWithFormat(
                      office_balance,
                      currInfo.locale,
                      currInfo.currency,
                      currInfo.symbol
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography
                    fontSize={13}
                    lineHeight="24px"
                    color={palette.yao.grey[7]}
                    fontWeight={600}
                  >
                    Disbursements
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography
                    fontSize={14}
                    lineHeight="24px"
                    color={
                      disbursements >= 0
                        ? palette.yao.primary[2]
                        : palette.yao.secondary[2]
                    }
                    fontWeight={600}
                    textAlign="right"
                  >
                    {fCurrencyWithFormat(
                      disbursements,
                      currInfo.locale,
                      currInfo.currency,
                      currInfo.symbol
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              colSpan={3}
              sx={{
                padding: "16px",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
                borderLeftColor: palette.yao.primary[4],
              }}
            >
              <Grid container sx={{ margin: 0, padding: 0 }} spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    fontSize={11}
                    lineHeight="24px"
                    color={palette.yao.grey[7]}
                  >
                    CLIENT
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    fontSize={13}
                    lineHeight="24px"
                    color={palette.yao.grey[7]}
                    fontWeight={600}
                  >
                    Balance
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    fontSize={14}
                    lineHeight="24px"
                    color={
                      client_balance >= 0
                        ? palette.yao.primary[2]
                        : palette.yao.secondary[2]
                    }
                    fontWeight={600}
                    textAlign="right"
                  >
                    {fCurrencyWithFormat(
                      client_balance,
                      currInfo.locale,
                      currInfo.currency,
                      currInfo.symbol
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </Table>
      </Stack>
    );
  };

  const handleReconcile = (elementId: string | undefined) => {
    setAnchorEl(null);
    if (!elementId?.length) {
      return;
    }
    const row = getLedgersAPI.data?.find((r) => r._id === elementId);
    if (!row) {
      return;
    }
    // @ts-ignore
    NiceModal.show<Date>(ReverseConfirmModal, {
      row,
    }).then((date) => {
      if (!!date) {
        (async () => {
          try {
            setLoading(true);
            const response = await reverseLedger(row._id, date);
            if (!response) {
              throw new Error("invalid return");
            }
            setLoading(false);
            getLedgersAPI.invoke(searchLedgersPayload);
            enqueueSnackbar("Allow a moment for the ledger to be updated", {
              variant: "info",
            });
          } catch (error) {
            console.log(error);
            enqueueSnackbar(formatError(error), { variant: "error" });
            setLoading(false);
          }
        })();
      }
    });
  };

  return (
    <Page title="Overview">
      <Container maxWidth={false} sx={{ p: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box flexGrow={1} flexShrink={1} />
          <MatterFinancialLimitBanner label="Budget" type="inline" />
          <FormControlLabel
            control={
              <Checkbox
                checked={accountTypesFilter.CLIENT}
                onChange={(e) =>
                  handleAccountTypesFilter(
                    BankAccountTypes.CLIENT,
                    e.target.checked
                  )
                }
              />
            }
            label="Client"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={accountTypesFilter.OFFICE}
                onChange={(e) =>
                  handleAccountTypesFilter(
                    BankAccountTypes.OFFICE,
                    e.target.checked
                  )
                }
              />
            }
            label="Office"
          />
          <Tooltip
            title={
              postingEnabled
                ? "New direct posting"
                : "Use the quick actions or payments tab to create a payment notification"
            }
          >
            <span>
              <Button
                startIcon={
                  <CustomIcon
                    name="add"
                    sx={{ padding: 0.2 }}
                    color={palette.yao.grey[6]}
                  />
                }
                variant="outlined"
                disabled={!postingEnabled}
                onClick={() => {
                  if (!postingEnabled) {
                    return;
                  }
                  setSelectedMatterId(matterCtx.matterId);
                  setCreatingPaymentNotification({
                    ...creatingPaymentNotification,
                    creating: true,
                  });
                }}
                sx={{ marginLeft: "24px!important" }}
              >
                New Posting
              </Button>
            </span>
          </Tooltip>
          <Button
            variant="outlined"
            onClick={() => {
              getLedgersAPI.invoke(searchLedgersPayload);
              getOfficeDisbursement.invoke({ matter: matterCtx.matterId });
            }}
          >
            <Iconify icon="material-symbols:refresh" fontSize={20} />
          </Button>
        </Stack>
        <TableContainer
          sx={{
            minWidth: 960,
            position: "relative",
            padding: "1px",
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">DATE</TableCell>
                <TableCell align="left">TYPE</TableCell>
                <TableCell align="left">PAYEE/PAYER</TableCell>
                <TableCell align="left">NARRATIVE</TableCell>
                <TableCell align="left">
                  {lawFirmContext.getTaxName()}
                </TableCell>
                <TableCell align="left">OFFICE</TableCell>
                <TableCell align="left">OFFICE TOTAL</TableCell>
                <TableCell align="left">CLIENT</TableCell>
                <TableCell align="left">CLIENT TOTAL</TableCell>
                <TableCell align="center">NOTES</TableCell>
                <TableCell align="left">STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderLoading()}
              {renderEmpty()}
              {renderRows()}
              {renderTotals()}
            </TableBody>
          </Table>
        </TableContainer>
        {!inView && (
          <Paper elevation={8} className="yao-afix-footer">
            {renderTotalsOffView()}
          </Paper>
        )}
      </Container>
      <Popover
        id={Boolean(anchorEl) ? `popover-${anchorEl?.dataset?.id}` : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            "& svg": { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        <MenuItem onClick={() => handleReconcile(anchorEl?.dataset?.id)}>
          <Iconify icon="akar-icons:arrow-clockwise" /> Reverse
        </MenuItem>
      </Popover>
      <Dialog
        maxWidth="sm"
        open={creatingPaymentNotification.creating}
        onClose={handleCloseForm}
      >
        <DialogTitle>New Posting</DialogTitle>
        <PaymentNotificationMenu
          matterId={selectedMatterId}
          hideDraft={true}
          directPosting={true}
          onSuccess={() => {
            getLedgersAPI.invoke(searchLedgersPayload);
            setTimeout(() => {
              getOfficeDisbursement.invoke({ matter: matterCtx.matterId });
            }, 5000);
          }}
        />
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
}
