import {Box, Button, Divider, Grid, IconButton, Stack, Typography,} from "@mui/material";
import CustomIcon from "src_common/components/custom-icon";
import {Correspondence, CorrespondenceCategory,} from "src_lawfirm/api/correspondence";
import palette from "src_common/theme/palette";
import {AddCorrespondenceCallNoteModal, AddCorrespondenceNoteModal} from ".";
import {useContext, useState} from "react";
import {Matter} from "src_lawfirm/api/matters";
import {useParams} from "react-router";
import NiceModal from "@ebay/nice-modal-react";
import YaoCorrespondenceGptDialog from "../../yao-gpt/yao-correspondence-gpt";
import { getFormattedDateSafe } from "src_common/utils/formatTime";
import {MatterDetailsContext} from "../context/matter-details-context";

interface IProps {
    correspondence: Correspondence | null | undefined;
    setOpenDrawer: (val: Correspondence | null) => void;
}

export const CorrespondenceDetail = ({
                                         correspondence,
                                         setOpenDrawer,
                                     }: IProps) => {
    const {matterId} = useParams();
    const [editCorrespondenceModal, setEditCorrespondenceModal] = useState(false);
    const [editCorrespondenceNoteModal, setEditCorrespondenceNoteModal] =
        useState(false);
    const matterCtx = useContext(MatterDetailsContext);

    return (
        <>

            <Box width="100%" height="100%">
                <Grid
                    display="flex"
                    justifyContent="space-between"
                    paddingBottom="29px"
                    pr={2}
                >
                    <Box display="flex" alignItems="center" gap={2}>
                        <IconButton sx={{padding: "0"}}>
                            {correspondence?.category === CorrespondenceCategory.CALL ? (
                                <CustomIcon color={palette.yao.secondary[2]} name="phone"/>
                            ) : (
                                <CustomIcon
                                    className="call_note-icon"
                                    color={palette.yao.secondary[2]}
                                    name="note"
                                />
                            )}
                        </IconButton>
                        <Typography
                            color={palette.yao.primary[2]}
                            fontSize="18px"
                            fontWeight="600"
                        >
                            {correspondence?.category === CorrespondenceCategory.CALL
                                ? "CALL NOTE"
                                : "NOTE"}
                        </Typography>
                    </Box>

                    <Box>
                        <IconButton
                            onClick={() => {
                                correspondence?.category === CorrespondenceCategory.CALL
                                    ? setEditCorrespondenceModal(true)
                                    : setEditCorrespondenceNoteModal(true);
                            }}
                        >
                            <CustomIcon name="pencil"/>
                        </IconButton>
                    </Box>
                </Grid>
                <Stack direction="column" spacing={2} alignItems="start" mb="30px">
                    <Button variant="outlined" fullWidth onClick={() => {
                        NiceModal.show(YaoCorrespondenceGptDialog, {
                            correspondence
                        })
                    }}>Summarize this note to my client</Button>
                </Stack>

                <Grid container>
                    <Grid item xs={12}>
                        <Typography color={palette.yao.grey[7]} fontSize="11px">
                            {correspondence?.correspondence_time ? "TIME & DATE" : "DATE"}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color={palette.yao.primary[2]} fontSize="13px">
                            {getFormattedDateSafe(correspondence?.correspondence_time, "hh:mm a")}
                            &nbsp;
                            {getFormattedDateSafe(correspondence?.correspondence_date, "d.MM.yy")}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container pt="10px">
                    <Grid item xs={12}>
                        <Typography color={palette.yao.grey[7]} fontSize="11px">
                            MATTER
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color={palette.yao.primary[2]} fontSize="13px">
                            {correspondence?.matter.case_name}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container pt="10px">
                    <Grid item xs={12}>
                        <Typography color={palette.yao.grey[7]} fontSize="11px">
                            CASE CONTACT
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography fontSize="13px" color={palette.yao.primary[2]}>
                            {correspondence?.case_contact
                                ? [
                                correspondence.case_contact.first_name,
                                " ",
                                correspondence.case_contact.last_name,
                            ] || correspondence.case_contact.company_name
                                : correspondence?.other_contact}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container pt="10px">
                    <Grid item xs={3}>
                        <Typography color={palette.yao.grey[7]} fontSize="11px">
                            {correspondence?.category === CorrespondenceCategory.CALL
                                ? "CALL NOTE"
                                : "NOTE"}
                        </Typography>
                    </Grid>

                    <Grid item xs={9}>
                        <Divider sx={{marginTop: "8px"}}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color={palette.yao.primary[2]} fontSize="13px">
                            {correspondence?.note}
                            <Divider sx={{marginTop: "12px"}}/>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>



            <AddCorrespondenceCallNoteModal
                visible={editCorrespondenceModal}
                handleClose={() => setEditCorrespondenceModal(false)}
                correspondence={correspondence}
                matter={matterCtx.matter.data as Matter}
                setOpenDrawer={setOpenDrawer}
            />
            <AddCorrespondenceNoteModal
                visible={editCorrespondenceNoteModal}
                handleClose={() => setEditCorrespondenceNoteModal(false)}
                correspondence={correspondence}
                matter={matterCtx.matter.data as Matter}
                setOpenDrawer={setOpenDrawer}
            />
        </>
    );
};
