import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormProvider, RHFCheckbox } from "src_common/components/hook-form";
import { YAOFieldAutocompleteOptionObject } from "src_common/components/yao-form-fields";
import { Contact } from "src_lawfirm/api/contacts";
import {
  ContactCategory,
  createMatter,
  Matter,
  MatterParams,
  updateMatter,
} from "src_lawfirm/api/matters";
import * as Yup from "yup";
import { CaseContactSelector, ClientSelector } from "./fields";
import { GeneralFields } from "./GeneralFields";
import StepForm from "./StepForm";
import { MatterDetailsContext } from "../context/matter-details-context";
import { Reconciliation } from "../../../api/bank-reconciliation";

type MatterFormData = {
  clients: Contact[];
  title: string;
  description: string;
  rate?: number;
  fee_earner: any;
  law_firm: any;
  client_account_balance: number;
  office_account_balance: number;
  status: string;
  case_name: string;
  responsible_lawyer: any;
  responsible_supervisor: any;
  paralegal: any;
  secretary: any;
  department: any;
  case_type: any;
  workflow: any;
  billing_preferences: any;
  financial_limit: 0;
  instructed_date: string;
  completed_date: string;
  archived_date: string;
  permissions: string[];
  source: YAOFieldAutocompleteOptionObject;
  case_contacts: Array<{
    contact: Contact;
    category: ContactCategory;
    reference: string;
  }>;
  client_letter_subject: string;
  other_party_letter_subject: string;
  private: boolean;
};

const FormSchema = Yup.object().shape({
  clients: Yup.array().of(
    Yup.object().nullable().required("Client name is required")
  ),

  case_contacts: Yup.array(),

  case_name: Yup.string().max(255).required("Matter name is required"),
  department: Yup.object().nullable().required("Department is required"),
  case_type: Yup.object().nullable().required("Matter type is required"),
  // workflow: Yup.string().nullable().required("Workflow is required"),

  // permissions: Yup.array().of(Yup.string()),

  responsible_lawyer: Yup.object().nullable().required("Lawyer is required"),
  responsible_supervisor: Yup.object()
    .nullable()
    .required("Supervisor is required"),
  paralegal: Yup.object().nullable(),
  secretary: Yup.object().nullable(),

  financial_limit: Yup.number(),

  source: Yup.object().nullable(),
  client_letter_subject: Yup.string(),
  other_party_letter_subject: Yup.string(),
});

function MatterForm({ matter }: { matter?: Matter }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const matterCtx = useContext(MatterDetailsContext);

  const methods = useForm<MatterFormData>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      clients: matter?.clients?.map((c) => c.contact) ?? [undefined],
      case_contacts: matter?.case_contacts ?? [undefined],
      case_name: matter?.case_name ?? "",

      department: matter?.department ?? null,
      case_type: matter?.case_type ?? null,
      responsible_lawyer: matter?.responsible_lawyer ?? null,
      responsible_supervisor: matter?.responsible_supervisor ?? null,
      paralegal: matter?.paralegal ?? null,
      secretary: matter?.secretary ?? null,

      rate: matter?.rate ?? undefined,
      financial_limit: matter?.financial_limit ?? 0,

      source: matter?.source ?? null,
      client_letter_subject: matter?.client_letter_subject ?? "",
      other_party_letter_subject: matter?.other_party_letter_subject ?? "",
      private: matter?.private ?? false,
    },
  });
  const clientsRef = useRef<HTMLDivElement>(null);
  const caseContactsRef = useRef<HTMLDivElement>(null);

  const { handleSubmit } = methods;

  const onSubmit = async (data: MatterFormData) => {
    const api =
      typeof matter !== "undefined"
        ? (data: MatterParams) => updateMatter(matter._id, data)
        : createMatter;

    const apiResponse = await api({
      case_name: data?.case_name,
      rate: data?.rate || null,
      clients: data?.clients?.map((c) => ({ contact: c._id })),
      responsible_lawyer: data?.responsible_lawyer?._id,
      responsible_supervisor: data?.responsible_supervisor?._id,
      paralegal: data?.paralegal?._id,
      secretary: data?.secretary?._id,
      department: data?.department?._id,
      case_type: data?.case_type?._id,
      workflow: data.workflow || "5cabe64dcf0d4447fa60f5e2", // TODO: workflow should be optional for now on Backend
      financial_limit: data?.financial_limit || 0,
      source: data?.source?.value,
      case_contacts: data?.case_contacts
        ?.filter((cc) => cc?.category && cc?.contact?._id)
        ?.map((cc) => ({
          category: cc.category,
          contact: cc.contact._id,
          reference: cc.reference || "",
        })),
      client_letter_subject: data?.client_letter_subject || "",
      other_party_letter_subject: data?.other_party_letter_subject || "",
      permissions: [],
      private: data?.private || false,
    });

    if ("_id" in apiResponse) {
      if (matter) {
        matterCtx.invalidate("details");
      }
      enqueueSnackbar("Matter created successfully");
      enqueueSnackbar("Checking for matter possible conflicts...", {
        variant: "info",
        autoHideDuration: 15000,
      });
      navigate(`/matters/${apiResponse._id}/overview`);
      setTimeout(() => {
        matterCtx.invalidate("compliances");
        enqueueSnackbar("Conflict check ended!", { variant: "success" });
      }, 15000);
    }
  };

  useEffect(() => {
    const subscription = methods.watch((value, { name, type, ...rest }) => {
      if (name === "rate" && value.rate && isNaN(value.rate)) {
        methods.setValue("rate", undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch("rate")]);

  return (
    <Grid container spacing={2} sx={{ mt: 2.5 }}>
      <Grid item xs={8}>
        <FormProvider methods={methods}>
          <Paper ref={clientsRef} variant="outlined" sx={{ p: 4 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">Clients</Typography>

              <RHFCheckbox
                sx={{ mr: 1 }}
                name=""
                label="Client is a company"
                value={1}
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                mt: 1,
                justifyContent: "space-between",
              }}
            >
              <ClientSelector name="clients" methods={methods} />
            </Stack>
          </Paper>
          <Paper
            ref={caseContactsRef}
            variant="outlined"
            sx={{ p: 4, mt: 2.5 }}
          >
            <Typography variant="h3" sx={{ my: 0.8 }}>
              Case Contacts
            </Typography>

            <Stack
              direction="row"
              sx={{
                mt: 2,
                justifyContent: "space-between",
              }}
            >
              <CaseContactSelector name="case_contacts" methods={methods} />
            </Stack>
          </Paper>
          <Paper variant="outlined" sx={{ p: 4, mt: 2.5 }}>
            <Typography variant="h3">General</Typography>

            <GeneralFields />

            <Stack
              direction="row"
              sx={{
                mt: 5,
                justifyContent: "right",
              }}
              spacing={2}
            >
              <Button color="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ minWidth: 100 }}
                onClick={handleSubmit(onSubmit)}
              >
                Finish
              </Button>
            </Stack>
          </Paper>
        </FormProvider>
      </Grid>
      <Grid item xs={4}>
        <StepForm refs={[clientsRef, caseContactsRef]} />
      </Grid>
      <Grid item xs={4}>
        <Paper variant="outlined" sx={{ p: 4, display: "none" }}>
          Work In Progress
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MatterForm;
