import { useContext, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  DialogActions,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import { useAPI } from "src_common/hooks/useAPI";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src_common/components/hook-form";
import {
  createPaymentNotification,
  PaymentMethod,
  PaymentMethodTypes,
  PaymentNotification,
  PaymentNotificationFormData,
  updatePaymentNotification,
} from "../../api/payment-notifications";
import {
  BankAccount,
  BankAccountTypes,
  getBankAccount,
} from "../../api/bank-account";
import _ from "lodash";
import InternalAccount from "./InternalAccount";
import ExternalAccount from "./ExternalAccount";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { CurrencyMask } from "src_common/components/yao-form";
import YaoAutocomplete from "src_common/components/yao-form/YaoAutocomplete";
import { Contact } from "src_lawfirm/api/contacts";
import { getMatter } from "src_lawfirm/api/matters";
import { Matter } from "../../api/matters";
import { contactNameResolver } from "../contacts/useContacts";
import { createLedger, updateLedger } from "src_lawfirm/api/ledgers";
import { isValid } from "date-fns";
import { Invoice } from "src_lawfirm/api/invoices";
import {
  PaymentNotificationC2O,
  PaymentNotificationC2ODisbursement,
} from "./PaymentNotification/PaymentNotificationC2O";
import { PaymentNotificationM2M } from "./PaymentNotification/PaymentNotificationM2M";
import Decimal from "decimal.js";
import { fCurrencyWithFormat } from "src_common/utils/formatNumber";
import { DateTime } from "luxon";
import { LawFirmContext } from "../law-firm/LawFirmContext";

type Props = {
  matter: string;
  fromType?: BankAccountTypes;
  toType?: BankAccountTypes;
  onCancel: VoidFunction;
  paymentNotification?: PaymentNotificationFormData;
  hideDraft?: boolean;
  directPosting?: boolean;
  payment?: Partial<PaymentNotification>;
  isAnticipated?: boolean;
};

export default function PaymentNotificationForm({
  matter,
  fromType,
  toType,
  onCancel,
  hideDraft = false,
  directPosting = false,
  payment = undefined,
  isAnticipated,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const getBankAccountsAPI = useAPI(() => getBankAccount(null));
  const getMatterAPI = useAPI<Matter>(getMatter);
  const lawFirmContext = useContext(LawFirmContext);

  const [transactionDate, setTransactionDate] = useState(new Date());
  const [method, setMethod] = useState<PaymentMethodTypes>();
  const [draftState, setDraftState] = useState(false);
  const isMobile = useMediaQuery("(max-width:500px)");
  const [contacts, setContacts] = useState<string[]>([]);
  const [acctFrom, setAcctFrom] = useState("");
  const [acctTo, setAcctTo] = useState("");
  const [creationMethod, setCreationMethod] = useState<"create" | "edit">(
    "create"
  );
  const [tempMethod, setTempMethod] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [tempContact, setTempContat] = useState<string | undefined>(undefined);
  const [anticipated, setAnticipated] = useState<boolean>(false);
  const [c2o, setC2o] = useState<boolean>(false);
  const [m2m, setM2m] = useState<boolean>(false);
  const [ofr, setOfr] = useState<boolean>(false);
  const [hasVat, setHasVat] = useState<boolean>(false);
  const [hideLabelTo, setHideLabelTo] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [disbursements, setDisbursements] = useState<
    PaymentNotificationC2ODisbursement[]
  >([]);
  const [transferMatter, setTransferMatter] = useState<Matter | undefined>(
    undefined
  );
  const currInfo = useMemo(
    () => lawFirmContext.getCurrency(),
    [lawFirmContext]
  );

  const methods = useForm<PaymentNotificationFormData>({
    defaultValues: {
      vat_percentage: 0,
    },
  });

  useEffect(() => {
    getBankAccountsAPI.invoke(undefined, (data) => {
      setTimeout(() => {
        checkEdit(data as BankAccount[]);
      }, 200);
    });
    getMatterAPI.invoke(matter, (data) => {
      const output: string[] = [];
      const { clients = [], case_contacts = [] } = data as Matter;
      if (Array.isArray(clients) && clients.length > 0) {
        clients.forEach((c) => {
          output.push(contactNameResolver(c.contact as Contact));
        });
      }
      if (Array.isArray(case_contacts) && case_contacts.length > 0) {
        case_contacts.forEach((c) => {
          output.push(contactNameResolver(c.contact as Contact));
        });
      }
      setContacts(_.uniq(output.filter((c) => c.length > 0)));
    });
    if (
      (fromType === BankAccountTypes.CLIENT &&
        toType === BankAccountTypes.OFFICE) ||
      (fromType === BankAccountTypes.EXTERNAL &&
        toType === BankAccountTypes.OFFICE)
    ) {
      setC2o(true);
    }

    if (
      fromType === BankAccountTypes.CLIENT &&
      toType === BankAccountTypes.MATTER
    ) {
      setM2m(true);
    }

    if (
      fromType === BankAccountTypes.EXTERNAL &&
      toType === BankAccountTypes.OFFICE
    ) {
      setOfr(true);
    }

    const shouldDisplayVat =
      fromType === BankAccountTypes.OFFICE &&
      toType === BankAccountTypes.EXTERNAL;
    setHasVat(shouldDisplayVat);
    methods.setValue(
      "vat_percentage",
      shouldDisplayVat ? lawFirmContext.getTaxPercentage() : 0
    );

    const shouldHideLabelTo =
      (fromType === BankAccountTypes.OFFICE &&
        toType === BankAccountTypes.CLIENT) ||
      (fromType === BankAccountTypes.CLIENT &&
        toType === BankAccountTypes.OFFICE);
    setHideLabelTo(shouldHideLabelTo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkEdit = (accountsList: BankAccount[]) => {
    if (!payment) {
      return;
    }
    setCreationMethod("edit");

    const getAccountModel = (account: any) => {
      const acc = accountsList.find((i) => i._id === account);
      if (!acc) {
        return null;
      }

      const value = JSON.stringify({
        account_id: acc._id,
        account_number: acc.account_number,
        account_name: acc.name,
      });

      return {
        label: _.capitalize(
          `${acc.account_number} - ${acc.name} (${acc.type})`
        ),
        value,
        changeVal: value,
      };
    };

    if (
      fromType === BankAccountTypes.CLIENT ||
      fromType === BankAccountTypes.OFFICE
    ) {
      const account = getAccountModel(payment.from!.account_id);
      methods.setValue("from.account", account as any);
      methods.setValue("from.reference", payment.from!.reference);
    } else {
      methods.setValue("from.name", payment.from!.name);
      setTempContat(payment.from!.name);
      methods.setValue("from.account_number", payment.from!.account_number);
      methods.setValue("from.sorting_code", payment.from!.sorting_code);
      methods.setValue("from.reference", payment.from!.reference);
    }

    if (toType === BankAccountTypes.EXTERNAL) {
      methods.setValue("to.name", payment.to!.name);
      setTempContat(payment.to!.name);
      methods.setValue("to.account_number", payment.to!.account_number);
      methods.setValue("to.sorting_code", payment.to!.sorting_code);
      methods.setValue("to.reference", payment.to!.reference);
    } else {
      const account = getAccountModel(payment.to!.account_id);
      methods.setValue("to.account", account as any);
      methods.setValue("to.reference", payment.to!.reference);
    }

    methods.setValue("value", Number(payment.value) || 0);
    methods.setValue("vat_percentage", Number(payment.vat_percentage) || 0);
    if (isValid(new Date(payment.date!))) {
      setTransactionDate(new Date(payment.date!));
    }
    if (payment.method) {
      methods.setValue("method", payment.method);
      setMethod(payment.method);
      setTempMethod({
        label: PaymentMethod[payment.method].description,
        value: PaymentMethod[payment.method].name,
      });
    }
    methods.setValue("notes", payment.notes || "");
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (form: PaymentNotificationFormData) => {
    try {
      form.date = DateTime.fromISO(transactionDate.toISOString(), {
        setZone: false,
      }).toFormat("yyyy-MM-dd");
      form.matter = matter;
      form.draft = draftState;
      form.method = method;
      form.anticipated = anticipated;
      form.vat_percentage = hasVat ? Number(form.vat_percentage) || 0 : 0;
      form.transfer_matter = m2m ? transferMatter?._id : undefined;
      if (
        fromType === BankAccountTypes.CLIENT ||
        fromType === BankAccountTypes.OFFICE
      ) {
        form.from = {
          type: fromType,
          account_id: JSON.parse(acctFrom || "{}")["account_id"],
          reference: m2m
            ? `to Matter ${transferMatter?.number || ""}`
            : form.from.reference,
        };
      } else if (fromType === BankAccountTypes.EXTERNAL) {
        form.from = {
          type: fromType,
          name: form.from.name,
          account_number: form.from.account_number,
          sorting_code: form.from.sorting_code,
          reference: form.from.reference,
        };
      }
      if (
        toType === BankAccountTypes.CLIENT ||
        toType === BankAccountTypes.OFFICE
      ) {
        form.to = {
          name: JSON.parse(acctTo || "{}")["account_name"],
          type: toType,
          account_id: JSON.parse(acctTo || "{}")["account_id"],
          reference: hideLabelTo ? form.from.reference : form.to.reference,
        };
      } else if (toType === BankAccountTypes.EXTERNAL) {
        form.to = {
          type: toType,
          name: form.to.name,
          account_number: form.to.account_number,
          sorting_code: form.to.sorting_code,
          reference: form.to.reference,
        };
      } else if (toType === BankAccountTypes.MATTER) {
        form.to = {
          type: BankAccountTypes.CLIENT,
          name: transferMatter?.case_name,
          account_id: JSON.parse(acctFrom || "{}")["account_id"],
          reference: `from Matter ${
            getMatterAPI?.data?.number?.toString() || ""
          }`,
        };
      }

      if (c2o) {
        if (!invoice) {
          throw Error("You need to select an invoice");
          return;
        }
        form.invoice = invoice._id;
        const selectedDisbursements = disbursements.filter((d) => d.selected);
        if (!selectedDisbursements.length) {
          throw Error("At least one disbursement need to be selected");
          return;
        }
        form.disbursements = selectedDisbursements.map((d) => ({
          _id: d.id,
          value: Number(d.value),
        }));
      }

      let enqueue_message = "";
      if (creationMethod === "edit" && !directPosting && isAnticipated) {
        await updateLedger(payment?._id as string, form);
        enqueue_message = "payment notification updated successfully";
      } else if (creationMethod === "create" && directPosting) {
        await createLedger(form);
        enqueue_message = "posting created successfully";
      } else if (creationMethod === "create") {
        await createPaymentNotification(form);
        enqueue_message = "payment notification created successfully";
      } else if (directPosting) {
        throw Error("invalid method");
      } else {
        await updatePaymentNotification(payment?._id as string, form);
        enqueue_message = "payment notification edited successfully";
      }

      if (enqueue_message.length > 0) {
        enqueueSnackbar(enqueue_message);
        enqueueSnackbar("Allow a moment for the ledger to be updated", {
          variant: "info",
        });
      }
      onCancel && onCancel();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const renderAllocationValue = () => {
    if (!ofr) {
      return null;
    }
    const allocated = disbursements
      .filter((d) => d.selected)
      .map((d) => new Decimal(Number(d.value) || 0))
      .reduce((p, c) => p.plus(c), new Decimal(0))
      .toNumber();
    const unallocated = new Decimal(Number(methods.getValues("value") || 0))
      .minus(allocated)
      .toNumber();
    return (
      <Typography>
        {fCurrencyWithFormat(
          allocated,
          currInfo.locale,
          currInfo.currency,
          currInfo.symbol
        )}{" "}
        allocated and{" "}
        {fCurrencyWithFormat(
          unallocated < 0 ? 0 : unallocated,
          currInfo.locale,
          currInfo.currency,
          currInfo.symbol
        )}{" "}
        unallocated
      </Typography>
    );
  };

  return (
    <Container sx={{ pt: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" sx={{ color: "#000" }}>
            From
          </Typography>
          {!hideDraft && (
            <RHFCheckbox
              sx={{
                position: "relative",
                right: "-1rem",
                top: isMobile ? "-0.5rem" : "-3.6rem",
              }}
              name="draft"
              label="Save as Draft"
              checked={draftState}
              onChange={() => setDraftState(!draftState)}
            />
          )}
        </Stack>

        {((fromType === BankAccountTypes.CLIENT ||
          fromType === BankAccountTypes.OFFICE) && (
          <InternalAccount
            accounts={getBankAccountsAPI.data || []}
            type={fromType}
            isTo={false}
            handleAcct={setAcctFrom}
            setValue={methods.setValue}
            isM2M={m2m}
            hideLabelTo={m2m}
          />
        )) || (
          <ExternalAccount
            isTo={false}
            contacts={contacts}
            setValue={methods.setValue}
            observableValue={tempContact}
          />
        )}

        <Stack sx={{ pt: 3 }}>
          <Typography variant="h6" sx={{ color: "#000", mb: 1.5 }}>
            To
          </Typography>
        </Stack>

        {toType === BankAccountTypes.EXTERNAL ? (
          <ExternalAccount
            isTo={true}
            contacts={contacts}
            setValue={methods.setValue}
            observableValue={tempContact}
          />
        ) : null}

        {toType === BankAccountTypes.MATTER ? (
          <PaymentNotificationM2M
            matter={matter}
            methods={methods}
            transferMatter={transferMatter}
            setTransferMatter={setTransferMatter}
          />
        ) : null}

        {[BankAccountTypes.EXTERNAL, BankAccountTypes.MATTER].indexOf(
          toType as BankAccountTypes
        ) === -1 ? (
          <InternalAccount
            accounts={getBankAccountsAPI.data || []}
            type={toType}
            isTo={true}
            handleAcct={setAcctTo}
            setValue={methods.setValue}
            hideLabelTo={hideLabelTo}
          />
        ) : null}

        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={hasVat ? 6 : 12} md={hasVat ? 3 : 6}>
            <YaoFormFieldLabel name="value_label" label="Amount" required />
            <RHFTextField
              id="value"
              name="value"
              variant="outlined"
              placeholder="Enter value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {lawFirmContext.getCurrencySymbol()}
                  </InputAdornment>
                ),
                inputComponent: CurrencyMask,
              }}
              required={!c2o}
              disabled={c2o && !ofr}
            />
          </Grid>

          {hasVat ? (
            <Grid item xs={6} md={3}>
              <YaoFormFieldLabel
                name="vat_percentage"
                label={lawFirmContext.getTaxName()}
              />
              <Controller
                control={methods.control}
                name="vat_percentage"
                render={({ field: { onChange, value, ...fprops } }) => (
                  <Select
                    onChange={(e) => {
                      onChange(Number(e.target.value) || 0);
                    }}
                    value={value}
                    fullWidth
                    {...fprops}
                    sx={{ height: "40px", p: 0 }}
                  >
                    <MenuItem value={0}>0%</MenuItem>
                    <MenuItem value={lawFirmContext.getTaxPercentage()}>
                      {lawFirmContext.getTaxPercentage()}%
                    </MenuItem>
                  </Select>
                )}
              />
            </Grid>
          ) : null}

          <Grid item xs={6} md={3}>
            <YaoFormFieldLabel
              name="transaction_date_label"
              label="Transaction date"
              required
            />
            <DatePicker
              openTo="day"
              views={["day"]}
              value={transactionDate}
              onChange={(newValue: any) => {
                setTransactionDate(newValue);
              }}
              InputProps={{ style: { height: "40px" } }}
              renderInput={(params) => (
                <TextField {...params} placeholder="dd/MM/yyyy" />
              )}
              inputFormat="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <YaoFormFieldLabel name="method_label" label="Method" />
            <YaoAutocomplete
              id="method"
              name="method"
              placeholder="Select an option"
              value={tempMethod}
              options={Object.values(PaymentMethod).map((method: any) => ({
                label: _.capitalize(method.description),
                value: method.name,
              }))}
              onChange={(e: any, v: any) => {
                setMethod(v?.value || undefined);
                setTempMethod(v);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <YaoFormFieldLabel name="notes_label" label="Notes" />
            <RHFTextField
              id="notes"
              name="notes"
              variant="outlined"
              placeholder="Enter your note"
              inputProps={{
                multiple: true,
                rows: 2,
              }}
            />
          </Grid>
          {fromType === BankAccountTypes.OFFICE &&
            toType === BankAccountTypes.EXTERNAL && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAnticipated || anticipated}
                      disabled={isAnticipated}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setAnticipated(event.target.checked)
                      }
                    />
                  }
                  label="Anticipated?"
                />
              </Grid>
            )}
        </Grid>

        {c2o ? (
          <PaymentNotificationC2O
            matter={matter}
            methods={methods}
            invoice={invoice}
            setInvoice={setInvoice}
            disbursements={disbursements}
            setDisbursements={setDisbursements}
          />
        ) : null}

        <DialogActions>
          {methods.watch("value") && renderAllocationValue()}
          <Box flexGrow={1} />
          <Button variant="outlined" color="inherit" onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={
              (c2o && !disbursements.filter((d) => d.selected).length) ||
              (m2m && transferMatter === undefined)
            }
          >
            {"Save"}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Container>
  );
}
