import { useState, useEffect } from "react";
import { Stepper, Step, StepLabel, Paper } from "@mui/material";

import { styled } from "@mui/material/styles";

const MyPaper = styled(Paper)(({ theme }) => ({
  position: "sticky",
  top: 200,
}));

const steps = [
  {
    label: "Clients",
  },
  {
    label: "Case Contacts",
  },
  {
    label: "General",
  },
];

export default function StepForm({
  refs,
}: {
  refs: React.RefObject<HTMLDivElement>[];
}) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      if (entries.length === refs.length) return;

      for (let entry of entries) {
        if (entry.target === refs[0].current) {
          if (entry.isIntersecting) return setActiveStep(0);
          else return setActiveStep(1);
        }

        if (entry.target === refs[1].current) {
          if (entry.isIntersecting) return setActiveStep(1);
          else return setActiveStep(2);
        }
      }
    }, options);

    refs.forEach((ref) => ref?.current && observer.observe(ref.current));

    return () => {
      observer.disconnect();
    };
  }, [refs]);

  return (
    <MyPaper variant="outlined">
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
        sx={{ p: 4 }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </MyPaper>
  );
}
