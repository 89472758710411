import {FC, useEffect, useState} from "react";
import {Task, TaskStatus} from "../../../api/tasks";
import useDebounce from "src_common/hooks/useDebounce";
import {getEmailPopulated} from "../../../api/email-template";
import {Box, Button, Tooltip} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useAPI} from "src_common/hooks/useAPI";
import {getAttorney} from "../../../api/attorneys";
import useAuth from "src_common/hooks/useAuth";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import {useAppContext} from "../../../AppContext";
import {getMatter, getMatters, Matter} from "../../../api/matters";
import NiceModal from "@ebay/nice-modal-react";
import {AddCorrespondenceEmailModal} from "../CorrespondenceForms/AddCorrespondenceEmailModal.component";

export const TaskSendMail: FC<{ task: Task }> = ({task}) => {
    const {debounce, reset: clearDebounce} = useDebounce();
    const {user} = useAuth();
    const app = useAppContext();
    const getAttorneyApi = useAPI(getAttorney);
    const [html, setHtml] = useState<string>('');
    const getMatterAPI = useAPI(getMatter);

    useEffect(() => {
        if (!user) {
            return;
        }
        getAttorneyApi.invoke(user._id);
    }, [user])

    useEffect(() => {
        clearDebounce();
        setHtml('');
        const templateId = (task?.input_id || '').trim()
        if (!templateId.length) {
            return
        }
        debounce(async () => {
            try {
                getMatterAPI.invoke(task?.matter._id);
                const content = await getEmailPopulated(templateId, {
                    matter: task.matter._id,
                });
                setHtml(content?.html || '')
            } catch (e) {
                console.error(e)
            }
        }, 250);
    }, [task])

    if (!task || !task.input_id?.length || task.status === TaskStatus.COMPLETED || getMatterAPI.loading || getMatterAPI.error || !html.length) {
        return null
    }

    return <Box width="100%">
        <AuthenticatedTemplate>
            <Tooltip title="Send an Email" placement="top">
                <Button startIcon={<SendIcon/>} variant="contained" fullWidth onClick={() => {
                    NiceModal.show(
                        AddCorrespondenceEmailModal,
                        {
                            matter: getMatterAPI.data as Matter,
                            defaultSignature: `${html}<br />${getAttorneyApi.data?.email_default_signature || ""}`,
                            task_id: task._id
                        }
                    )
                }}>Send e-mail</Button>
            </Tooltip>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Button startIcon={<SendIcon/>} variant="contained" onClick={app.signIn} fullWidth>Send e-mail</Button>
        </UnauthenticatedTemplate>
    </Box>
}