import React, {createContext, Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {
  deleteWorkflowTemplateTask,
  listWorkflowTemplate,
  updateWorkflowTemplateTask,
  Workflow,
  WorkflowList,
  WorkflowSectionList,
  WorkflowTask,
} from "src_lawfirm/api/workflow";
import {FormTemplate} from "src_common/@interfaces/forms";
import {useAPI, UseAPIHook} from "src_common/hooks/useAPI";
import {getFormTemplates} from "src_lawfirm/api/forms";
import {FormType} from '../../../src_common/@interfaces/forms';
import {TaskPriority} from '../../api/tasks';
import {MatterStatus, Status} from "../../api/matters";
import {EmailTemplate, getEmailTemplate} from "../../api/email-template";

type PropsSectionContext = {
  loadingWorkflow: boolean;
  setLoadingWorkflow: Dispatch<SetStateAction<boolean>>;

  workflow: Workflow | undefined;
  setWorkflow: Dispatch<SetStateAction<Workflow | undefined>>;

  loadingSections: boolean;
  setLoadingSections: Dispatch<SetStateAction<boolean>>;

  sections: WorkflowSectionList[];
  setSections: Dispatch<SetStateAction<WorkflowSectionList[]>>;

  workflowTemplates: UseAPIHook<WorkflowList[]> | undefined;
  formTemplates: UseAPIHook<FormTemplate[]> | undefined;
  precedentTemplates: UseAPIHook<FormTemplate[]> | undefined;
  emailTemplates: UseAPIHook<EmailTemplate[]> | undefined;
  matterStatus: UseAPIHook<{ value: string; name: string; }[]> | undefined

  updateTask: (
    task: WorkflowTask,
    update: { [key: string]: any }
  ) => Promise<void>;
  removeTask: (task: WorkflowTask) => Promise<void>;
  addTask: (section: string, order: number, task: WorkflowTask) => void;
};

const DEFAULT_VALUE = {
  loadingWorkflow: true,
  workflow: undefined,
  loadingSections: true,
  sections: [],
  setLoadingWorkflow: () => {},
  setLoadingSections: () => {},
  setWorkflow: () => {},
  setSections: () => {},
  workflowTemplates: undefined,
  formTemplates: undefined,
  precedentTemplates: undefined,
  emailTemplates: undefined,
  matterStatus: undefined,
  updateTask: () => Promise.resolve(),
  removeTask: () => Promise.resolve(),
  addTask: () => {},
};

const CanvasContext = createContext<PropsSectionContext>(DEFAULT_VALUE);

const CanvasContextProvider: FC = ({ children }) => {
  const workflowTemplates = useAPI(listWorkflowTemplate);
  const formTemplates = useAPI(getFormTemplates);
  const precedentTemplates = useAPI(getFormTemplates);
  const emailTemplates = useAPI(getEmailTemplate);
  const matterStatus = useAPI(() => Promise.resolve([
    Status.QUOTE,
    Status.IN_PROGRESS,
    Status.ON_HOLD,
    Status.COMPLETED,
    Status.EXCHANGED,
    Status.ARCHIVED
  ]))

  const [loadingWorkflow, setLoadingWorkflow] = useState<boolean>(
    DEFAULT_VALUE.loadingWorkflow
  );
  const [workflow, setWorkflow] = useState<Workflow | undefined>(
    DEFAULT_VALUE.workflow
  );
  const [loadingSections, setLoadingSections] = useState<boolean>(
    DEFAULT_VALUE.loadingSections
  );
  const [sections, setSections] = useState<WorkflowSectionList[]>(
    DEFAULT_VALUE.sections
  );

  useEffect(() => {
    workflowTemplates.invoke();
    formTemplates.invoke({ form_type: FormType.FORM, backoffice_forms: true });
    precedentTemplates.invoke({ form_type: FormType.PRECEDENT, backoffice_forms: true });
    matterStatus.invoke();
    emailTemplates.invoke()
  }, []);

  const updateTask = (
    task: WorkflowTask,
    update: { [key: string]: any }
  ): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      if (!Object.keys(update).length) {
        reject(Error("No keys found to update"));
        return;
      }
      try {
        const updated = await updateWorkflowTemplateTask(task._id, {
          title: task.title,
          assignee: task.assignee,
          action: task.action,
          input_id: task.input_id,
          section: task.section,
          trigger_due_date_days: task.trigger_due_date_days,
          priority: task.priority || TaskPriority.STANDARD,
          matter_status_trigger: task.matter_status_trigger || undefined,
          ...update,
        });
        if (updated?.modifiedCount === 1) {
          const sectionIdx = sections.findIndex(
            (s) => `${s._id}` === `${task.section}`
          );
          if (sectionIdx !== -1) {
            const taskIdx = sections[sectionIdx].tasks.findIndex(
              (t) => `${t._id}` === `${task._id}`
            );
            if (taskIdx !== -1) {
              const sectionsCopy = [...sections];
              const item = {
                ...sectionsCopy[sectionIdx].tasks[taskIdx],
                ...update,
              };
              sectionsCopy[sectionIdx].tasks[taskIdx] = item as WorkflowTask;
              setSections(sectionsCopy);
            } else {
              throw new Error("Task not found to update");
            }
          } else {
            throw new Error("Section not found to update");
          }
          resolve();
        } else {
          throw new Error("Update fail");
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  const removeTask = (task: WorkflowTask): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const deleted = await deleteWorkflowTemplateTask(task._id);
        if (deleted?.deletedCount > 0) {
          const sectionIdx = sections.findIndex(
            (s) => `${s._id}` === `${task.section}`
          );
          if (sectionIdx !== -1) {
            const taskIdx = sections[sectionIdx].tasks.findIndex(
              (t) => `${t._id}` === `${task._id}`
            );
            if (taskIdx !== -1) {
              const taskSection = { ...sections[sectionIdx] };
              taskSection.tasks.splice(taskIdx, 1);
              const sectionsCopy = [...sections];
              sectionsCopy[sectionIdx] = { ...taskSection };
              setSections(sectionsCopy);
            } else {
              throw new Error("Task not found to remove");
            }
          } else {
            throw new Error("Section not found to remove task");
          }
          resolve();
        } else {
          throw new Error("Remove fail");
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  const addTask = (section: string, order: number, task: WorkflowTask) => {
    const sectionIdx = sections.findIndex(
      (s) => `${s._id}` === `${section}`
    );
    if(sectionIdx !== -1){
      const taskSection = { ...sections[sectionIdx] };
      let nextTaks = Array.isArray(taskSection.tasks) && taskSection.tasks.length > 0 ? [...taskSection.tasks] : [];
      nextTaks.splice(order + 1, 0, task);
      taskSection.tasks = nextTaks.map((t, i) => ({ ...t, order: i }));
      const sectionsCopy = [...sections];
      sectionsCopy[sectionIdx] = {...taskSection};
      setSections(sectionsCopy);
    }
  }

  return (
    <CanvasContext.Provider
      value={{
        loadingWorkflow,
        setLoadingWorkflow,
        workflow,
        setWorkflow,
        loadingSections,
        setLoadingSections,
        sections,
        setSections,
        workflowTemplates,
        formTemplates,
        precedentTemplates,
        updateTask,
        removeTask,
        addTask,
        matterStatus,
        emailTemplates,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

export { CanvasContextProvider };
export default CanvasContext;
