import {
    CORRESPONDENCE_EVENT_KEY,
    CorrespondenceCategory,
    CorrespondenceForm,
    CorrespondenceMailAttachments,
    replyCorrespondence,
} from "src_lawfirm/api/correspondence";
import {SubmitHandler, useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {Box, Button, Dialog, DialogActions, Grid, IconButton, Stack, Typography,} from "@mui/material";
import {FormProvider} from "src_common/components/hook-form";
import CloseIcon from "@mui/icons-material/Close";
import {YaoFormFieldLabel} from "src_common/components/yao-form/YaoForm";
import {Matter} from "src_lawfirm/api/matters";
import palette from "src_common/theme/palette";
import {
    useUploadFileToS3,
    YAOCKEditor,
    YAOFieldAutocompleteTagInput,
    YAOFieldText,
} from "src_common/components/yao-form-fields";
import {sendMail} from "src_lawfirm/GraphService";
import useAuth from "src_common/hooks/useAuth";
import React, {useEffect, useState} from "react";
import CustomIcon from "src_common/components/custom-icon";
import {useContacts} from "src_lawfirm/pages/contacts/useContacts";
import {getAttachmentIconName, getFileTypeByMime,} from "src_common/utils/fileHelpers";
import {UploadButton} from "./UploadButton";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {useAPI} from "src_common/hooks/useAPI";
import {EmailTemplate, getEmailPopulated, getEmailTemplate} from "../../../api/email-template";
import {YaoSelectorInputProps, YaoSelectorItem} from "src_common/components/YaoSelector/yao-selector.types";
import YaoSelectorDialog from "src_common/components/YaoSelector";
import {extractEmailFromString} from '../CorrespondenceFolderTab/helpers';
import {trigger} from 'src_common/utils/events';

type EmailFormModel = {
    emails: string[];
    cc_emails?: string[];
    bcc_emails?: string[];
    subject: string;
    message: string;
    task_id: string | undefined;
};

interface IFile {
    readonly file: File;
    readonly filename: string;
}

interface IProps {
    matter?: Matter;
    defaultSignature?: string;
    task_id?: string | undefined;
}

export const AddCorrespondenceEmailModal = NiceModal.create<IProps>(
    ({matter, defaultSignature = '', task_id = undefined}) => {
        const modal = useModal();

        const [attachments, setAttachments] = useState<IFile[]>([]);
        const {enqueueSnackbar} = useSnackbar();
        const {user} = useAuth();

        const contactsApi = useContacts();
        const getEmailsAPI = useAPI<EmailTemplate[]>(getEmailTemplate);

        const upload = useUploadFileToS3();

        useEffect(() => {
            getEmailsAPI.invoke()
            matter
                ? contactsApi.invoke(
                    undefined,
                    undefined,
                    false,
                    undefined,
                    undefined,
                    [
                        ...matter.case_contacts.map((c) => c.contact._id!),
                        ...matter.clients.map((c) => c.contact._id!),
                    ]
                )
                : contactsApi.invoke();
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        const defaultValues = {
            subject: matter?.case_name,
            emails: [],
            cc_emails: (task_id || '').length > 0 && matter && (matter.responsible_supervisor?.email || '').length > 0
                ? [matter.responsible_supervisor.email]
                : [],
            bcc_emails: [],
            message: defaultSignature,
            task_id,
        };

        const contactOptions = contactsApi?.data
            .filter((d) => d.email !== "")
            .map(
                (c) =>
                    `${c.company_name || ""} ${c.first_name || ""} ${c.last_name || ""} - ${
                        c.email
                    }`
            );

        const methods = useForm<EmailFormModel>({
            defaultValues,
        });

        const handleCloseModal = (emailSent: boolean) => {
            modal.resolve(emailSent);
            modal.hide();
        };

        const onSubmit: SubmitHandler<EmailFormModel> = async (form) => {
            try {
                const email_html_body = `${form.message.replace(
                    /(<p[^>]*>)(.*?)(<\/p>)/gi,
                    '<p style="margin: 0">$2</p>'
                )}<hr />`;

                const emails = form.emails.map((e) => {
                    return extractEmailFromString(e);
                });

                const cc_emails =
                    (form.cc_emails &&
                        form.cc_emails.map((e) => {
                            return extractEmailFromString(e);
                        })) ||
                    [];

                const bcc_emails =
                    (form.bcc_emails &&
                        form.bcc_emails.map((e) => {
                            return extractEmailFromString(e);
                        })) ||
                    [];

                const payload = {
                    subject: form.subject,
                    email_html_body,
                    to_emails: emails,
                    cc_emails,
                    bcc_emails,
                    attachments,
                };

                await sendMail(payload);

                let uploads: CorrespondenceMailAttachments[] = [];
                await Promise.all(
                    attachments.map((attachment) => {
                        if (attachment.file.name === attachment.filename)
                            return upload(attachment.file).then((data) => {
                                if (!data) throw new Error("Something went wrong");
                                const {key, title} = data;
                                return uploads.push({key, filename: attachment.filename});
                            });
                        return uploads.push(
                            {
                                filename: attachment.filename,
                                key: attachment.file.name
                            } as CorrespondenceMailAttachments
                        );
                    })
                );

                const correspondenceDto: CorrespondenceForm = {
                    title: form.subject,
                    email_html_body,
                    category: CorrespondenceCategory.EMAIL,
                    matter: matter?._id || "",
                    to: emails || [],
                    cc_emails,
                    bcc_emails,
                    from: [user?.email],
                    correspondence_date: new Date(),
                    attachments: uploads,
                    task_id: form.task_id,
                };

                await replyCorrespondence(correspondenceDto);
                trigger(CORRESPONDENCE_EVENT_KEY)

                methods.reset();
                enqueueSnackbar("Email successfully sent!");
                handleCloseModal(true);
            } catch (e) {
                enqueueSnackbar(e.message, {variant: "error"});
            }
        };

        const handleUseTemplate = () => {
            const selectorProps: YaoSelectorInputProps = {
                title: 'Use template',
                items: (getEmailsAPI.data || []).map(i => ({
                    id: i._id,
                    title: i.title,
                    description: i.description,
                    icon: "precedent",
                    icon_color_index: 1,
                    data: {
                        matter: matter!._id
                    }
                })),
                placeholders: {
                    text: 'Find template by title'
                },
                filters: {
                    case_type: false,
                    department: false,
                    text: true,
                },
                onContinue: (items: YaoSelectorItem[]) => {
                    return new Promise(async (resolve, reject) => {
                        const matter = items[0].data?.matter || '';
                        if (!matter.length) {
                            reject('invalid key')
                        } else {
                            try {
                                const content = await getEmailPopulated(
                                    items[0].id,
                                    {
                                        matter,
                                    }
                                );
                                resolve(content?.html || '');
                            } catch (e) {
                                reject(e)
                            }
                        }
                    })
                }
            }

            NiceModal.show<string>(YaoSelectorDialog, selectorProps).then(
                (content) => {
                    methods.setValue("message", `${content}<br />${defaultSignature}`)
                }
            );
        }

        return (
            <Dialog
                maxWidth="sm"
                open={modal.visible}
                className="cs_corresponding-modal"
                TransitionProps={{
                    onExited: () => {
                        modal.remove();
                    },
                }}
            >
                <FormProvider
                    methods={methods}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <IconButton onClick={() => handleCloseModal(false)}>
                        <CloseIcon/>
                    </IconButton>

                    <Typography
                        color={palette.yao.primary[2]}
                        fontSize="21px"
                        fontWeight="600"
                        textAlign="center"
                    >
                        Email Correspondence
                    </Typography>

                    <Stack pb="10px" px="40px">
                        <Grid item>
                            <YaoFormFieldLabel name="emails" label="To"/>
                            <YAOFieldAutocompleteTagInput
                                name="emails"
                                label=""
                                placeholder="Enter emails"
                                filterSelectedOptions={false}
                                options={contactOptions}
                                textFieldType="search"
                            />
                        </Grid>
                    </Stack>

                    <Stack pb="10px" px="40px">
                        <Grid item>
                            <YaoFormFieldLabel name="cc_emails" label="Add cc emails"/>
                            <YAOFieldAutocompleteTagInput
                                name="cc_emails"
                                label=""
                                placeholder="Enter cc emails"
                                filterSelectedOptions={false}
                                options={contactOptions}
                                textFieldType="search"
                            />
                        </Grid>
                    </Stack>
                    <Stack pb="10px" px="40px">
                        <Grid item>
                            <YaoFormFieldLabel name="bcc_emails" label="Add bcc emails"/>
                            <YAOFieldAutocompleteTagInput
                                name="bcc_emails"
                                label=""
                                placeholder="Enter bcc emails"
                                filterSelectedOptions={false}
                                options={contactOptions}
                                textFieldType="search"
                            />
                        </Grid>
                    </Stack>
                    <Stack pb="10px" px="40px">
                        <Grid item>
                            <YaoFormFieldLabel name="subject" label="Subject"/>
                            <YAOFieldText name="subject"/>
                        </Grid>
                    </Stack>
                    <Stack pb="10px" px="40px">
                        <Grid item width="100%">
                            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                <Box flexGrow={1}>
                                    <YaoFormFieldLabel name="message" label="Message"/>
                                </Box>
                                {getEmailsAPI.loading || !(getEmailsAPI.data || []) ? null : (
                                    <Button variant="text" onClick={handleUseTemplate}>
                                        USE TEMPLATE
                                    </Button>
                                )}
                            </Stack>

                            <YAOCKEditor name="message"/>
                        </Grid>
                    </Stack>

                    <Stack pb="10px" px="40px">
                        <Grid item>
                            <UploadButton
                                matterId={matter!._id}
                                attachments={attachments}
                                setAttachments={setAttachments}
                            />
                            {attachments?.map((a) => {
                                const ext = getFileTypeByMime(a.file.type);
                                return (
                                    <IconButton
                                        key={a.file.name + a.file.lastModified}
                                        sx={{paddingTop: 0, paddingBottom: 0, paddingRight: 0}}
                                        onClick={() => {
                                            setAttachments(
                                                attachments.filter((p) => p.file.name !== a.file.name)
                                            );
                                        }}
                                    >
                                        {ext && (
                                            <>
                                                <CustomIcon
                                                    name={getAttachmentIconName(`.${ext}`) || ""}
                                                    tooltip={a.filename}
                                                    sx={{height: "27px", width: "27px"}}
                                                />{" "}
                                                <Typography
                                                    sx={{
                                                        marginBottom: "15px",
                                                        position: "relative",
                                                        right: "5px",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    x
                                                </Typography>
                                            </>
                                        )}
                                    </IconButton>
                                );
                            })}
                        </Grid>
                    </Stack>

                    <DialogActions style={{marginRight: "16px", paddingTop: "0"}}>
                        <Button
                            color="inherit"
                            onClick={() => {
                                handleCloseModal(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={methods.formState.isSubmitting}
                            sx={{width: "95px"}}
                        >
                            Send
                        </LoadingButton>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        );
    }
);
