import { Container, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import Breadcrumbs from "src_common/components/Breadcrumbs";
import Page from "src_common/components/Page";
import { getMatter } from "src_lawfirm/api/matters";
import MatterForm from "../MatterForm";

export default function EditMatterPage() {
  const { matterId } = useParams();

  const getMatterAPI = useQuery(
    ["matters", matterId],
    () => getMatter(matterId),
    {
      enabled: !!matterId,
    }
  );

  if (getMatterAPI.isLoading || !getMatterAPI.data) return null;

  const matter = getMatterAPI.data;

  return (
    <Page title="Matters">
      <Container maxWidth={false}>
        <Stack>
          <Breadcrumbs
            links={[
              { name: "Dashboard" },
              { name: "Matter" },
              { name: matter.case_name },
            ]}
          />
        </Stack>
        <Typography variant="h1">{matter.case_name}</Typography>
        <MatterForm matter={matter} />
      </Container>
    </Page>
  );
}
