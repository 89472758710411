import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormProvider, RHFTextField } from "src_common/components/hook-form";
import Iconify from "src_common/components/Iconify";
import Page from "src_common/components/Page";
import { useQuery } from "src_common/hooks/useQuery";
import LogoOnlyLayout from "src_common/layouts/LogoOnlyLayout";
import { resetAttorneyPassword } from "src_lawfirm/api/attorneys";
import * as Yup from "yup";

type FormValuesProps = {
  password: string;
  confirmPassword: string;
  afterSubmit?: string;
};

export default function SetPassword() {
  const search = useQuery();
  const token = search.get("token") || "";
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const SetPassowrdSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SetPassowrdSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    if (!token) {
      navigate("/auth/login");
    }
  }, []);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await resetAttorneyPassword({
        password: data.password,
        token,
      });
      enqueueSnackbar("Password set successfully");
      navigate("/auth/login");
    } catch (error) {
      console.error(error);

      setError("afterSubmit", { ...error, message: error.message });
    }
  };

  return (
    <Page title="Reset Password">
      <LogoOnlyLayout />

      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            You are invited to YAO
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Please, set your password below.
          </Typography>
        </Box>
      </Stack>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} my={3}>
          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={
                        showConfirmPassword
                          ? "eva:eye-fill"
                          : "eva:eye-off-fill"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Update
        </LoadingButton>
      </FormProvider>
    </Page>
  );
}
