import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Buffer } from "buffer";
import {
  Box,
  Stack,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Page from "src_common/components/Page";
import ContactForm from "./ContactForm";
import {
  ContactFormData,
  editContactWithSmsCode,
} from "src_lawfirm/api/contacts";
import { useContacts } from "./useContacts";
import ReactCodeInput from "react-code-input";
import "./contact.css";
import { formatError } from "src_common/utils/misc";

const ContactEmbeded: FC = () => {
  let { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<string>("");
  const [userCode, setUserCode] = useState<string>("");
  const contactsApi = useContacts();
  const [form, setForm] = useState<ContactFormData>();
  const [updateCode, setUpdateCode] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (typeof token !== "string" || !token.length) {
      setError("Invalid token");
      return;
    }

    const data = Buffer.from(decodeURIComponent(token), "base64").toString(
      "utf8"
    );
    setUserCode(data);
  }, [token]);

  const handleFormSubmit = async (data: ContactFormData) => {
    try {
      setForm(data);
      const sent = await contactsApi.requestSmsCode(userCode);
      if (sent) {
        setModalVisible(true);
        return;
      } else {
        enqueueSnackbar("Error sending SMS code", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const handleSave = async () => {
    try {
      setLoader(true);
      const response = await editContactWithSmsCode(
        userCode,
        form as ContactFormData,
        updateCode
      );
      setModalVisible(false);
      setUpdated(true);
    } catch (error) {
      enqueueSnackbar(formatError(error), { variant: "error" });
    } finally {
      setLoader(false);
      setModalVisible(false);
    }
  };

  if (error.length > 0) {
    return (
      <Page title="Forms > Contact information">
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Contact information
            </Typography>
            <Typography sx={{ color: "#f44336" }}>{error}</Typography>
          </Box>
        </Stack>
      </Page>
    );
  }

  if (updated) {
    return (
      <Page title="Forms > Contact information">
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Contact information
            </Typography>
            <Typography sx={{ color: "#66bb6a" }}>
              Thank you for updating your information
            </Typography>
          </Box>
        </Stack>
      </Page>
    );
  }

  return (
    <Page title="Forms > Contact information">
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Contact information
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            please fill in the fields below with your information
          </Typography>
        </Box>
      </Stack>
      <ContactForm
        type="embeded"
        hideCancel={true}
        confirmText="Continue"
        onEmbededFilled={handleFormSubmit}
      />
      <Dialog
        maxWidth="xs"
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <DialogContent className="contact-dialog-content">
          <Typography variant="subtitle2">
            please enter the code received by sms below to confirm
          </Typography>
          <Stack justifyContent="center" alignItems="center" mt={1}>
            <ReactCodeInput
              name="update_code"
              value={updateCode}
              onChange={(value: string) => setUpdateCode(value)}
              type="text"
              fields={4}
              inputMode={"latin"}
            />
          </Stack>
        </DialogContent>
        <DialogActions className="contact-dialog-action">
          <Button color="secondary" onClick={() => setModalVisible(false)}>
            cancel
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            type="submit"
            onClick={handleSave}
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};

export default ContactEmbeded;
