import axios from "src_common/utils/axios";
import {AxiosResponse} from "axios";
import {MongooseUpdateResponse} from "./workflow";

export enum PostSearchType {
    'ALL' = 'ALL',
    'MY_FILES' = 'MY_FILES',
    'LOST_N_FOUND' = 'LOST_N_FOUND',
}

export const PostSearchTypeLabel: Record<PostSearchType, string> = {
    [PostSearchType.ALL]: "All",
    [PostSearchType.MY_FILES]: "My files",
    [PostSearchType.LOST_N_FOUND]: "Lost & Found"
}

export type PostItemMatter = {
    _id: string;
    case_name: string;
    number: number;
    responsible_lawyer: {
        _id: string;
        name: string;
        surname: string;
    };
}

export interface PostItem {
    _id: string;
    name: string;
    key: string;
    author: {
        _id: string;
        name: string;
        surname: string;
    };
    law_firm: any;
    matter?: PostItemMatter;
    created_at: string;
    updated_at: string;
    highlight?: boolean;
}

export interface CreatePostItemDto {
    name: string;
    key: string;
}

export interface UpdatePostItemDto {
    name: string;
    matter: string | null;
}

export interface SearchPostItemDto {
    type: PostSearchType;
    page: number;
    size: number;
}

export async function createPostItem(payload: CreatePostItemDto): Promise<PostItem> {
    const res: AxiosResponse<PostItem> = await axios.post('/post-items', payload);
    return res.data;
}

export async function updatePostItem(id: string, payload: UpdatePostItemDto): Promise<MongooseUpdateResponse> {
    const res: AxiosResponse<MongooseUpdateResponse> = await axios.patch(`/post-items/${id}`, payload);
    return res.data;
}

export async function searchPostItem(payload: SearchPostItemDto): Promise<PostItem[]> {
    const res: AxiosResponse<PostItem[]> = await axios.post('/post-items/search', payload);
    return res.data;
}

export async function getLostAndFoundPending(): Promise<number> {
    const res = await axios.get<number>('/post-items/lost-found-count')
    return res.data;
}

export async function claimPostItem(id: string, matter: string): Promise<boolean> {
    const res: AxiosResponse<{ success: boolean; }> = await axios.post(`/post-items/${id}/matter/${matter}/claim`);
    return res.data?.success || false;
}

export async function getPostItemPendingCount(): Promise<number> {
    const res: AxiosResponse<{ count: number }> = await axios.get('/post-items/pending-count');
    return res.data?.count || 0;
}