/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import CustomIcon from "src_common/components/custom-icon";
import { useState } from "react";
import { DialogAnimate } from "src_common/components/animate";
import Page from "src_common/components/Page";
import palette from "src_common/theme/palette";
import { BankAccountTypes } from "../../api/bank-account";
import PaymentNotificationForm from "./PaymentNotificationForm";
import { PaymentNotification } from '../../api/payment-notifications';

export interface typeCreating {
  creating: boolean;
  title?: string;
  fromType?: BankAccountTypes;
  toType?: BankAccountTypes;
  payment?: Partial<PaymentNotification>;
  isAnticipated?: boolean;
}

export interface typeEditing {
  editing: boolean;
  title?: string;
  fromType?: BankAccountTypes;
  toType?: BankAccountTypes;
  payment?: PaymentNotification;
}

type Props = {
  matterId: string;
  hideDraft?: boolean;
  directPosting?: boolean;
  onSuccess?: VoidFunction;
};

function getIcon(name: string) {
  return <CustomIcon name={name} sx={{ p: 0.1 }} />;
}

export default function PaymentNotificationMenu({ matterId, hideDraft = false, directPosting = false, onSuccess }: Props) {
  const [creatingPaymentNotification, setCreatingPaymentNotification] =
    useState<typeCreating>({
      creating: false,
      title: undefined,
      fromType: undefined,
      toType: undefined,
    });

  const handleCloseForm = () => {
    setCreatingPaymentNotification({
      ...creatingPaymentNotification,
      creating: false,
    });
  };

  return !creatingPaymentNotification.creating ? (
    <Container sx={{ p: 3 }}>
      <Stack spacing={2} direction="row" sx={{ mb: 2 }} justifyContent="space-between">
        <Typography
          sx={{
            color: palette.yao.grey[6],
            width: "16rem",
            textAlign: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {getIcon("client")}CLIENT
          </Box>
        </Typography>
        <Typography
          sx={{
            color: palette.yao.grey[6],
            width: "16rem",
            textAlign: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {getIcon("office")}OFFICE
          </Box>
        </Typography>
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          sx={{ width: "16rem" }}
          onClick={() =>
            setCreatingPaymentNotification({
              creating: true,
              title: "Client Payment",
              fromType: BankAccountTypes.CLIENT,
              toType: BankAccountTypes.EXTERNAL,
            })
          }
        >
          Payment
        </Button>
        <Button
          variant="outlined"
          sx={{ width: "16rem" }}
          onClick={() =>
            setCreatingPaymentNotification({
              creating: true,
              title: "Office Payment",
              fromType: BankAccountTypes.OFFICE,
              toType: BankAccountTypes.EXTERNAL,
            })
          }
        >
          Payment
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          sx={{ width: "16rem" }}
          onClick={() =>
            setCreatingPaymentNotification({
              creating: true,
              title: "Client Receipt",
              fromType: BankAccountTypes.EXTERNAL,
              toType: BankAccountTypes.CLIENT,
            })
          }
        >
          Receipt
        </Button>
        <Button
          variant="outlined"
          sx={{ width: "16rem" }}
          onClick={() =>
            setCreatingPaymentNotification({
              creating: true,
              title: "Office Receipt",
              fromType: BankAccountTypes.EXTERNAL,
              toType: BankAccountTypes.OFFICE,
            })
          }
        >
          Receipt
        </Button>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 2, pt: 2 }}
      >
        <Typography
          sx={{
            color: palette.yao.grey[6],
            width: "16rem",
            textAlign: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {getIcon("transfer")}TRANSFER
          </Box>
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Button variant="outlined" sx={{ width: "16rem" }} 
        onClick={() =>
            setCreatingPaymentNotification({
              creating: true,
              title: "Client to Client",
              fromType: BankAccountTypes.CLIENT,
              toType: BankAccountTypes.MATTER,
            })
          }>
          Client to Client
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          sx={{ width: "16rem" }}
          onClick={() =>
            setCreatingPaymentNotification({
              creating: true,
              title: "Office to Client",
              fromType: BankAccountTypes.OFFICE,
              toType: BankAccountTypes.CLIENT,
            })
          }
        >
          Office to client
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          sx={{ width: "16rem" }}
          onClick={() =>
            setCreatingPaymentNotification({
              creating: true,
              title: "Client to Office",
              fromType: BankAccountTypes.CLIENT,
              toType: BankAccountTypes.OFFICE,
            })
          }
        >
          Client to office
        </Button>
      </Stack>
    </Container>
  ) : (
    <Page title="Payment Notification">
      <DialogAnimate
        maxWidth="md"
        open={creatingPaymentNotification.creating}
        onClose={handleCloseForm}
      >
        <DialogTitle>{creatingPaymentNotification.title}</DialogTitle>
        <PaymentNotificationForm
          matter={matterId}
          fromType={creatingPaymentNotification.fromType}
          toType={creatingPaymentNotification.toType}
          onCancel={() => {
            handleCloseForm();
            onSuccess && onSuccess();
          }}
          hideDraft={hideDraft}
          directPosting={directPosting}
        />
      </DialogAnimate>
    </Page>
  );
}
