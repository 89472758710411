import {FC, useContext, useEffect, useMemo, useState} from "react";
import {Box, CircularProgress, Paper, Typography} from "@mui/material";
import palette from "src_common/theme/palette";
import {MatterDetailsContext} from "./context/matter-details-context";
import {LawFirmContext} from "../law-firm/LawFirmContext";

type InputProps = {
    type?: "card" | "inline"; label?: string; showEmpty?: boolean; emptyValue?: string;
};

const styles = {
    cardContainer: {
        sx: {
            p: 3.5, textAlign: "center",
        }, elevation: 10,
    }, cardLabel: {
        fontSize: 11, fontWeight: 400, color: palette.yao.grey[7],
    }, cardValues: {
        fontSize: 21, fontWeight: 600,
    }, cardText: {
        red: {
            color: "#E4607B",
        }, orange: {
            color: "#E4CB72",
        }, green: {
            color: "#2BA9AF",
        },
    }, inlineContainer: {
        borderWidth: 1, borderStyle: "solid", borderRadius: "6px", position: "relative", display: "inline-block",
    }, inlineProgress: {
        position: "absolute", zIndex: 1, backgroundColor: "red", height: "100%", left: 0, top: 0, borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px",
    }, inlineContent: {
        sx: {
            display: "inline-flex", position: "relative", zIndex: 10, padding: 1,
        },
    }, inlineLabel: {
        fontSize: 12, fontWeight: 700, color: palette.yao.grey[8], marginRight: 1,
    }, inlineValues: {
        fontSize: 11, fontWeight: 400, color: palette.yao.grey[7],
    }, inlineBorder: {
        red: {
            borderColor: "#E4607B",
        }, orange: {
            borderColor: "#E4CB72",
        }, green: {
            borderColor: "#2BA9AF",
        },
    }, inlineBackground: {
        red: {
            backgroundColor: "#FFF0F3",
        }, orange: {
            backgroundColor: "#FFF5ED",
        }, green: {
            backgroundColor: "#ECFBFC",
        },
    },
};

export const MatterFinancialLimitBanner: FC<InputProps> = ({
                                                               type = "card", label = "BUDGET", showEmpty = true, emptyValue = "no value",
                                                           }) => {
    const matterCtx = useContext(MatterDetailsContext);
    const lawFirmContext = useContext(LawFirmContext);

    const currInfo = useMemo(() => lawFirmContext.getCurrency(), [lawFirmContext]);

    const [severity, setSeverity] = useState<"red" | "orange" | "green">("green");

    useEffect(() => {
        if (Number(matterCtx.financialData.data?.limit_percentage) > 100) {
            setSeverity("red");
        } else if (Number(matterCtx.financialData.data?.limit_percentage) > 80) {
            setSeverity("orange");
        }
    }, [matterCtx.financialData.data]);

    const valueToEnGB = (value: number | null | undefined): string => {
        try {
            return Intl.NumberFormat(currInfo.locale, {
                notation: "compact", maximumFractionDigits: 1, currency: currInfo.currency,
            }).format(Number(value) || 0);
        } catch (e) {
            return `${value || ""}`;
        }
    };

    const FinancialBudgetIcon = () => {
        return (<Box
                sx={{
                    display: "flex", justifyContent: "center", mb: "5px", height: "22px",
                }}
            >
                <img
                    className="contain"
                    src={"/assets/icons/matter/financial_budget.svg"}
                    alt=""
                />
            </Box>);
    };

    const renderCard = (): JSX.Element | null => {
        if (matterCtx.financialData.isLoading) {
            return (<Paper {...styles.cardContainer}>
                    <FinancialBudgetIcon/>
                    <Typography {...styles.cardLabel}>{label?.toUpperCase()}</Typography>
                    <CircularProgress size={16} sx={{mt: 1}}/>
                </Paper>);
        }
        if (!matterCtx.matterId || matterCtx.financialData.data === null) {
            return showEmpty ? (<Paper {...styles.cardContainer}>
                    <Typography {...styles.cardLabel}>{label?.toUpperCase()}</Typography>
                    <Typography {...styles.cardValues}>{emptyValue}</Typography>
                </Paper>) : null;
        }

        return (<Paper {...styles.cardContainer}>
                <FinancialBudgetIcon/>
                <Typography {...styles.cardLabel}>{label?.toUpperCase()}</Typography>
                <Typography {...styles.cardValues}>
          <span style={{...styles.cardText[severity]}}>
            {currInfo.symbol}{valueToEnGB(matterCtx.financialData.data?.limit_consumed)}
          </span>{" "}
                    of {currInfo.symbol}{valueToEnGB(matterCtx.financialData.data?.financial_limit)}
                </Typography>
            </Paper>);
    };

    const renderInline = (): JSX.Element | null => {
        const progressStyle = {
            width: matterCtx.financialData.data?.limit_percentage || 0 >= 100 ? "100%" : `${matterCtx.financialData.data?.limit_percentage || 0}%`, borderTopRightRadius: matterCtx.financialData.data?.limit_percentage || 0 >= 100 ? "6px" : 0, borderBottomRightRadius: matterCtx.financialData.data?.limit_percentage || 0 >= 100 ? "6px" : 0, ...styles.inlineBackground[severity],
        };

        if (matterCtx.financialData.isLoading) {
            return (<Box sx={{...styles.inlineContainer, ...styles.inlineBorder.green}}>
                    <Box sx={{...styles.inlineProgress, ...progressStyle}}/>
                    <Box {...styles.inlineContent}>
                        <Typography {...styles.inlineLabel}>{label}</Typography>
                        <CircularProgress size={16}/>
                    </Box>
                </Box>);
        }

        if (!matterCtx.matterId || matterCtx.financialData.data === null) {
            return showEmpty ? (<Box
                    sx={{...styles.inlineContainer, ...styles.inlineBorder[severity]}}
                >
                    <Box sx={{...styles.inlineProgress, ...progressStyle}}/>
                    <Box {...styles.inlineContent}>
                        <Typography {...styles.inlineLabel}>{label}</Typography>
                        <Typography {...styles.inlineValues}>{emptyValue}</Typography>
                    </Box>
                </Box>) : null;
        }

        return (<Box sx={{...styles.inlineContainer, ...styles.inlineBorder[severity]}}>
                <Box sx={{...styles.inlineProgress, ...progressStyle}}/>
                <Box {...styles.inlineContent}>
                    <Typography {...styles.inlineLabel}>{label}</Typography>
                    <Typography {...styles.inlineValues}>
                        ({currInfo.symbol}{valueToEnGB(matterCtx.financialData.data?.limit_consumed)} of {currInfo.symbol}
                        {valueToEnGB(matterCtx.financialData.data?.financial_limit)}){" "}
                    </Typography>
                </Box>
            </Box>);
    };

    if (type === "card") {
        return renderCard();
    }

    if (type === "inline") {
        return renderInline();
    }

    return null;
};
