import {MouseEvent, useContext, useEffect, useMemo, useState} from "react";
import {
    Backdrop,
    Badge,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    ListItemIcon,
    MenuItem,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import AllocateIcon from "@mui/icons-material/MoveUpOutlined";
import RejectIcon from "@mui/icons-material/BackspaceOutlined";
import EditIcon from "@mui/icons-material/Create";
import Page from "src_common/components/Page";
import {useAPI} from "src_common/hooks/useAPI";
import {fCurrencyWithFormat} from "src_common/utils/formatNumber";
import {
    allocateAntecipated,
    LedgerEntryStatus,
    LedgerEntryTypeLabel,
    rejectAntecipated,
    SearchLedger,
    searchLedgers,
} from "src_lawfirm/api/ledgers";
import {BankAccountTypes} from "src_lawfirm/api/bank-account";
import {TableNoData, TableSkeleton} from "src_common/components/table";
import {format} from "date-fns";
import {fCapitalizeFirstLetter} from "src_common/utils/formatText";
import Decimal from "decimal.js";
import {MatterFinancialLimitBanner} from "./MatterFinancialLimitBanner";
import "./footer.css";
import CustomIcon from "src_common/components/custom-icon";
import palette from "src_common/theme/palette";
import Iconify from "src_common/components/Iconify";
import {useSnackbar} from "notistack";
import {formatError} from "src_common/utils/misc";
import useAuth from "src_common/hooks/useAuth";
import {AttorneyPermission} from "src_lawfirm/api/attorneys";
import {MatterDetailsContext} from "./context/matter-details-context";
import {NotificationAccount, PaymentNotification} from "src_lawfirm/api/payment-notifications";
import {typeCreating} from "./PaymentNotificationMenu";
import PaymentNotificationForm from "./PaymentNotificationForm";
import { LawFirmContext } from "../law-firm/LawFirmContext";

Decimal.set({precision: 10, rounding: 2});

const DATE_PATTERN = "dd.MM.yyyy";

export default function AnticipatedTab() {
    const lawFirmContext = useContext(LawFirmContext);
    const matterCtx = useContext(MatterDetailsContext);
    const {enqueueSnackbar} = useSnackbar();
    const getLedgersAPI = useAPI(searchLedgers);
    const { user } = useAuth();
    const isUserAdminOrAccountant = user?.permissions.includes(AttorneyPermission.ADMIN) || user?.permissions.includes(AttorneyPermission.ACCOUNTANT);
    const [accountTypesFilter, setAccountTypesFilter] = useState({
        CLIENT: true, OFFICE: true,
    });
    const [typeToSearch, setTypeToSearch] = useState<BankAccountTypes | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [creatingPaymentNotification, setCreatingPaymentNotification] =
    useState<typeCreating>({
        creating: false,
        title: undefined,
        fromType: undefined,
        toType: undefined,
        payment: undefined,
        isAnticipated: undefined,
      });

    const currInfo = useMemo(() => lawFirmContext.getCurrency(), [lawFirmContext]);

    const searchLedgersPayload: SearchLedger = {
        page: 0, size: 10000, status: LedgerEntryStatus.ANTICIPATED, start: null, end: null, matter: matterCtx.matterId, type: typeToSearch,
    };

    useEffect(() => {
        getLedgersAPI.invoke(searchLedgersPayload);
    }, [typeToSearch]);

    useEffect(() => {
        if (accountTypesFilter.CLIENT && accountTypesFilter.OFFICE) {
            setTypeToSearch(null);
        }
        if (accountTypesFilter.CLIENT && !accountTypesFilter.OFFICE) {
            setTypeToSearch(BankAccountTypes.CLIENT);
        }
        if (accountTypesFilter.OFFICE && !accountTypesFilter.CLIENT) {
            setTypeToSearch(BankAccountTypes.OFFICE);
        }
        if (!accountTypesFilter.OFFICE && !accountTypesFilter.CLIENT) {
            setAccountTypesFilter({CLIENT: true, OFFICE: true});
        }
    }, [accountTypesFilter]);

    const handleAccountTypesFilter = (type: BankAccountTypes, checked: boolean) => {
        setAccountTypesFilter({...accountTypesFilter, [type]: checked});
    };

    const renderLoading = () => {
        if (!getLedgersAPI.loading) {
            return null;
        }
        return [...Array(20)].map((_, i) => <TableSkeleton key={i}/>);
    };

    const renderEmpty = () => {
        if (getLedgersAPI.loading || (Array.isArray(getLedgersAPI.data) && getLedgersAPI.data?.length > 0)) {
            return null;
        }
        return (<TableNoData isNotFound={true} label="No data found for this selection"/>);
    };

    const getFormattedDate = (dtValue: any) => {
        try {
            return format(new Date(dtValue), DATE_PATTERN);
        } catch {
            return "";
        }
    };

    const handleAllocate = (rowId: string) => {
        if (typeof rowId !== "string" || !rowId?.length) {
            return;
        }
        (async () => {
            try {
                setLoading(true);
                await allocateAntecipated(rowId);
                getLedgersAPI.invoke(searchLedgersPayload);
                setLoading(false);
            } catch (error) {
                enqueueSnackbar(formatError(error), {variant: "error"});
                setLoading(false);
            }
        })();
    };

    const handleReject = (rowId: string) => {
        if (typeof rowId !== "string" || !rowId?.length) {
            return;
        }
        (async () => {
            try {
                setLoading(true);
                await rejectAntecipated(rowId);
                getLedgersAPI.invoke(searchLedgersPayload);
                setLoading(false);
            } catch (error) {
                enqueueSnackbar(formatError(error), {variant: "error"});
                setLoading(false);
            }
        })();
    };

    const editPaymentNotification = (rowId: string) => {
        if (typeof rowId !== 'string' || !rowId?.length || !getLedgersAPI.data) {
            return;
        };
        const currentLedger = getLedgersAPI.data.find((row) => row._id === rowId);
        const from: NotificationAccount = {
            type: BankAccountTypes.OFFICE,
            account_id: currentLedger?.bank_account._id,
            reference: currentLedger?.bank_account.name,
        };
        const to: NotificationAccount = {
            type: BankAccountTypes.EXTERNAL,
            name: currentLedger?.payee,
            reference: currentLedger?.reference,
        };
        const payment: Partial<PaymentNotification> = {
            _id: currentLedger!._id,
            date: currentLedger!.date,
            notes: currentLedger!.notes,
            value: Math.abs(currentLedger!.value),
            vat_percentage: currentLedger!.vat_percentage,
            author_name: currentLedger!.author.name,
            matter_title: currentLedger!.matter.title,
            from,
            to,
            matter: {
                _id: currentLedger!.matter._id,
                number: currentLedger!.matter.number
            },
            created_at: currentLedger!.created_at,
        };
        try {
            setLoading(true);
            setCreatingPaymentNotification({
            creating: true,
            title: 'Edit anticipated disbursement',
            fromType: BankAccountTypes.OFFICE,
            toType: BankAccountTypes.EXTERNAL,
            payment,
            isAnticipated: true,
            })
            setLoading(false);
        } catch (error) {
            enqueueSnackbar(formatError(error), {variant: "error"});
            setLoading(false);
        }
    }

    const handleCloseForm = () => {
        setCreatingPaymentNotification({
            ...creatingPaymentNotification,
            payment: undefined,
            creating: false,
        });
        setAnchorEl(null);
        getLedgersAPI.invoke({
            page: 0,
            size: 10000,
            matter: matterCtx.matterId || undefined,
            status: LedgerEntryStatus.ANTICIPATED,
        });
    };

    const renderRows = () => {
        if (getLedgersAPI.loading || !Array.isArray(getLedgersAPI.data)) {
            return null;
        }

        let client_total = new Decimal(0);
        let office_total = new Decimal(0);

        return getLedgersAPI.data.map((r, i) => {
            const isClient = BankAccountTypes.CLIENT === r.account_type;

            if (isClient) {
                client_total = client_total.plus(new Decimal(r.value || 0));
            } else {
                office_total = office_total.plus(new Decimal(r.value || 0));
            }

            const isVatInItalic = !(new Decimal(r.vat || 0).equals(0)) && new Decimal(r.subtotal || 0).equals(new Decimal(r.value || 0));
            return (<TableRow key={r._id} sx={{position: "relative"}}>
                    <TableCell align="left" sx={{padding:"10px"}}>{getFormattedDate(r.date)}</TableCell>
                    <TableCell align="center" sx={{padding:"10px"}}>{!r.type ? "" : (
                        <Tooltip title={LedgerEntryTypeLabel[r.type]?.text || ""} placement="top">
                            <Typography component="span">{LedgerEntryTypeLabel[r.type]?.abbr || ""}</Typography>
                        </Tooltip>)}
                    </TableCell>
                    <TableCell align="center" sx={{padding:"10px"}}>
                        {fCapitalizeFirstLetter(r.account_type)}
                    </TableCell>
                    <TableCell align="left" sx={{padding:"10px"}}>
                        <Typography width="150px" noWrap title={r.payee}>
                            {r.payee || ''}
                        </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{padding:"10px"}}>
                        <Typography width="190px" noWrap title={r.reference}>
                            {r.reference}
                        </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{padding: "10px", fontStyle: isVatInItalic ? "italic" : "normal"}}>
                        {fCurrencyWithFormat(r.vat, currInfo.locale, currInfo.currency, currInfo.symbol)}
                    </TableCell>
                    <TableCell align="left" sx={{padding:"10px"}}>
                        {isClient ? "" : fCurrencyWithFormat(r.subtotal || r.value, currInfo.locale, currInfo.currency, currInfo.symbol)}
                    </TableCell>
                    <TableCell align="left" sx={{padding:"10px"}}>
                        {isClient ? "" : fCurrencyWithFormat(office_total.toNumber(), currInfo.locale, currInfo.currency, currInfo.symbol)}
                    </TableCell>
                    <TableCell align="left" sx={{padding:"10px"}}>
                        {isClient ? fCurrencyWithFormat(r.subtotal || r.value, currInfo.locale, currInfo.currency, currInfo.symbol) : ""}
                    </TableCell>
                    <TableCell align="left" sx={{padding:"10px"}}>
                        {isClient ? fCurrencyWithFormat(client_total.toNumber(), currInfo.locale, currInfo.currency, currInfo.symbol) : ""}
                    </TableCell>
                    <TableCell align="center" sx={{padding:"10px"}}>
                        <Tooltip
                            title={<Grid
                                style={{width: "286px", height: "100%", padding: "20px"}}
                            >
                                {r.notes?.length > 0 ? (<Typography>{r.notes}</Typography>) : (
                                    <Typography>No note</Typography>)}
                            </Grid>}
                        >
                            <Button
                                sx={{padding: 0}}
                                className="todo-button"
                                startIcon={<CustomIcon name="chat"/>}
                            >
                                <Badge
                                    badgeContent={r.notes?.length > 0 ? 1 : 0}
                                    className="select-list"
                                    componentsProps={{
                                        root: {
                                            style: {
                                                color: palette.yao.secondary[2], fontSize: "10px", position: "relative", bottom: "8px", right: "10px",
                                            },
                                        },
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="right" sx={{paddingRight: "30px !important"}}>
                        <IconButton
                            aria-describedby={`popover-${r._id}`}
                            data-id={r._id}
                            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            <Iconify
                                icon={"eva:more-vertical-fill"}
                                width={20}
                                height={20}
                            />
                        </IconButton>
                    </TableCell>
                </TableRow>);
        });
    };

    return (<Page title="Overview">
            <Container maxWidth={false} sx={{p: 3}}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Box flexGrow={1} flexShrink={1}/>
                    <MatterFinancialLimitBanner
                        label="Budget"
                        type="inline"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={accountTypesFilter.CLIENT}
                            onChange={(e) => handleAccountTypesFilter(BankAccountTypes.CLIENT, e.target.checked)}
                        />}
                        label="Client"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={accountTypesFilter.OFFICE}
                            onChange={(e) => handleAccountTypesFilter(BankAccountTypes.OFFICE, e.target.checked)}
                        />}
                        label="Office"
                    />
                    <Tooltip title="refresh data">
                        <Button variant="outlined" onClick={() => getLedgersAPI.invoke(searchLedgersPayload)}>
                            <Iconify icon="material-symbols:refresh" fontSize={20}/>
                        </Button>
                    </Tooltip>
                </Stack>
                <TableContainer
                    sx={{
                        minWidth: 960, position: "relative", padding: "1px",
                    }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">DATE</TableCell>
                                <TableCell align="center" sx={{padding:"10px"}}>TYPE</TableCell>
                                <TableCell align="center" sx={{padding:"10px"}}>ACC TYPE</TableCell>
                                <TableCell align="left" sx={{padding:"10px"}}>PAYEE/PAYER</TableCell>
                                <TableCell align="left" sx={{padding:"10px"}}>NARRATIVE</TableCell>
                                <TableCell align="left" sx={{padding:"10px"}}>{lawFirmContext.getTaxName()}</TableCell>
                                <TableCell align="left" sx={{padding:"10px"}}>OFFICE</TableCell>
                                <TableCell align="left" sx={{padding:"10px"}}>OFFICE TOTAL</TableCell>
                                <TableCell align="left" sx={{padding:"10px"}}>CLIENT</TableCell>
                                <TableCell align="left" sx={{padding:"10px"}}>CLIENT TOTAL</TableCell>
                                <TableCell align="center" sx={{padding:"10px"}}>NOTES</TableCell>
                                <TableCell align="right" sx={{paddingRight: "20px !important"}}>ACTION</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderLoading()}
                            {renderEmpty()}
                            {renderRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            <Popover
                id={Boolean(anchorEl) ? `popover-${anchorEl?.dataset?.id}` : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "bottom", horizontal: "right"}}
                sx={{
                    mt: -1, width: 160, "& .MuiMenuItem-root": {
                        px: 1, typography: "body2", borderRadius: 0.75, "& svg": {mr: 2, width: 20, height: 20},
                    },
                }}
            >
                <MenuItem onClick={() => isUserAdminOrAccountant
                    ? handleAllocate(anchorEl?.dataset?.id || "")
                    : ""
                }>
                    <ListItemIcon sx={{ color: isUserAdminOrAccountant ? "" : "#a5b4c3" }}>
                        <AllocateIcon fontSize="small" />
                        Allocate
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={() => isUserAdminOrAccountant
                    ? handleReject(anchorEl?.dataset?.id || "")
                    : ""
                }>
                    <ListItemIcon sx={{ color: isUserAdminOrAccountant ? "" : "#a5b4c3" }}>
                        <RejectIcon fontSize="small" />
                        Reject
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={() => {
                    editPaymentNotification(anchorEl?.dataset?.id || '')
                }}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                        Edit
                    </ListItemIcon>
                </MenuItem>
            </Popover>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Dialog
                maxWidth="md"
                fullWidth
                open={creatingPaymentNotification.creating}
                onClose={handleCloseForm}
            >
                <DialogTitle>{creatingPaymentNotification.title}</DialogTitle>
                <DialogContent>
                    {creatingPaymentNotification.payment !== undefined && (
                        <PaymentNotificationForm
                            matter={creatingPaymentNotification.payment.matter!._id}
                            fromType={creatingPaymentNotification.fromType}
                            toType={creatingPaymentNotification.toType}
                            onCancel={handleCloseForm}
                            hideDraft={true}
                            payment={creatingPaymentNotification.payment}
                            isAnticipated={creatingPaymentNotification.isAnticipated}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </Page>);
}
