import {FC, ReactNode, useEffect, useMemo, useState} from "react";
import {Box, CircularProgress, Drawer, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {CorrespondenceCategory, getCorrespondenceDetails} from "../../../api/correspondence";
import {useQuery} from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import {CorrespondenceEmailDetail} from "./CorrespondenceEmailDetail.component";
import {CorrespondenceDetail} from "./CorrespondenceDetail.component";
import {CorrespondenceFileViewer} from "./CorrespondenceFileViewer.component";

export const CorrespondencePreviewV2Component: FC<{
    correspondenceId: string | undefined; onClose: () => void
}> = ({correspondenceId, onClose}) => {

    const [visible, setVisible] = useState<boolean>(false);
    const details = useQuery(['corr-details', correspondenceId], () => !!correspondenceId ? getCorrespondenceDetails(correspondenceId) : Promise.resolve(null), {
        retry: 3,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    useEffect(() => {
        setVisible(typeof correspondenceId === 'string' && correspondenceId?.length > 0);
    }, [correspondenceId]);

    const renderLoader = (): ReactNode => {
        return <Box flexGrow={1} flexShrink={1} width="100%">
            <Stack height="100%" justifyContent="center" alignItems="center" spacing={1}>
                <CircularProgress/>
                <Typography variant="caption">wait...</Typography>
            </Stack>
        </Box>
    }

    const renderError = (): ReactNode => {
        return <Box flexGrow={1} flexShrink={1} width="100%">
            <Stack height="100%" justifyContent="center" alignItems="center" spacing={1}>
                <ErrorIcon  color="error" fontSize="large" />
                <Typography variant="subtitle1" color="error.main">Failed to load</Typography>
                <Typography variant="subtitle1" color="error.main">Please try again in a few minutes</Typography>
            </Stack>
        </Box>
    }

    const renderContent = useMemo(() => {
        if(details.isLoading || details.isFetching) {
            return null
        }
        switch (details.data?.category){
            case CorrespondenceCategory.EMAIL:
                return <CorrespondenceEmailDetail correspondence={details.data} setOpenDrawer={() => { onClose() }} />
            case CorrespondenceCategory.CALL:
            case CorrespondenceCategory.NOTE:
                return <CorrespondenceDetail correspondence={details.data} setOpenDrawer={() => { onClose() }} />
            case CorrespondenceCategory.DOCUMENT:
                return <CorrespondenceFileViewer correspondence={details.data} handleClose={() => { onClose() }} />
            default:
                return null;
        }
    }, [details.data, details.isLoading, details.isFetching])



    return <Drawer anchor="right"
                   open={!!correspondenceId}
                   variant="permanent"
                   hideBackdrop>
        <Stack width="40vw"
               minWidth="500px"
               height="100%"
               spacing={1}
               p={1}
               justifyContent="flex-start"
               alignItems="flex-start">
            <Box position="absolute" top="14px" right="0">
                <Tooltip title="Close preview">
                    <IconButton onClick={() => onClose()}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
            {details.isLoading || details.isFetching ? renderLoader() : null}
            {details.isError ? renderError() : null}
            {details.isSuccess ? renderContent : null }
        </Stack>


    </Drawer>

}
