import {
  Correspondence, CORRESPONDENCE_EVENT_KEY,
  CorrespondenceCategory,
  CorrespondenceForm,
  CorrespondenceMailAttachments,
  replyCorrespondence,
} from "src_lawfirm/api/correspondence";
import { getMatter } from "src_lawfirm/api/matters";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  DialogActions,
  Grid,
  IconButton,
  Stack,
  Button,
  Typography,
  Dialog,
} from "@mui/material";
import { FormProvider } from "src_common/components/hook-form";
import CloseIcon from "@mui/icons-material/Close";
import {
  SupportedFileTypes,
  YAOFieldAutocompleteTagInput,
  YAOFieldText,
  useDownloadFileFromS3,
  useUploadFileToS3,
  YAOCKEditor,
} from "src_common/components/yao-form-fields";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import palette from "src_common/theme/palette";
import { sendMail } from "src_lawfirm/GraphService";
import useAuth from "src_common/hooks/useAuth";
import { useEffect, useState } from "react";
import CustomIcon from "src_common/components/custom-icon";
import { useCorrespondenceContext } from "src_common/hooks/useCorrespondenceApi";
import { useContacts } from "src_lawfirm/pages/contacts/useContacts";
import {
  getAttachmentIconName,
  getFileTypeByMime,
} from "src_common/utils/fileHelpers";
import { UploadButton } from './UploadButton';
import { extractEmailFromString } from '../CorrespondenceFolderTab/helpers';
import { trigger } from 'src_common/utils/events';

type EmailFormModel = {
  emails: string[];
  cc_emails?: string[];
  bcc_emails?: string[];
  subject: string;
  message: string;
};

interface IFile {
  readonly file: File;
  readonly filename: string;
}

interface IProps {
  correspondence: Correspondence | null | undefined;
  setModel: (val: Correspondence | null) => void;
  replyToSenderOnly?: boolean;
  forward?: boolean;
  defaultSignature?: string;
}

let filesToForward: IFile[] = [];

export const ReplyCorrespondenceEmailModal = ({
  correspondence,
  setModel,
  replyToSenderOnly = true,
  forward = false,
  defaultSignature = "",
}: IProps) => {
  const [attachments, setAttachments] = useState<IFile[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);

  const { download } = useDownloadFileFromS3();

  const upload = useUploadFileToS3();

  const contactsApi = useContacts();

  useEffect(() => {
    correspondence &&
      getMatter(correspondence.matter?._id).then((matter) => {
        contactsApi.invoke(undefined, undefined, false, undefined, undefined, [
          ...matter.case_contacts.map((c) => c.contact._id!),
          ...matter.clients.map((c) => c.contact._id!),
        ]);
      });
    if (forward) {
      loadForwardAttachments();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const mails = forward
    ? []
    : [
        ...((!replyToSenderOnly && correspondence?.to_emails) || []),
        ...(correspondence?.from_emails || []),
      ];

  const othermails = mails
    .filter((m) => m.address !== user?.email)
    .map((a) => a.address);

  const defaultValues = {
    subject: correspondence?.original_title || correspondence?.title || "",
    emails: othermails,
    cc_emails: [],
    bcc_emails: [],
    message: defaultSignature,
  };

  const contactOptions = contactsApi?.data
    .filter((d) => d.email !== "")
    .map(
      (c) =>
        `${c.company_name || ""} ${c.first_name || ""} ${c.last_name || ""} - ${
          c.email
        }`
    );

  const methods = useForm<EmailFormModel>({
    defaultValues,
  });

  const loadForwardAttachments = () => {
    if (!correspondence?.attachments) return;
    setIsLoadingAttachments(true);
    Promise.all(
      correspondence.attachments.map(async (attachment) => {
        const urlS3: string = (await download(attachment.key!)) || "";
        const response = await fetch(urlS3);
        const blob = await response.blob();
        const file = new File([blob], attachment.key, {
          type: blob.type,
        });
        filesToForward.push({file, filename: attachment.filename});
        setAttachments([...attachments, ...filesToForward]);
      })
    ).then(() => {
      setIsLoadingAttachments(false);
    });
  };

  const onSubmit: SubmitHandler<EmailFormModel> = async (form) => {
    try {
      const email_html_body = `${form.message.replace(
        /(<p[^>]*>)(.*?)(<\/p>)/gi,
        '<p style="margin: 0">$2</p>'
      )}<hr /> <p> ${
        correspondence?.email_html_body || correspondence?.email_body || ""
      } </p>`;

      const emails = form.emails.map((e) => {
        return extractEmailFromString(e);
      });

      const cc_emails =
        (form.cc_emails &&
          form.cc_emails.map((e) => {
            return extractEmailFromString(e);
          })) ||
        [];

      const bcc_emails =
        (form.bcc_emails &&
          form.bcc_emails.map((e) => {
            return extractEmailFromString(e);
          })) ||
        [];

      let payload = {
        subject: form.subject,
        email_html_body,
        to_emails: emails,
        cc_emails,
        bcc_emails,
        attachments,
      };

      await sendMail(payload);

      let uploads: CorrespondenceMailAttachments[] = [];
      await Promise.all(
        attachments.map((attachment) => {
          if (attachment.file.name === attachment.filename)
            return upload(attachment.file).then((data) => {
              if (!data) throw new Error("Something went wrong");
              const { key, title } = data;
              return uploads.push({ key, filename: attachment.filename });
            });
          return uploads.push(
            {
              filename: attachment.filename,
              key: attachment.file.name
            } as CorrespondenceMailAttachments
          );
        })
      );

      const correspondenceDto: CorrespondenceForm = {
        title: forward ? "Fwd: " + form.subject : form.subject,
        email_html_body,
        category: CorrespondenceCategory.EMAIL,
        matter: correspondence?.matter?._id || "",
        to: emails || [],
        cc_emails,
        bcc_emails,
        from: [user?.email],
        correspondence_date: new Date(),
        attachments: uploads,
      };

      await replyCorrespondence(correspondenceDto);
      trigger(CORRESPONDENCE_EVENT_KEY);

      methods.reset();
      filesToForward = [];

      if (setModel) setModel(null);
      enqueueSnackbar(`Email ${forward ? "forward" : "reply"} successfully!`);
      setAttachments([]);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  return (
    <Dialog
      maxWidth="sm"
      open={!!correspondence}
      onClose={() => setModel(null)}
      className="cs_corresponding-modal"
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <IconButton onClick={() => setModel(null)}>
          <CloseIcon />
        </IconButton>

        <Typography
          color={palette.yao.primary[2]}
          fontSize="21px"
          fontWeight="600"
          textAlign="center"
        >
          {`${forward ? "Forward" : "Reply"} Email Correspondence`}
        </Typography>

        <Stack pb="10px" px="40px">
          <Grid item>
            <YaoFormFieldLabel name="key" label="To" />
            <YAOFieldAutocompleteTagInput
              name="emails"
              label=""
              placeholder="Enter emails"
              filterSelectedOptions={false}
              options={contactOptions}
              textFieldType="search"
            />
          </Grid>
        </Stack>
        <Stack pb="10px" px="40px">
          <Grid item>
            <YaoFormFieldLabel name="cc_emails" label="Add cc emails" />
            <YAOFieldAutocompleteTagInput
              name="cc_emails"
              label=""
              placeholder="Enter cc emails"
              filterSelectedOptions={false}
              options={contactOptions}
              textFieldType="search"
            />
          </Grid>
        </Stack>
        <Stack pb="10px" px="40px">
          <Grid item>
            <YaoFormFieldLabel name="bcc_emails" label="Add bcc emails" />
            <YAOFieldAutocompleteTagInput
              name="bcc_emails"
              label=""
              placeholder="Enter bcc emails"
              filterSelectedOptions={false}
              options={contactOptions}
              textFieldType="search"
            />
          </Grid>
        </Stack>
        <Stack pb="10px" px="40px">
          <Grid item>
            <YaoFormFieldLabel name="subject" label="Subject" />
            <YAOFieldText name="subject" />
          </Grid>
        </Stack>
        <Stack pb="10px" px="40px">
          <Grid item width="100%">
            <YaoFormFieldLabel name="message" label="Message" />
            <YAOCKEditor name="message" height={230} />
          </Grid>
        </Stack>

        <Stack pb="10px" px="40px">
          <Grid item>
            <UploadButton
              matterId={correspondence!.matter._id}
              attachments={attachments}
              setAttachments={setAttachments}
            />
            {attachments.length > 0 &&
              attachments?.map((a) => {
                const ext = getFileTypeByMime(a.file.type);
                return (
                  <IconButton
                    sx={{ paddingRight: 0 }}
                    onClick={() => {
                      setAttachments(
                        attachments.filter((p) => p.file.name !== a.file.name)
                      );
                    }}
                  >
                    {ext && (
                      <>
                        <CustomIcon
                          name={getAttachmentIconName(`.${ext}`) || ""}
                          tooltip={a.filename}
                          sx={{ height: "27px", width: "27px" }}
                        />{" "}
                        <Typography
                          sx={{
                            marginBottom: "15px",
                            position: "relative",
                            right: "5px",
                            fontSize: "12px",
                          }}
                        >
                          x
                        </Typography>
                      </>
                    )}
                  </IconButton>
                );
              })}
          </Grid>
        </Stack>

        <DialogActions
          style={{
            marginRight: "16px",
            paddingTop: 0,
          }}
        >
          <Button
            color="inherit"
            onClick={() => {
              filesToForward = [];
              setAttachments([]);
              setModel(null);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isLoadingAttachments}
            loading={methods.formState.isSubmitting}
            sx={{ width: "95px" }}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
