import { AxiosResponse } from "axios";
import axios from "src_common/utils/axios";
import { formatError } from "src_common/utils/misc";
import { CorrespondenceSignStatus } from "./correspondence";

export type CorrespondenceSignUserDto = {
  _id: string;
  email: string;
  mobile_phone: string;
};

export type CorrespondenceSignRequestDto = {
  to: CorrespondenceSignUserDto[];
};

export type CorrespondenceSignProgressDto = {
  _id: string;
  sign_contacts: {
    contact: {
      _id: string;
      display_name: string;
    };
    status: CorrespondenceSignStatus;
    sign_date?: Date;
  }[];
};

export type CorrespondenceSignDetailsDto = {
  title: string;
  author: string;
  wopi_token: string;
  sign_status: CorrespondenceSignStatus;
  key: string;
  pre_signed: string;
};

export type CorrespondenceSignDto = {
  token: string;
  code?: string;
  fingerprint?: string;
};

export async function requestCorrespondenceSign(
  id: string,
  form: CorrespondenceSignRequestDto
): Promise<boolean> {
  try {
    const res: AxiosResponse<void> = await axios.post(
      `/correspondence-sign/${id}/request`,
      form
    );
    return true;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function correspondenceSignProgress(
  id: string
): Promise<CorrespondenceSignProgressDto> {
  try {
    const res: AxiosResponse<CorrespondenceSignProgressDto> = await axios.get(
      `/correspondence-sign/${id}/progress`
    );
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function correspondenceSignDetails(
  id: string,
  params?: Record<string, string>
) {
  try {
    const res: AxiosResponse<CorrespondenceSignDetailsDto> = await axios.get(
      `/correspondence-sign/${id}${params && `?contactId=${params.contactId}`}`
    );
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function correspondenceSignRequestCode(
  id: string,
  payload: CorrespondenceSignDto
) {
  try {
    const res: AxiosResponse<CorrespondenceSignDetailsDto> = await axios.put(
      `/correspondence-sign/${id}/sign/code`,
      payload
    );
    return res.status === 200;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function correspondenceSignConfirmCode(
  id: string,
  payload: CorrespondenceSignDto
) {
  try {
    const res: AxiosResponse<CorrespondenceSignDetailsDto> = await axios.patch(
      `/correspondence-sign/${id}/sign/code`,
      payload
    );
    return res.status === 200;
  } catch (e) {
    throw new Error(formatError(e));
  }
}
