import { Box, Button, DialogActions, Divider, IconButton, Typography, } from "@mui/material";
import { format } from "date-fns";
import CustomIcon from "src_common/components/custom-icon";
import { Correspondence, CorrespondenceMails } from "src_lawfirm/api/correspondence";
import palette from "src_common/theme/palette";
import {useContext, useEffect, useRef, useState} from "react";
import customAxios from "src_common/utils/axios";
import useAuth from "src_common/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { DialogAnimate } from "src_common/components/animate";
import { FileViewer } from "src_common/components/FileViewer";
import { useSnackbar } from "notistack";
import { ReplyCorrespondenceEmailModal } from "./ReplyCorrespondenceEmailModal.component";
import { useAppContext } from "src_lawfirm/AppContext";
import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { getAttachmentIconName } from "src_common/utils/fileHelpers";
import "src_common/components/yao-form-fields/ckEditor.css";
import { getAttorney } from "src_lawfirm/api/attorneys";
import {SupportedFileTypes, useDownloadFileFromS3} from "src_common/components/yao-form-fields";
import {FORMAT_WOPI} from "src_common/utils/getFileFormat";
import {MatterDetailsContext} from "../context/matter-details-context";

interface IProps {
  correspondence: Correspondence | null | undefined;
  setOpenDrawer: (val: Correspondence | null) => void;
}

export const CorrespondenceEmailDetail = ({
  correspondence,
                                            setOpenDrawer,
}: IProps) => {
  const matterCtx = useContext(MatterDetailsContext);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [pdfViewModal, setPdfViewModal] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [fileKey, setFileKey] = useState("");
  const [fileToken, setFileToken] = useState("");
  const [fileExt, setFileExt] = useState("");
  const [replyCorrespondenceEmailModal, setReplyCorrespondenceEmailModal] =
    useState<Correspondence | null>();
  const [replyToSenderOnly, setReplyToSenderOnly] = useState<boolean>(false);
  const [forward, setForward] = useState<boolean>(false);
  const app = useAppContext();
  const [isSent, setIsSent] = useState<boolean>(false);
  const [htmlContent, setHtmlContent] = useState<string>("")
  const [loader, setLoader] = useState<boolean>(false)
  const { directDownload } = useDownloadFileFromS3();
  const wopiFormRef = useRef<HTMLFormElement>();

  useEffect(() => {
    setLoader(true);
    setHtmlContent("");
    setTimeout(() => {
      setHtmlContent(correspondence?.email_html_body || '');
      setLoader(false);
    }, 600)
  }, [correspondence])

  useEffect(() => {
    const next = Array.isArray(correspondence?.from_emails) &&
      correspondence?.from_emails.some(i => (i.address || '').toLowerCase() === (user?.email || '').toLowerCase());
    if (next !== isSent) {
      setIsSent(next || false);
    }
  }, [user, correspondence])

  const getFile = async (file: string, filename: string, extension: string) => {
    try {
      const [details, wopi] = await Promise.all([
        customAxios.get<string>(`/correspondence/${correspondence?._id}?key=${file}`),
        FORMAT_WOPI.indexOf(extension as SupportedFileTypes) === -1 ? Promise.resolve(undefined) : customAxios.post<string | undefined>(
            `/correspondence/wopi/sign`,
            {
              key: file,
              file_name: filename,
              matter_id: matterCtx.matterId,
              matter_name: matterCtx.matter?.data?.case_name || '',
            }
        )
      ]);
      if (!details?.data) throw new Error("Invalid response from server");
      setFileKey(file);
      setFileToken(wopi?.data || '');
      setFilePath(details.data);
      setPdfViewModal(true);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const getAttorneyApi = useQuery(["attorney", user?._id], () =>
    getAttorney(user?._id)
  );

  const defaultSignature =
    !getAttorneyApi.isLoading &&
    `<br />${getAttorneyApi.data?.email_default_signature}`;

  const extractEmailListData = (values: CorrespondenceMails[] = []) => {
    if (!Array.isArray(values) || !values.length) {
      return 'Empty'
    }
    return values.map(v => `${v.name} <${v.address}>`).join(', ');
  }

  const handleOpenFile = async () => {
    enqueueSnackbar("The file will be opened in another tab in a few seconds", {
      variant: "info",
    });
    await directDownload(correspondence?.email_path || "");
  }

  return (
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            paddingBottom="20px"
          >
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton sx={{ padding: "0", marginTop: "6px" }}>
                <CustomIcon name="mail" color={palette.yao.secondary[2]} />
              </IconButton>

              <Typography
                color={palette.yao.primary[2]}
                fontSize="18px"
                fontWeight="600"
                flexGrow={1}
              >
                Email
              </Typography>
              {
                !correspondence?.email_path?.length ? null : (
                  <Button onClick={handleOpenFile}>
                    <CustomIcon name="download" />
                  </Button>
                )
              }
            </Box>
          </Box>

          <Typography color={palette.yao.grey[7]} fontSize="11px">
            TO
          </Typography>
          <Typography color={palette.yao.primary[2]} fontSize="13px" marginBottom="10px">
            {extractEmailListData(correspondence?.to_emails)}
          </Typography>

          {
            isSent ? (
              <>

                <Typography color={palette.yao.grey[7]} fontSize="11px">
                  CC
                </Typography>
                <Typography color={palette.yao.primary[2]} fontSize="13px" marginBottom="10px">
                  {extractEmailListData(correspondence?.cc_emails as CorrespondenceMails[])}
                </Typography>
                <Typography color={palette.yao.grey[7]} fontSize="11px">
                  BCC
                </Typography>
                <Typography color={palette.yao.primary[2]} fontSize="13px" marginBottom="10px">
                  {extractEmailListData(correspondence?.bcc_emails as CorrespondenceMails[])}
                </Typography>
              </>
            ) : (
              <>
                <Typography color={palette.yao.grey[7]} fontSize="11px">
                  FROM
                </Typography>
                <Typography color={palette.yao.primary[2]} fontSize="13px" marginBottom="10px">
                  {extractEmailListData(correspondence?.from_emails)}
                </Typography>
                <Typography color={palette.yao.grey[7]} fontSize="11px">
                  CC
                </Typography>
                <Typography color={palette.yao.primary[2]} fontSize="13px" marginBottom="10px">
                  {extractEmailListData(correspondence?.cc_emails as CorrespondenceMails[])}
                </Typography>
              </>
            )
          }

          <Typography color={palette.yao.grey[7]} fontSize="11px">
            TIME & DATE
          </Typography>

          <Typography color={palette.yao.primary[2]} fontSize="13px">
            {correspondence?.correspondence_date
              ? format(
                new Date(correspondence.correspondence_date),
                "hh:mm a, d.MM.yy"
              )
              : ""}
          </Typography>

          <Box marginTop="10px">
            <Typography color={palette.yao.grey[7]} fontSize="11px">
              SUBJECT
            </Typography>

            <Typography color={palette.yao.primary[2]} fontSize="13px">
              {correspondence?.title}
            </Typography>
          </Box>
        </Box>

        <span
          style={{
            color: palette.yao.grey[7],
            fontSize: "11px",
            marginTop: "10px",
            marginBottom: "5px",
          }}
        >
          MESSAGE
        </span>

        <Box height="100%" sx={{ overflowY: "auto" }}>
          {correspondence?.email_html_body ? (
              <iframe sandbox="" id="inlineFrameExample" srcDoc={correspondence?.email_html_body} style={{ width: '100%', height: '100%', border: 0 }} />
          ) : (
            <Typography color={palette.yao.primary[2]} fontSize="13px">
              {correspondence?.email_body}
            </Typography>
          )}
        </Box>

        <Box >
          <Divider sx={{ marginTop: "12px" }} />

          <Box
            marginTop="10px"
            display="flex"
            flexDirection="column"
            height="60px"
          >
            <Typography color={palette.yao.grey[7]} fontSize="11px">
              Attachments
            </Typography>

            <Typography color={palette.yao.primary[2]} fontSize="13px">
              {correspondence?.attachments?.map((a) => {
                const ext = a?.key?.split(".").pop() || "";

                return (
                  <IconButton
                    onClick={() => {
                      if (a.key) {
                        getFile(a.key, a.filename, ext);
                        setFileExt(ext);
                      }
                    }}
                  >
                    <CustomIcon
                      name={getAttachmentIconName(`.${ext}`) || ""}
                      tooltip={a?.filename}
                      sx={{
                        height: "27px",
                        width: "27px",
                      }}
                    />
                  </IconButton>
                );
              })}
            </Typography>
          </Box>

          <DialogActions sx={{ paddingRight: "0", marginTop: "10px" }}>

            <AuthenticatedTemplate>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setForward(false);
                  setReplyToSenderOnly(true);
                  setReplyCorrespondenceEmailModal(correspondence);
                }}
                sx={{ textTransform: "none" }}
              >
                Reply to Sender
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setForward(false);
                  setReplyToSenderOnly(false);
                  setReplyCorrespondenceEmailModal(correspondence);
                }}
              >
                Reply All
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setForward(true);
                  setReplyCorrespondenceEmailModal(correspondence);
                }}
                sx={{ textTransform: "none" }}
              >
                Forward
              </Button>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Button onClick={app.signIn}>Login to Microsoft</Button>
            </UnauthenticatedTemplate>
          </DialogActions>
        </Box>

        <DialogAnimate
          maxWidth="sm"
          open={pdfViewModal}
          onClose={() => setPdfViewModal(false)}
          className="cs_corresponding-modal"
        >
          {filePath && fileExt && (
            <div className="pdf-viewer-doc"  style={{ height: "100vh" }}>
              <form ref={wopiFormRef} id="office_form" name="office_form" target="office_frame" action="" method="post"></form>
              <FileViewer file={filePath}
                          ext={fileExt}
                          wopi_token={fileToken}
                          wopi_key={fileKey}
                          wopi_form={wopiFormRef}
              />
            </div>
          )}
        </DialogAnimate>

        {replyCorrespondenceEmailModal && (
          <ReplyCorrespondenceEmailModal
            correspondence={replyCorrespondenceEmailModal}
            replyToSenderOnly={replyToSenderOnly}
            forward={forward}
            defaultSignature={defaultSignature || undefined}
            setModel={() => {
              setReplyCorrespondenceEmailModal(null);
              setOpenDrawer(null);
            }}
          />
        )}
      </Box>
  );
};
