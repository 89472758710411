import axios from "axios";

export const getUserCurrentIp = async (): Promise<string | undefined> => {
  try {
    const response = await axios.get<{ ip: string }>(
      "https://api.ipify.org?format=json"
    );
    if (response.status === 200) {
      const { ip } = response.data;
      return ip;
    }
    return undefined;
  } catch (error) {
    console.error(
      "Error while getting current user IP in getUserCurrentIp",
      error
    );
    return undefined;
  }
};

export const getUserAgentData = (): string => {
  return window.navigator.userAgent;
};

export const getUserTimezone = (): string => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    console.error(
      "Error while getting user timezone in getUserTimezone",
      error
    );
    return "Unknown Timezone";
  }
};

export default async function getBrowserFingerprint(
  toBase64?: boolean
): Promise<string | undefined> {
  const currentUserIpAddress = await getUserCurrentIp();
  if (!currentUserIpAddress) {
    return undefined;
  }

  const userAgent = getUserAgentData();
  const timezone = getUserTimezone();

  const fingerprintString = `${currentUserIpAddress};${timezone};${userAgent}`;
  if (toBase64) {
    const fingerprintBase64 = Buffer.from(
      fingerprintString,
      "base64"
    ).toString('ascii');
    return fingerprintBase64;
  }
  return fingerprintString;
}
