import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";

type Option = {
  label: string;
  value: string;
  [key: string]: any;
};

type YaoAutocompleteProps = {
  id?: string;
  name?: string;
  onChange?: any;
  options?: Option[];
  value?: Option | null;
  optionsLoader?: any;
  renderInput?: any;
  placeholder?: string;
  defaultValue?: Option | null;
};

export default function YaoAutocomplete({
  id,
  name,
  onChange,
  options,
  value,
  optionsLoader,
  renderInput,
  placeholder,
  defaultValue,
}: YaoAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [opts, setOpts] = useState<Option[]>([]);

  const options_: Option[] = options || opts;
  const loading = !options && open && options_.length === 0;

  useEffect(() => {
    if (!open || !optionsLoader) return;
    (async () => {
      const opts_ = await optionsLoader();
      setOpts(opts_);
    })();
  }, [open, optionsLoader]);

  return (
    <Autocomplete
      id={id}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disablePortal
      fullWidth
      loading={loading}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      options={options_}
      isOptionEqualToValue={(
        option: Option,
        value: Option
      ) => option.value === value.value}
      renderInput={
        renderInput ||
        ((params) => (
          <TextField
            {...params}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        ))
      }
    />
  );
}
