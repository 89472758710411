import {
  Correspondence, CORRESPONDENCE_EVENT_KEY,
  CorrespondenceCategory,
  CorrespondenceForm,
  createCorrespondence,
  updateCorrespondence,
} from 'src_lawfirm/api/correspondence';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  Grid,
  IconButton,
  Stack,
  Button,
  Typography, Dialog,
} from '@mui/material';
import {
  FormProvider,
  RHFTextArea,
  RHFTextField,
} from 'src_common/components/hook-form';
import CloseIcon from '@mui/icons-material/Close';
import {
  YAOFieldAutocomplete,
  YAOFieldAutocompleteOptionObject,
  YAOFieldDatePicker,
} from 'src_common/components/yao-form-fields';
import {contactNameResolver, useContacts} from 'src_lawfirm/pages/contacts/useContacts';
import { useEffect, useState } from 'react';
import { YaoFormFieldLabel } from 'src_common/components/yao-form/YaoForm';
import { useMatters } from '../useMatters';
import { Matter } from 'src_lawfirm/api/matters';
import { useCorrespondenceContext } from 'src_common/hooks/useCorrespondenceApi';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import palette from 'src_common/theme/palette';
import {Contact} from "../../../api/contacts";
import {orderBy} from "lodash";
import {trigger} from "src_common/utils/events";

type CallNoteFormModel = {
  matter: YAOFieldAutocompleteOptionObject;
  case_contact: YAOFieldAutocompleteOptionObject | null;
  other_contact: string | null;
  correspondence_date: Date | null;
  note: string;
};

interface IProps {
  matter?: Matter;
  correspondence?: Correspondence | null
  visible: boolean;
  handleClose: () => void;
  setOpenDrawer?: (val: Correspondence | null) => void;
}

export const AddCorrespondenceNoteModal = ({
  visible,
  matter,
  correspondence,
  handleClose,
  setOpenDrawer,
}: IProps) => {
  const [other, setOther] = useState(correspondence?.other_contact ? 'other' : '')
  const [contacts, setContacts] = useState<{ label: string; value: string }[]>([])
  const { enqueueSnackbar } = useSnackbar();
  const mattersApi = useMatters();

  const { fetchCorrespondences } = useCorrespondenceContext(matter?._id);

  useEffect(() => {
    mattersApi.invoke();
  }, []);

  useEffect(() => {
    if (!matter) {
      setContacts([
        {
          label: 'Other Contact',
          value: 'other',
        }
      ]);
      return
    }

    const case_contacts = (matter.case_contacts || []).map(c => ({
      label: contactNameResolver(c.contact as Contact) || '',
      value: c.contact._id || ''
    }));
    const clients = (matter.clients || []).map(c => ({
      label: contactNameResolver(c.contact as Contact) || '',
      value: c.contact._id || ''
    }));

    setContacts(
        orderBy(
            [
              ...case_contacts,
              ...clients,
              {
                label: 'Other Contact',
                value: 'other',
              }
            ],
            ['label'],
            ['asc']
        )
    );
  }, [matter])

  const defaultCaseContact = correspondence?.other_contact
    ? {
      label: 'Other contact',
      value: 'other'
    } : correspondence?.case_contact ? {
      label: `${correspondence.case_contact.first_name} ${correspondence.case_contact.last_name}`,
      value: correspondence.case_contact._id
    } : null

  const defaultValues =
  {
    matter: correspondence ? {
      label: correspondence.matter?.case_name ? correspondence.matter.case_name : '',
      value: correspondence.matter?._id ? correspondence.matter._id : '',
    } : {
      label: matter?.case_name ? matter.case_name : '',
      value: matter?._id ? matter._id : '',
    },
    case_contact: defaultCaseContact,
    other_contact: correspondence?.other_contact ? correspondence.other_contact : '',
    correspondence_date: correspondence?.correspondence_date ? new Date(correspondence.correspondence_date) : new Date(),
    correspondence_time: '',
    note: correspondence?.note ? correspondence.note : '',
  }

  const methods = useForm<CallNoteFormModel>({
    defaultValues
  });

  const onSubmit: SubmitHandler<CallNoteFormModel> = async (form) => {
    try {
      const correspondenceDto: CorrespondenceForm = {
        ...form,
        category: CorrespondenceCategory.NOTE,
        correspondence_date: form.correspondence_date
          ? form.correspondence_date
          : undefined,
        correspondence_time: undefined,
        case_contact: (form.case_contact && form.case_contact.value !== 'other') ? form.case_contact.value : undefined,
        other_contact: form.case_contact?.value !== 'other' ? '' : form.other_contact ? form.other_contact : undefined,
        matter: form.matter ? form.matter.value : matter?._id,
      };

      correspondence ?
        await updateCorrespondence({ ...correspondenceDto, _id: correspondence._id })
        :
        await createCorrespondence(correspondenceDto)
      fetchCorrespondences();
      trigger(CORRESPONDENCE_EVENT_KEY);

      methods.reset();
      if (setOpenDrawer) setOpenDrawer(null);
      enqueueSnackbar(`Correspondence ${correspondence ? 'updated' : 'created'} successfully!`);
      setOther('')
      handleClose();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={visible}
      onClose={handleClose}
      className="cs_corresponding-modal"
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>

        <Typography
          color={palette.yao.primary[2]}
          fontSize="21px"
          fontWeight="600"
          textAlign="center"
        >
          {correspondence ? 'Edit Note' : 'Add Note'}
        </Typography>

        <Stack pb="15px" pt="30px" px="40px">
          <Grid container spacing={{ xs: 2, md: 3, sm: 3 }}>
            <Grid item xs={6} sm={6} md={6}>
              <YaoFormFieldLabel name="case_contact" label="Case Contact" />
              <YAOFieldAutocomplete
                name={'case_contact'}
                placeholder="Start typing"
                options={contacts}
                customOnChange={(value: string) => {
                  setOther(value)
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <YaoFormFieldLabel name="correspondence_date" label="Date" />
              <YAOFieldDatePicker
                name="correspondence_date"
                textFieldProps={{
                  sx: {
                    svg: {
                      color: palette.yao.grey[7],
                      height: '14px',
                      width: '14px',
                    },
                  },
                }}
                datePickerProps={{
                  views: ['year', 'month', 'day'],
                  components: {
                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                  },
                  OpenPickerButtonProps:{ sx: { ml: -3, mt: -0.25 }, size: 'small' }
                }}
                inputFormat="dd MMM yy"
                rules={{
                  required: 'Date is required',
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              {other === 'other' && (
                <RHFTextField
                  name="other_contact"
                  label="Other Contact"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Grid>
          </Grid>
        </Stack>

        <Stack pb="10px" px="40px">
          <Grid item>
            <YaoFormFieldLabel name="matter" label="Matter" />
            <YAOFieldAutocomplete
              name={'matter'}
              placeholder="Choose Matter"
              options={mattersApi?.data.map((c: Matter) => ({
                label: c.case_name,
                value: c._id,
              }))}
              loading={mattersApi.loading}
              rules={{
                required: 'Matter is required',
              }}
            />
          </Grid>
        </Stack>

        <Stack spacing={4} px="40px" pb="25px">
          <Grid item width="100%">
            <YaoFormFieldLabel name="note" label="Note" />
            <RHFTextArea
              name="note"
              placeholder="Enter note"
              spellCheck
              style={{
                borderColor: palette.yao.grey[9],
                fontFamily: 'inherit',
                height: '90px',
                padding: '10px',
                resize: 'none',
                width: '100%',
              }}
              required
            />
          </Grid>
        </Stack>

        <DialogActions style={{ marginRight: '16px', paddingTop: '0' }}>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={methods.formState.isSubmitting}
            sx={{ width: '95px' }}
          >
            {correspondence ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
