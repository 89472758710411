import axios from "src_common/utils/axios";
import { formatError } from "src_common/utils/misc";
import { Attorney } from "./attorneys";
import { Task } from "./tasks";


export async function createTaskComment(form: TaskCommentForm): Promise<TaskComment> {
  try {
    const res = await axios.post("/task-comments", form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateTaskComment(form: TaskCommentUpdateForm, id: string): Promise<TaskComment> {
  try {
    const res = await axios.patch(`/task-comments/${id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getTaskComments(task_id: string): Promise<TaskComment[]> {
  const res = await axios.get(`task-comments/${task_id}/comments`);
  return res.data;
}

export type TaskComment = {
  comment: string,
  task: Task,
  author: Attorney,
  _id: string,
  created_at: Date,
  updated_at: Date,
  __v: number
};


export type TaskCommentForm = {
  comment: string,
  task: string,
}

export const TaskCommentDefaultValue = {
  comment: '',
  task: '',
}

export type TaskCommentUpdateForm = {
  comment: string,
  task: string,
}

export type TaskCommentFilter = {
  task: string,
}
