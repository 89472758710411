import { createContext, FC, useState } from "react";
import {
  getLostAndFoundPending,
  PostItem,
  PostSearchType,
  searchPostItem,
} from "../../api/post-office";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type PostOfficeContextProps = {
  tab: PostSearchType;
  rows: PostItem[];
  isFetching: boolean;
  hasNextPage: boolean;
  page: number;
  size: number;
  fetchNextPage: () => void;
  reset: (newTab: PostSearchType) => void;
  append: (items: PostItem[]) => void;
  updateItem: (item: PostItem) => void;
  removeItem: (id: string) => void;
  lostAndFoundCount: number;
};

const DEFAULT_VALUES = {
  tab: PostSearchType.ALL,
  rows: [],
  isFetching: false,
  hasNextPage: true,
  page: 0,
  size: 50,
  fetchNextPage: () => {},
  reset: (newTab: PostSearchType) => {},
  append: (items: PostItem[]) => {},
  updateItem: (item: PostItem) => {},
  removeItem: (id: string) => {},
  lostAndFoundCount: 0,
};

const PostOfficeContext = createContext<PostOfficeContextProps>(DEFAULT_VALUES);

const PostOfficeContextProvider: FC = ({ children }) => {
  const [tab, setTab] = useState<PostSearchType>(DEFAULT_VALUES.tab);
  const [rows, setRows] = useState<PostItem[]>(DEFAULT_VALUES.rows);
  const [isFetching, setIsFetching] = useState<boolean>(
    DEFAULT_VALUES.isFetching
  );
  const [hasNextPage, setHasNextPage] = useState<boolean>(
    DEFAULT_VALUES.hasNextPage
  );
  const [page, setPage] = useState<number>(DEFAULT_VALUES.page);
  const [size] = useState<number>(DEFAULT_VALUES.size);

  const { data: lostAndFoundCount } = useQuery(
    ["lost-found-count", tab],
    () => getLostAndFoundPending(),
    { cacheTime: 30000 }
  );
  const queryClient = useQueryClient();
  const invalidateLostAndFoundCount = () => {
    queryClient.invalidateQueries(["lost-found-count"])  
  }

  const fetchNextPage = () => {
    if (!hasNextPage) {
      return;
    }
    (async () => {
      try {
        setIsFetching(true);
        const items = await searchPostItem({ page, size, type: tab });
        setRows((old) => [...old, ...items]);
        setPage((v) => v + 1);
        setIsFetching(false);
        setHasNextPage(items.length > 0);
      } catch (e) {
        setIsFetching(false);
      }
    })();
  };

  const reset = (newTab: PostSearchType) => {
    setTab(newTab);
    setRows(DEFAULT_VALUES.rows);
    setIsFetching(DEFAULT_VALUES.isFetching);
    setHasNextPage(DEFAULT_VALUES.hasNextPage);
    setPage(DEFAULT_VALUES.page);
  };

  const append = (items: PostItem[]) => {
    if (!Array.isArray(items) || !items.length) {
      return;
    }
    invalidateLostAndFoundCount();
    setRows((old) => [
      ...items.map((i) => ({ ...i, highlight: true })),
      ...old,
    ]);
    setTimeout(() => {
      setRows((old) => old.map((o) => ({ ...o, highlight: false })));
    }, 1000);
  };

  const updateItem = (item: PostItem) => {
    if (!item) {
      return;
    }
    setRows((old) =>
      old.map((i) => (i._id === item._id ? { ...i, ...item } : i))
    );
    invalidateLostAndFoundCount();
  };

  const removeItem = (id: string) => {
    setRows((old) => old.filter((i) => i._id !== id));
    invalidateLostAndFoundCount();
  };

  return (
    <PostOfficeContext.Provider
      value={{
        tab,
        rows,
        isFetching,
        hasNextPage,
        page,
        size,
        fetchNextPage,
        reset,
        append,
        updateItem,
        removeItem,
        lostAndFoundCount: lostAndFoundCount || 0,
      }}
    >
      {children}
    </PostOfficeContext.Provider>
  );
};

export { PostOfficeContext, PostOfficeContextProvider };
