import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Drawer,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Breadcrumbs from "src_common/components/Breadcrumbs";
import palette from "src_common/theme/palette";
import Badge, { BadgeProps } from "@mui/material/Badge";
import {
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Matter,
  MatterStatus,
  MatterStatusLabel,
} from "src_lawfirm/api/matters";
import { BankAccountTypes } from "src_lawfirm/api/bank-account";
import { MatterStatusField } from "./MatterStatusField.component";
import { MatterDetailsContext } from "./context/matter-details-context";
import CustomIcon from "src_common/components/custom-icon";
import AddMenuContext from "src_common/contexts/AddMenuContext";
import { contactNameResolver } from "../contacts/useContacts";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import NiceModal from "@ebay/nice-modal-react";
import { AddCorrespondenceEmailModal } from "./CorrespondenceForms/AddCorrespondenceEmailModal.component";
import { useAppContext } from "../../AppContext";
import { last as _last } from "lodash";
import PaymentNotificationMenu from "./PaymentNotificationMenu";
import { AddNewTask } from "./TaskForms";
import {
  AddCorrespondenceCallNoteModal,
  AddCorrespondenceNoteModal,
} from "./CorrespondenceForms";
import ComplianceService from "src_lawfirm/api/compliances";
import { useQuery } from "@tanstack/react-query";

const tabs = [
  { label: "Overview", value: "overview" },
  { label: "Progress", value: "progress" },
  {
    label: "Tasks",
    value: "tasks",
  },
  { label: "Forms", value: "forms" },
  {
    label: "Correspondence",
    value: "correspondence",
  },
  { label: "Time", value: "time" },
  {
    label: "Compliance",
    value: "compliance",
  },
  { label: "Accounts", value: "accounts/ledgers" },
];

type LinkTabProps = {
  href: string;
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    height: "15px",
    top: "-2px",
    right: "-2px",
    maxWidth: "15px",
    minWidth: "10px",
    borderRadius: "12px",
    fontSize: "0.6rem",
  },
}));

const LinkTab = forwardRef(
  (
    { href, ...props }: LinkTabProps,
    ref: Ref<HTMLAnchorElement> | undefined
  ) => {
    return <NavLink ref={ref} to={href} {...props} />;
  }
);

interface typeCreating {
  creating: boolean;
  title?: string;
  fromType?: BankAccountTypes;
  toType?: BankAccountTypes;
}

export default function SingleMatterLayout() {
  const app = useAppContext();
  const complianceService = new ComplianceService();
  const navigate = useNavigate();
  const location = useLocation();
  const addMenuCtx = useContext(AddMenuContext);
  const matterCtx = useContext(MatterDetailsContext);
  const statusRef = useRef<HTMLDivElement>(null);
  const [statusMenuOpen, setStatusMenuOpen] = useState<boolean>(false);
  const [addCorrespondenceModal, setAddCorrespondenceModal] =
    useState<boolean>(false);
  const [addCorrespondenceNoteModal, setAddCorrespondenceNoteModal] =
    useState<boolean>(false);
  const [taskAddForm, setTaskAddForm] = useState<boolean>(false);
  const [creatingPaymentNotification, setCreatingPaymentNotification] =
    useState<typeCreating>({
      creating: false,
      title: undefined,
      fromType: undefined,
      toType: undefined,
    });
  const [activeTab, setActiveTab] = useState<string>("");

  useEffect(() => {
    addMenuCtx.setMatterId(matterCtx.matterId);
  }, [matterCtx.matterId]);

  useEffect(() => {
    const path: string[] = location?.pathname?.split("/");
    const isAccount = path.indexOf("accounts") !== -1;
    if (isAccount) {
      setActiveTab("accounts/ledgers");
    } else {
      setActiveTab(_last(path) || "");
    }
  }, [location]);

  const handleClick = () => {
    setStatusMenuOpen(true);
  };

  const handleClose = () => {
    setStatusMenuOpen(false);
  };

  const handleCloseForm = () => {
    setCreatingPaymentNotification({
      ...creatingPaymentNotification,
      creating: false,
    });
  };

  if (matterCtx.matter.isLoading) {
    return (
      <Box sx={{ minHeight: { lg: 1 } }}>
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (matterCtx.matter.isError || !matterCtx.matter.data) {
    return (
      <Box sx={{ minHeight: { lg: 1 } }}>
        <Box textAlign="center">
          <Typography variant="body1" color="error.main">
            please try again in a few seconds
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth={false}>
        <Stack>
          <Breadcrumbs
            links={[
              { name: "Dashboard" },
              { name: "Matter" },
              { name: matterCtx.matter.data.case_name },
            ]}
          />
        </Stack>
        <Stack direction="row" spacing={0.5} sx={{ my: 0.5 }}>
          <Chip
            label={
              <Box display="flex" gap="0.3125rem">
                <Typography sx={{ color: palette.yao.grey[6] }}>#</Typography>
                <Typography sx={{ color: "#0D394D" }}>
                  {matterCtx.matter.data.number}
                </Typography>
              </Box>
            }
            variant="outlined"
            size="small"
            sx={{
              px: 0.5,
              height: "24px",
              borderColor: palette.yao.grey[7],
            }}
          />

          {matterCtx.matter.data?.private && (
            <Chip
              label={
                <Box display="flex" gap="0.3125rem">
                  <Typography sx={{ color: palette.yao.grey[7] }}>
                    Private
                  </Typography>
                </Box>
              }
              variant="outlined"
              size="small"
              sx={{
                px: 0.5,
                height: "24px",
                borderColor: "#0D394D", 
              }}
            />
          )}
          <Box onClick={handleClick} ref={statusRef} sx={{ height: "24px" }}>
            <MatterStatusField matterStatus={matterCtx.matter.data.status} />
          </Box>
          <Menu
            anchorEl={statusRef.current}
            open={statusMenuOpen}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {Object.values(MatterStatus)
              .filter((status) => {
                return matterCtx.matter.data?.status === MatterStatus.ARCHIVED
                  ? status === MatterStatus.ARCHIVED
                  : status !== MatterStatus.ARCHIVED;
              })
              .map((value) => (
                <MenuItem
                  key={value}
                  onClick={() => {
                    matterCtx.matterStatusMutation.mutate(value);
                    handleClose();
                  }}
                  selected={matterCtx.matter.data?.status === value}
                >
                  {MatterStatusLabel[value]}
                </MenuItem>
              ))}
          </Menu>
          <Typography
            variant="h4"
            sx={{ lineHeight: "22px", pl: 1, fontSize: 24 }}
          >
            {matterCtx.matter.data.case_name}
          </Typography>
          <IconButton
            sx={{ top: -20 }}
            onClick={() => {
              navigate(`/matters/${matterCtx.matterId}/edit`, {
                replace: true,
              });
            }}
          >
            <CustomIcon name="pencil" />
          </IconButton>
        </Stack>
        <Stack direction="row" sx={{ my: 1.5 }} spacing={2.5}>
          <Paper
            sx={{
              border: `1px solid ${palette.yao.primary[4]}`,
              px: 3,
              py: 1,
              borderRadius: 1,
              width: "calc(100% - 275px)",
            }}
          >
            <Box display="flex" justifyContent="space-between">
              {[
                {
                  key: "CLIENT",
                  value: matterCtx.matter.data.clients
                    .map((c) => contactNameResolver(c?.contact as any))
                    .join(", "),
                },
                {
                  key: "RESPONSIBLE LAWYER",
                  value: `${matterCtx.matter.data?.responsible_lawyer?.name} ${matterCtx.matter.data?.responsible_lawyer?.surname}`,
                },
                {
                  key: "CASE TYPE",
                  value: matterCtx.matter.data?.case_type?.title,
                },
              ]?.map(({ key, value }: { key: string; value: string }) => (
                <Box key={key}>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: 11,
                      lineHeight: 0.5,
                      color: palette.yao.grey[7],
                    }}
                  >
                    {key}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      lineHeight: 2,
                      color: palette.yao.primary[2],
                    }}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Paper>
          <Paper
            sx={{
              border: `1px solid ${palette.yao.primary[4]}`,
              px: 4,
              borderRadius: 1,
              width: 375,
              background: palette.yao.primary[4],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: palette.yao.primary[3],
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <AuthenticatedTemplate>
                <Tooltip title="Send an Email" placement="top">
                  <Button
                    disabled={matterCtx.attorney.isLoading}
                    startIcon={<CustomIcon name="letter" />}
                    onClick={() => {
                      NiceModal.show(AddCorrespondenceEmailModal, {
                        matter: matterCtx.matter.data as Matter,
                        defaultSignature: `<br />${
                          matterCtx.attorney.data?.email_default_signature || ""
                        }`,
                      });
                    }}
                  />
                </Tooltip>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Tooltip title="Send an Email" placement="top">
                  <Button
                    startIcon={<CustomIcon name="letter" />}
                    onClick={app.signIn}
                  />
                </Tooltip>
              </UnauthenticatedTemplate>
              <Tooltip title="Set a Task" placement="top">
                <Button
                  startIcon={<CustomIcon name="bulletList" />}
                  onClick={() => {
                    setTaskAddForm(true);
                  }}
                />
              </Tooltip>
              <Tooltip title="Add Telephone Note" placement="top">
                <Button
                  startIcon={<CustomIcon name="phone" />}
                  onClick={() => {
                    setAddCorrespondenceModal(true);
                  }}
                />
              </Tooltip>
              <Tooltip
                title="Create Payment Notification for Accounts Team"
                placement="top"
              >
                <Button
                  startIcon={<CustomIcon name="money" />}
                  onClick={() => {
                    setCreatingPaymentNotification({
                      ...creatingPaymentNotification,
                      creating: true,
                    });
                  }}
                />
              </Tooltip>
              <Tooltip title="Add File Note" placement="top">
                <Button
                  startIcon={<CustomIcon name="note" className="tab_note" />}
                  onClick={() => {
                    // setSelectedMatterId(matter._id);
                    setAddCorrespondenceNoteModal(true);
                  }}
                />
              </Tooltip>
            </Box>
          </Paper>
        </Stack>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          sx={{ marginBottom: 2 }}
        >
          {tabs.map(({ label, value }) => (
            <Tab
              sx={{ padding: "10px" }}
              LinkComponent={LinkTab}
              disableRipple
              key={value}
              value={value}
              label={
                value === "compliance" ? (
                  <StyledBadge
                    color="error"
                    badgeContent={matterCtx.totalCompliances}
                    invisible={matterCtx.totalCompliances === 0}
                  >
                    {label}
                  </StyledBadge>
                ) : (
                  label
                )
              }
              href={`/matters/${matterCtx.matterId}/${value}`}
            />
          ))}
        </Tabs>
      </Container>
      <Outlet />
      <Dialog
        maxWidth="sm"
        open={creatingPaymentNotification.creating}
        onClose={handleCloseForm}
      >
        <DialogTitle>New Payment Notification</DialogTitle>
        <PaymentNotificationMenu matterId={matterCtx.matterId} />
      </Dialog>

      <Drawer
        anchor="right"
        open={taskAddForm}
        onBackdropClick={() => setTaskAddForm(false)}
      >
        <AddNewTask
          matterId={matterCtx.matterId}
          callTasksAPI={() => {}}
          setAddForm={setTaskAddForm}
        />
      </Drawer>
      <AddCorrespondenceCallNoteModal
        visible={addCorrespondenceModal}
        handleClose={() => setAddCorrespondenceModal(false)}
        matter={matterCtx.matter.data as Matter}
      />
      <AddCorrespondenceNoteModal
        visible={addCorrespondenceNoteModal}
        handleClose={() => setAddCorrespondenceNoteModal(false)}
        matter={matterCtx.matter.data as Matter}
      />
    </>
  );
}
