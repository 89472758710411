import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import {
  useController,
  useFieldArray,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import CustomIcon from "src_common/components/custom-icon";
import {
  YAOFieldAutocomplete,
  YAOFieldText,
} from "src_common/components/yao-form-fields";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import palette from "src_common/theme/palette";
import { ContactCategory, ContactCategoryLabel } from "src_lawfirm/api/matters";
import {
  contactNameResolver,
  useContacts,
} from "src_lawfirm/pages/contacts/useContacts";
import { Contact, getContacts } from "src_lawfirm/api/contacts";
import useDebounce from "src_common/hooks/useDebounce";
import { useSnackbar } from "notistack";
import { formatError } from "src_common/utils/misc";
import CloseIcon from "@mui/icons-material/Close";

type SelectorProps = {
  name: string;
  methods: UseFormReturn<any, any>;
};

const IsolatedContactInput: FC<SelectorProps> = ({ name, methods }) => {
  const { debounce, reset: clearDebounce } = useDebounce();
  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Contact[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    clearDebounce();
    if (!inputValue.length) {
      setOpen(false);
      setOptions([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    debounce(async () => {
      try {
        const response = await getContacts({
          search: inputValue,
          limit: 50,
          last_id: "",
          is_archived: false,
          type: undefined,
        });
        setOptions(response);
      } catch (error) {
        setOptions([]);
        enqueueSnackbar(formatError(error), { variant: "error" });
      } finally {
        clearDebounce();
        setLoading(false);
      }
    }, 250);
  }, [inputValue]);

  return (
    <Autocomplete
      id={name}
      value={methods.getValues(name)}
      onChange={(_: SyntheticEvent, v: Contact | null) => {
        methods.setValue(name, v);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(o: Contact, v: Contact) => o?._id === v?._id}
      filterOptions={(o) => o}
      options={options}
      loading={loading}
      blurOnSelect={true}
      inputValue={inputValue}
      onInputChange={(_: SyntheticEvent, v: string) => setInputValue(v)}
      noOptionsText={
        !inputValue.length ? "Type anything to search" : "No result found"
      }
      disableClearable
      forcePopupIcon={false}
      clearOnEscape={false}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Type to search"
          fullWidth
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-" + name + "field",
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
          variant="outlined"
        />
      )}
      getOptionLabel={(o: Contact) => contactNameResolver(o, true)}
    />
  );
};

export const ClientSelector = ({ name, methods }: SelectorProps) => {
  const contactsAPI = useContacts();
  const formFields = useFieldArray({
    name: "clients",
    control: methods.control,
  });

  useEffect(() => {
    if (!formFields.fields.length) {
      onAdd();
    }
  }, []);

  const onAdd = () => {
    formFields.append(null as unknown as any);
  };

  const onDelete = () => {
    formFields.remove(formFields.fields.length - 1);
  };

  return (
    <Grid container spacing={1}>
      {formFields.fields.map((field, idx) => (
        <Grid item xs={12} key={idx}>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <YaoFormFieldLabel name={name} label="Full name" required />
            {idx === 0 && (
              <Button
                onClick={() => contactsAPI.createDialog()}
                variant="text"
                startIcon={
                  <CustomIcon
                    name="addContract"
                    sx={{ p: 0.35 }}
                    color={palette.yao.grey[6]}
                  />
                }
              >
                Create a contact
              </Button>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Box flexGrow={1}>
              <IsolatedContactInput name={`clients.${idx}`} methods={methods} />
            </Box>
            <IconButton
              disabled={formFields.fields.length === 1}
              onClick={() => {
                formFields.remove(idx);
              }}
              color="error"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Stack
          direction="row"
          sx={{
            justifyContent: "right",
            mt: 2,
          }}
          spacing={2}
        >
          <Button
            color="secondary"
            onClick={onDelete}
            disabled={formFields.fields.length === 1}
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={onAdd}>
            Add Client {formFields.fields.length + 1}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const CaseContactSelector = ({ name, methods }: SelectorProps) => {
  const contactsAPI = useContacts();
  const formFields = useFieldArray({
    name: "case_contacts",
    control: methods.control,
  });

  useEffect(() => {
    if (!formFields.fields.length) {
      onAdd();
    }
  }, []);

  const onAdd = () => {
    formFields.append(null as unknown as any);
  };

  const onDelete = () => {
    formFields.remove(formFields.fields.length - 1);
  };

  return (
    <>
      <Grid container spacing={1}>
        {formFields.fields.map((field, idx) => (
          <Grid item xs={12} key={idx}>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <YaoFormFieldLabel name={name} label="Contact" />

              {idx === 0 && (
                <Button
                  variant="text"
                  onClick={() => contactsAPI.createDialog()}
                  startIcon={
                    <CustomIcon
                      name="addContract"
                      sx={{ p: 0.35 }}
                      color={palette.yao.grey[6]}
                    />
                  }
                >
                  Create a contact
                </Button>
              )}
            </Stack>

            <Grid container columnSpacing={2.5}>
              <Grid item xs={5}>
                <IsolatedContactInput
                  name={`case_contacts.${idx}.contact`}
                  methods={methods}
                />
              </Grid>
              <Grid item xs={3}>
                <YAOFieldAutocomplete
                  name={`case_contacts.${idx}.category`}
                  placeholder="Choose category"
                  options={Object.values(ContactCategory)}
                  getOptionLabel={(c) => ContactCategoryLabel[c]}
                />
              </Grid>
              <Grid item xs={3}>
                <YAOFieldText
                  name={`case_contacts.${idx}.reference`}
                  placeholder="Contact reference"
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={formFields.fields.length === 1}
                  onClick={() => {
                    formFields.remove(idx);
                  }}
                  color="error"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Stack
            direction="row"
            sx={{
              justifyContent: "right",
              mt: 2,
            }}
            spacing={2}
          >
            <Button
              color="secondary"
              onClick={onDelete}
              disabled={formFields.fields.length === 1}
            >
              Cancel
            </Button>
            <Button variant="outlined" onClick={onAdd}>
              Add Contact {formFields.fields.length + 1}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
