import NiceModal from "@ebay/nice-modal-react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { DialogContent } from "src_common/components/dialog/Dialog";
import { FormProvider } from "src_common/components/hook-form";
import {
  YAOFieldAutocomplete,
  YAOFieldAutocompleteMultiple,
  YAOFieldDatePicker,
} from "src_common/components/yao-form-fields";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { useAPI } from "src_common/hooks/useAPI";
import { getAttorneys } from "src_lawfirm/api/attorneys";
import { WorkType } from "src_lawfirm/api/time-entries";

type FilterTimeEntryFormProps = DialogContent & {
  filters: TimeEntryFiltersFormModel;
};

export type TimeEntryFiltersFormModel = {
  start: Date | null;
  end: Date | null;
  assignee: {
    label: string;
    value: string;
  } | null;
  work_type: Array<{
    label: string;
    value: string;
  }>;
};

const FilterTimeEntryForm = ({
  filters,
  onFinish,
  onCancel,
}: FilterTimeEntryFormProps) => {
  const getAttorneysAPI = useAPI(getAttorneys);

  const methods = useForm<TimeEntryFiltersFormModel>({
    defaultValues: filters,
  });

  useEffect(() => {
    getAttorneysAPI.invoke();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
      <Grid container rowSpacing={1.5} columnSpacing={2.5}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Filter time
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <YaoFormFieldLabel name="start" label="Date range" />
          <YAOFieldDatePicker
            name="start"
            datePickerProps={{
              views: ["year", "month", "day"],
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <YaoFormFieldLabel name="end" label=" " />
          <YAOFieldDatePicker
            name="end"
            datePickerProps={{
              views: ["year", "month", "day"],
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <YaoFormFieldLabel name="assignee" label="Assigned to" />
          <YAOFieldAutocomplete
            name="assignee"
            label=""
            placeholder="Choose assignee"
            options={(getAttorneysAPI?.data || []).map((a) => ({
              label: a.name,
              value: a._id,
            }))}
          />
        </Grid>

        <Grid item xs={12}>
          <YaoFormFieldLabel name="work_type" label="Type" />
          <YAOFieldAutocompleteMultiple
            name="work_type"
            label=""
            placeholder="Choose time entry type"
            options={Object.keys(WorkType).map((wt) => ({
              label: wt,
              value: wt,
            }))}
          />
        </Grid>
      </Grid>

      <Stack
        direction="row"
        sx={{
          mt: 4,
          justifyContent: "right",
        }}
        spacing={2}
      >
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ minWidth: 100 }} type="submit">
          Apply
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default FilterTimeEntryForm;
